import Box from "@mui/material/Box";
import Sidebar from "./components/Layout/Sidebar";
import GeneralContainer from "./components/Layout/GeneralContainer";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatchApp } from "../../lib/redux";
import { getKeyDatabase } from "../../services/public";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  startGetAdsCampaign,
  startGetBrandInformation,
  startGetRecommendedSettingsPlatform,
  startSaveAdsCampaign,
  startUpdateAdsCampaignsInformationFromPlatforms,
} from "../../actions/adsCampaigns";
import {
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGN_STATUS_DRAFT,
  ADS_CAMPAIGN_STATUS_FINISHED,
  ALERT_ICON_TYPE_SUCCESS,
} from "../../utils/constants";
import { getUserIsViewer } from "../../actions/getters";
import Loader from "../../components/Loaders/Loader";
import {
  ADS_CAMPAIGN_HAS_BEEN_SAVE,
  ERROR_PROCESSING_CAMPAIGNS,
  LOADING_INFORMATION,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import {
  getCampaignUpdatedByStatus,
  getStatusAdsCampaign,
} from "../../services/adsCampaigns";
import { setNestedValue } from "../../utils/forms";
import { SimpleAlert } from "../../components/Alerts/Alerts";
import ModalErrorsCampaigns from "../AdsCampaign/components/ModalErrorsCampaigns";
import { zodResolver } from "@hookform/resolvers/zod";
import { getCampaignSchema } from "./utils/schemas/campaignSchema";
import { useUploadAsyncContentInCampaign } from "./hooks/useUploadAsyncContentInCampaign";

const currency = "USD";

const NewAdsCampaign = () => {
  const [isForcedSetupPage, setIsForcedSetupPage] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sectionSelected, setSectionSelected] = useState({
    platform: undefined,
    adGroupID: undefined,
    adID: undefined,
  });
  const [platformGenerating, setPlatformGenerating] = useState(null);
  const [lastInformationToGenerate, setLastInformationToGenerate] = useState(
    {}
  );
  const [showErrorGenerating, setShowErrorGenerating] = useState(false);
  const [modalErrorsCampaignsOpen, setModalErrorsCampaignsOpen] =
    useState(false);
  const [dataModalErrorsCampaigns, setDataModalErrorsCampaigns] = useState({});
  const [campaignID, setCampaignID] = useState(null);
  const [brandInformation, setBrandInformation] = useState({});

  const schema = getCampaignSchema();
  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      context: "",
      language: "",
      locations: "",
      platforms: [],
    },
    shouldUnregister: false,
  });
  const { getValues, setValue, watch, trigger } = methods;
  const dispatch = useDispatchApp();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { t } = useTranslationApp();
  const campaignIDParam = params.get("campaignID");

  useUploadAsyncContentInCampaign({
    campaignID,
    getValues,
    setValue,
    watch,
  });

  const states = watch("states") || {};
  const platforms = watch("platforms") || [];
  const platformIDs = watch("platformIDs") || [];
  const hasSomePlatformDraft = Object.keys(states || {}).some(
    (key) => states[key] === ADS_CAMPAIGN_STATUS_DRAFT
  );
  const hasSomePlatformCreated = Object.keys(states || {}).some(
    (key) => states[key] !== ADS_CAMPAIGN_STATUS_DRAFT
  );

  const onGenerateCampaignPlatform = async (platformData) => {
    setShowErrorGenerating(false);
    const formValues = getValues();
    const currentPlatforms = formValues.platforms || [];
    const newPlatforms = [...currentPlatforms, platformData.platform];
    setLastInformationToGenerate(platformData);

    if (isForcedSetupPage) {
      setIsForcedSetupPage(false);
    }

    setPlatformGenerating(platformData.platform);
    onChangeSectionSelected({
      platform: undefined,
      adGroupID: undefined,
      adID: undefined,
    });
    const response = await dispatch(
      startGetRecommendedSettingsPlatform({
        ...platformData,
        ...formValues,
        platforms: newPlatforms,
        campaignID,
      })
    );
    if (response.ok) {
      setShowErrorGenerating(false);
      const data = response.data;
      Object.keys(data).forEach((key) => {
        setValue(key, data[key]);
      });
      onChangeSectionSelected({
        platform: platformData.platform,
        adGroupID: undefined,
        adID: undefined,
      });
    } else {
      setShowErrorGenerating(true);
    }
    setPlatformGenerating(null);
  };

  const onUpdateAdsCampaignInformationFromPlatforms = async (campaignID) => {
    const response = await dispatch(
      startUpdateAdsCampaignsInformationFromPlatforms({
        campaignIDs: [campaignID],
      })
    );
    if (response) {
      const updatedData = response?.[campaignID];
      if (updatedData) {
        setNestedValue({ data: updatedData, setValue });
      }
    }
    return response;
  };

  const onGetInitialData = async (campaignID) => {
    setIsLoading(true);
    const response = await dispatch(startGetAdsCampaign(campaignID));

    if (response) {
      const data = response;
      Object.keys(data).forEach((key) => {
        setValue(key, data[key]);
      });
      onChangeSectionSelected({
        platform: data?.platforms?.[0],
        adGroupID: undefined,
        adID: undefined,
      });
      const statusCampaign = getStatusAdsCampaign({
        states: response.states,
        target: "general",
      });
      if (
        statusCampaign === ADS_CAMPAIGN_STATUS_ACTIVE ||
        statusCampaign === ADS_CAMPAIGN_STATUS_DEACTIVATED
      ) {
        onUpdateAdsCampaignInformationFromPlatforms(campaignID);
      }
    }

    setIsLoading(false);
  };

  const getBrandInformation = async () => {
    const response = await dispatch(startGetBrandInformation());
    if (response.ok) {
      const { data } = response;
      setBrandInformation(data);
    }
  };

  const onChangeStatusCampaign = async ({
    newStatus,
    target = "campaign",
    platform,
    adGroupID,
    adID,
    showAlertSave = false,
  }) => {
    const isValid = await trigger();
    if (!isValid) return;

    const objectUpdate = getCampaignUpdatedByStatus({
      campaign: getValues(),
      status: newStatus,
      target,
      platform,
      adGroupID,
      adID,
    });
    setIsUpdating(true);
    const response = await dispatch(
      startSaveAdsCampaign({
        campaign: {
          ...objectUpdate,
        },
        campaignID,
      })
    );
    setIsUpdating(false);

    if (response.ok) {
      setNestedValue({ data: response.campaign, setValue });

      if (showAlertSave) {
        SimpleAlert({
          title: t(ADS_CAMPAIGN_HAS_BEEN_SAVE),
          icon: ALERT_ICON_TYPE_SUCCESS,
        });
      }
    } else if (response.code === ERROR_PROCESSING_CAMPAIGNS) {
      setDataModalErrorsCampaigns({
        createdCampaigns: response?.createdCampaigns,
        failedCampaigns: response?.failedCampaigns,
        errors: response?.errors,
      });
      setModalErrorsCampaignsOpen(true);
      setNestedValue({ data: response.campaign, setValue });
    }
  };

  const onChangeSectionSelected = (section) => {
    if (isForcedSetupPage) {
      setIsForcedSetupPage(false);
    }
    if (platformGenerating) return;
    setSectionSelected(section);
  };

  const onGetItemSelectedLevel = ({ platform, adGroupID, adID }) => {
    if (!platform) return null;
    if (typeof adGroupID === "string") {
      return typeof adID === "string" ? "ad" : "adGroup";
    }
    return "platform";
  };

  const getIsCampaignPlatformActive = (platform) => {
    const state = states?.[platform];
    return (
      state === ADS_CAMPAIGN_STATUS_ACTIVE ||
      state === ADS_CAMPAIGN_STATUS_FINISHED
    );
  };
  const getIsCampaignPlatformCreated = (platform) => {
    const state = states?.[platform];
    return state !== ADS_CAMPAIGN_STATUS_DRAFT;
  };
  const getShowActionsPlatform = (platform) => {
    const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);
    const state = states?.[platform];

    return (
      isCampaignPlatformCreated &&
      state &&
      state !== ADS_CAMPAIGN_STATUS_DRAFT &&
      state !== ADS_CAMPAIGN_STATUS_FINISHED
    );
  };

  useEffect(() => {
    getBrandInformation();
    if (campaignIDParam) {
      setCampaignID(campaignIDParam);
      onGetInitialData(campaignIDParam);
      return;
    }

    const newCampaignID = dispatch(getKeyDatabase());
    setCampaignID(newCampaignID);
    navigate(`/ads-campaign?campaignID=${newCampaignID}`, {
      replace: true,
    });
    setIsLoading(false);

    // eslint-disable-next-line
  }, []);

  const levelSectionSelected = onGetItemSelectedLevel({
    platform: sectionSelected.platform,
    adGroupID: sectionSelected.adGroupID,
    adID: sectionSelected.adID,
  });

  const isGeneralCampaignActivated = getIsCampaignPlatformActive("general");
  const isGeneralCampaignCreated = getIsCampaignPlatformCreated("general");
  const isGeneralDisabledFields = dispatch(getUserIsViewer());

  const isAllCampaignsPlatformCreated = !platforms?.some(
    (platform) => !platformIDs?.[platform]
  );

  if (isLoading) {
    return (
      <Loader fullWidth hasMessage={true} message={t(LOADING_INFORMATION)} />
    );
  }
  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 64px)" }}>
      <FormProvider
        {...methods}
        campaignID={campaignID}
        currency={currency}
        onGenerateCampaignPlatform={onGenerateCampaignPlatform}
        platformGenerating={platformGenerating}
        isGenerating={Boolean(platformGenerating)}
        sectionSelected={sectionSelected}
        onChangeSectionSelected={onChangeSectionSelected}
        levelSectionSelected={levelSectionSelected}
        onGetItemSelectedLevel={onGetItemSelectedLevel}
        isGeneralCampaignActivated={isGeneralCampaignActivated}
        isGeneralCampaignCreated={isGeneralCampaignCreated}
        isGeneralDisabledFields={isGeneralDisabledFields}
        getIsCampaignPlatformCreated={getIsCampaignPlatformCreated}
        getIsCampaignPlatformActive={getIsCampaignPlatformActive}
        hasSomePlatformDraft={hasSomePlatformDraft}
        hasSomePlatformCreated={hasSomePlatformCreated}
        onChangeDataModalErrorsCampaigns={setDataModalErrorsCampaigns}
        onChangeModalErrorsCampaignsOpen={setModalErrorsCampaignsOpen}
        onChangeStatusCampaign={onChangeStatusCampaign}
        getShowActionsPlatform={getShowActionsPlatform}
        showErrorGenerating={showErrorGenerating}
        lastInformationToGenerate={lastInformationToGenerate}
        isUpdating={isUpdating}
        onChangeIsUpdating={setIsUpdating}
        isAllCampaignsPlatformCreated={isAllCampaignsPlatformCreated}
        isForcedSetupPage={isForcedSetupPage}
        onChangeIsForcedSetupPage={setIsForcedSetupPage}
        brandInformation={brandInformation}
      >
        <Sidebar />
        <GeneralContainer />
        {modalErrorsCampaignsOpen && (
          <ModalErrorsCampaigns
            modalOpen={modalErrorsCampaignsOpen}
            onCloseModal={() => setModalErrorsCampaignsOpen(false)}
            campaign={getValues()}
            successCampaigns={dataModalErrorsCampaigns.successCampaigns}
            failedCampaigns={dataModalErrorsCampaigns.failedCampaigns}
            errors={dataModalErrorsCampaigns.errors}
          />
        )}
      </FormProvider>
    </Box>
  );
};

export default NewAdsCampaign;
