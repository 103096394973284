const types = {
  //AUTH
  AUTH_LOGIN: "[AUTH] Login",
  AUTH_LOGOUT: "[AUTH] Logout",
  AUTH_UPDATE_USER: "[AUTH] Update User",
  AUTH_LOADING_FINISH: "[AUTH] Loading finish",
  AUTH_RENEW_TOKEN: "[AUTH] Renew token",

  //ADS CAMPAIGNS
  GET_ADS_CAMPAIGNS_FINISH: "[ADS CAMPAIGNS] Get Ads Campaigns Finish",
  GET_NEXT_ADS_CAMPAIGNS__FINISH:
    "[ADS CAMPAIGNS] Get Next Ads Campaigns Finish",
  DELETE_ADS_CAMPAIGN_FINISH: "[ADS CAMPAIGNS] Delete Ads Campaign Finish",
  CLEAN_ADS_CAMPAIGNS: "[ADS CAMPAIGNS] Clean Ads Campaigns",
  SAVE_ADS_CAMPAIGN_FINISH: "[ADS CAMPAIGNS] Save Ads Campaign Finish",
  SAVE_REPORT_ADS_CAMPAIGN_FINISH:
    "[ADS CAMPAIGNS] Save Report Ads Campaign Finish",

  //LOCALE
  GET_LOCALES_FINISH: "[LOCALE] Get Locales Finish",
  GET_LOCALE_FINISH: "[LOCALE] Get Locale Finish",
  SET_LOCALE_FINISH: "[LOCALE] Set Locale Finish",
  LOCALE_LOADING_FINISH: "[LOCALE] Locale Loading Finish",

  //USER
  GET_FACEBOOK_INTEGRATION: "[USER] Get Facebook Integration",
  REVOKE_FACEBOOK_INTEGRATION: "[USER] Revoke Facebook Integration",
  GET_INTEGRATIONS: "[USER] Get Integrations Finish",
  GET_LAST_SELECTED_INTEGRATION_DATA:
    "[USER] Get Last Selected Integration Data Finish",
  SET_LAST_SELECTED_INTEGRATION_DATA_BY_PLATFORM:
    "[USER] Set Last Selected Integration Data By Platform",
  GET_BUSINESS_NAME: "[USER] Get business name",
  GET_ADS_USERS: "[USER] Get Ads Users",
  MANAGE_USER: "[USER] Manage User",
  DELETE_USER: "[USER] Delete User",
  GET_CANVA_INTEGRATION: "[USER] Get Canva Integration",
  REVOKE_CANVA_INTEGRATION: "[USER] Revoke Canva Integration",
  GET_TIKTOK_BUSINESS_INTEGRATION: "[USER] Get Tiktok Business Integration",
  REVOKE_TIKTOK_BUSINESS_INTEGRATION:
    "[USER] Revoke Tiktok Business Integration",
  GET_GOOGLE_ADS_INTEGRATION: "[USER] Get Google Ads Integration",
  REVOKE_GOOGLE_ADS_INTEGRATION: "[USER] Revoke Google Ads Integration",
  GET_USER_IP: "[USER] Get User IP",
  CHANGE_ADVERTISER_ID_CUSTOM_CLAIM: "[USER] Change Advertiser ID Custom Claim",
  GET_IS_FREE_AI_VIDEO_GENERATED: "[USER] Get Is Free AI Video Generated",

  //UI
  UI_SET_OPEN_SLIDE_MENU: "[UI] Set Open Slide Menu",
  UI_SET_OPEN_NOTIFICATION: "[UI] Set Open Notification",
  UI_SET_SHOW_BACKDROP: "[UI] Set Show Backdrop",
  UI_SHOW_MODAL_SELECT_SUBSCRIPTION: "[UI] Show Modal Select Subscription",
  SET_VERSION: "[UI] Set Version",
  UI_SET_THEME: "[UI] Set Theme",
  RESET_UI_STATE: "[UI] Reset UI State",

  //PLANS AND PAYMENTS
  GET_PLANS_FINISH: "[PAYMENTS] Get Plans Finish",
  GET_PAYMENTS_FINISH: "[PAYMENTS] Get Payments Finish",
  LOADING_PAYMENTS_FINISH: "[PAYMENTS] Loading Payments Finish",
  GET_STRIPE_INFO_FINISH: "[PAYMENTS] Get Stripe Info Finish",
  SET_CUSTOMER_ID: "[PAYMENTS] Set Customer ID",
  SET_DEFAULT_PAYMENT_METHOD: "[PAYMENTS] Get Default Payment Method Finish",
  PAY_SUBSCRIPTION: "[PAYMENTS] Pay Subscription",
  CHANGE_SUBSCRIPTION_ADS: "[PAYMENTS] Change Subscription Ads",
  SET_PRORATED_PRICE_PLANS: "[PAYMENTS] Set Prorated Price Plans",
  PAY_INVOICE: "[PAYMENT] Pay Invoice",
  GET_SUBSCRIPTION_PLAN: "[PAYMENTS] Get Subscription Plan",
  GET_SUBSCRIPTION_PLAN_INFO: "[PAYMENTS] Get Subscription Plan Info",
  GET_SUBSCRIPTION_PLAN_INFO_USAGE:
    "[PAYMENTS] Get Subscription Plan Info Usage",
  SET_IS_ACTIVE_PLAN: "[PAYMENTS] Set Is Active Plan",
  PAY_SUBSCRIPTION_ADS: "[PAYMENTS] Pay Subscription Ads",
  CANCEL_SUBSCRIPTION: "[PAYMENTS] Cancel Subscription",
  REACTIVATE_SUBSCRIPTION: "[PAYMENTS] Reactivate Subscription",

  //CHAT
  OPEN_CHAT: "[CHAT] Open Chat",
  CLOSE_CHAT: "[CHAT] Close Chat",
  SET_CHATS: "[CHAT] Set Chats",
  SET_CURRENT_WRITE_MESSAGE: "[CHAT] Set Current Write Message",
  SET_CHATS_MESSAGES: "[CHAT] Set Chats Messages",
  SET_CURRENT_CHAT: "[CHAT] Set Current Chat",
  SET_CURRENT_CHAT_MESSAGES: "[CHAT] Set Current Chat Messages",
  SET_IS_TYPING_BOT: "[CHAT] Set Is Typing Bot",
  SET_IS_OPEN_CHAT: "[CHAT] Set Is Open Chat",
  MARK_READ_CHAT: "[CHAT] Mark Read Chat",
  MARK_DISABLED_CHAT: "[CHAT] Mark Disabled Chat",
  SET_NEW_CHAT: "[CHAT] Set New Chat",
  SET_UPLOADING_FILE: "[CHAT] Set Uploading File",
  CLEAR_TEMPORARY_CHATS: "[CHAT] Clear Temporary Chats",
  ADD_MESSAGE_CHAT: "[CHAT] Add Message Chat",
  ADD_MESSAGES_CHAT: "[CHAT] Add Messages Chat",
  ADD_MESSAGE_CHAT_IN_LIST: "[CHAT] Add Message Chat In List",

  //GLOBAL TYPES
  GLOBAL_RESET_STATE: "[GLOBAL] Reset State",
  GLOBAL_RESET_ADVERTISER_DATA: "[GLOBAL] Reset Advertiser data",
};

export default types;
