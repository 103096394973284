import { useEffect, useRef, useState } from "react";
import { Controller, useController, useFormContext } from "react-hook-form";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import {
  ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
  ADS_CAMPAIGN_MEDIA_FROM_PHYLLO,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_HEADLINE_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH,
  ADS_CAMPAIGN_TYPE_GALLERY_ADS_PLATFORMS_POSTS,
  IMAGE_FORMATS,
  META,
  MIN_DIMENSIONS_CONTENT,
  VIDEO_FORMATS,
} from "../../../../utils/constants";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getIsBlockedActions } from "../../../../actions/getters";
import { useDispatchApp } from "../../../../lib/redux";
import {
  generalFormatMediaUrl,
  getAdsTextsFormatted,
  getIsCarouselAdIsAvailable,
  getIsSpecialAdWithOnlyTitles,
  getIsSpecialAdWithoutCallToAction,
} from "../../../../services/adsCampaigns";
import {
  generateAdsCampaignDescription,
  startDeleteAdMediaID,
} from "../../../../actions/adsCampaigns";
import { getUniqueID } from "../../../../utils/numbers";
import { useMediaQuery } from "@mui/material";
import { useTranslationApp } from "../../../../lib/i18next";
import { getReactHookFormNestedError } from "../../../../utils/errors";
import {
  CALL_TO_ACTION_PLACEHOLDER,
  CARD,
  CONTENT,
  DESCRIPTION_PLACEHOLDER,
  DESCRIPTIONS,
  DESTINATION_URL_PLACEHOLDER,
  FIELD_MAX_LENGTH,
  FIELD_MIN_CARDS_AD_CAROUSEL,
  FIELD_REQUIRED,
  FIELD_TEXT_REPEATED,
  FIELD_URL_VALID,
  HEADLINE_PLACEHOLDER,
  HEADLINES,
  IS_CAROUSEL,
  TITLE_PLACEHOLDER,
  TITLES,
} from "../../../../i18n/keysTranslations";
import SelectCallToActionAds from "../../../Form/SelectCallToActionAds";
import Accordion from "../../../Containers/Accordion";
import { REGEX_URL } from "../../../../utils/regex";
import ContentField from "../../../Form/ContentField";
import AdPreview from "../../../../pages/AdsCampaign/components/AdPreview";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import TextField from "../../../Form/TextField";
import Loader from "../../../Loaders/Loader";
import { verifyDestinationURLIsABlockedDomain } from "../../../../utils/string";
import Switch from "../../../Form/Switch";
import { waitDelay } from "../../../../utils/date";

const platform = META;
const grid = {
  xs: 12,
  lg: 6,
};

const maxTitlesPlatform = 5;
const maxDescriptionsPlatform = 5;
const maxHeadlinesPlatform = 5;

const AdMetaForm = () => {
  const refPreview = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const {
    watch,
    getValues,
    control,
    formState,
    sectionSelected,
    isGeneralDisabledFields,
    getIsCampaignPlatformCreated,
    setValue,
  } = useFormContext();
  useFocusErrorForm(formState);

  const breakColumnFormAndPreview = useMediaQuery(() => "(max-width: 1080px)");
  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);

  const errors = formState.errors;
  const adsGroups = watch("adsGroups");

  const adGroupID = sectionSelected.adGroupID;
  const indexAdGroup = adsGroups.findIndex((item) => item.id === adGroupID);
  const adGroup = adsGroups[indexAdGroup];
  const BASIC_PATH_AD_GROUP = `adsGroups.[${indexAdGroup}]`;

  const adID = sectionSelected.adID;
  const indexAd = adGroup?.ads?.findIndex((item) => item.id === adID);
  const BASIC_PATH = `adsGroups.[${indexAdGroup}].ads.[${indexAd}]`;

  const isCarousel = watch(`${BASIC_PATH}.isCarousel`);
  const platformData = watch(`platformsData.${platform}`);
  const objective = platformData?.objective;

  const dispatch = useDispatchApp();

  const isSpecialAdWithOnlyTitles = getIsSpecialAdWithOnlyTitles({
    platform,
    destinationType: platformData?.destinationType,
    objective,
  });
  const isSpecialAdWithoutCallToAction = getIsSpecialAdWithoutCallToAction({
    platform,
    destinationType: platformData?.destinationType,
    objective,
  });
  const isCarouselAdsAvailable = getIsCarouselAdIsAvailable({
    platform,
    destinationType: platformData?.destinationType,
  });

  const onGenerateText = async ({ type, index }) => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_GENERATE_AD_TEXT_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }

    const currentTexts = getValues(`${BASIC_PATH}.${type}`) || [];

    const response = await dispatch(
      generateAdsCampaignDescription({
        objective,
        platform,
        websiteURL: getValues("websiteURL"),
        ageRange: getValues(`${BASIC_PATH_AD_GROUP}.ageRange`),
        genders: getValues(`${BASIC_PATH_AD_GROUP}.genders`),
        locations: getValues(`${BASIC_PATH_AD_GROUP}.locations`),
        interests: getValues(`${BASIC_PATH_AD_GROUP}.interests`),
        behaviors: getValues(`${BASIC_PATH_AD_GROUP}.behaviors`),
        demographics: getValues(`${BASIC_PATH_AD_GROUP}.demographics`),
        language: getValues(`language`),
        type,
        currentTexts: getAdsTextsFormatted({
          texts: currentTexts,
          format: "save",
        }),
      })
    );

    if (response) {
      onChangeText(`${BASIC_PATH}.${type}.[${index}].value`, response);
    }
  };

  const onAddText = async ({ type }) => {
    const currentTexts = getValues(`${BASIC_PATH}.${type}`) || [];
    setValue(`${BASIC_PATH}.${type}`, [
      ...currentTexts,
      {
        value: "",
        id: getUniqueID(),
      },
    ]);
  };
  const onRemoveText = async ({ type, index }) => {
    const currentTexts = getValues(`${BASIC_PATH}.${type}`);
    setValue(
      `${BASIC_PATH}.${type}`,
      currentTexts.filter((_, i) => i !== index)
    );
  };

  const onChangeIsCarousel = async (value) => {
    const ads = getValues(`adsGroups.[${indexAdGroup}].ads`);
    const id = getValues(`${BASIC_PATH}.id`);
    if (value) {
      let mediaData = [];
      let mediaUrls = [];
      let mediaIDs = [];
      let newDestinationUrls = [];

      const destinationUrls = getValues(`${BASIC_PATH}.destinationUrls`);
      const destinationUrl = destinationUrls?.[0]?.value || "";

      ads.forEach((ad) => {
        ad.mediaUrls.forEach((mediaUrl, index) => {
          mediaUrls.push(mediaUrl);
          mediaData.push(ad?.mediaData?.[index] || { from: "upload" });
          mediaIDs.push(ad?.mediaIDs?.[index] || "");
          newDestinationUrls.push({ value: destinationUrl, id: getUniqueID() });
        });
      });

      setValue(`${BASIC_PATH}.mediaUrls`, mediaUrls);
      setValue(`${BASIC_PATH}.mediaData`, mediaData);
      setValue(`${BASIC_PATH}.mediaIDs`, mediaIDs);
      setValue(`${BASIC_PATH}.destinationUrls`, newDestinationUrls);
    } else {
      const ad = ads.find((ad) => ad.id === id);
      setValue(`${BASIC_PATH}.mediaUrls`, [ad?.mediaUrls?.[0] || ""]);
      setValue(`${BASIC_PATH}.mediaData`, [
        ad?.mediaData?.[0] || { from: "upload" },
      ]);
      setValue(`${BASIC_PATH}.mediaIDs`, [ad?.mediaIDs?.[0] || ""]);
      setValue(`${BASIC_PATH}.destinationUrls`, [
        ad?.destinationUrls?.[0] || "",
      ]);
      setValue(
        `${BASIC_PATH}.titles`,
        ad?.titles.slice(0, 3) || [{ value: "", id: getUniqueID() }]
      );
      setValue(
        `${BASIC_PATH}.headlines`,
        ad?.headlines.slice(0, 3) || [{ value: "", id: getUniqueID() }]
      );
      setValue(
        `${BASIC_PATH}.descriptions`,
        ad?.descriptions.slice(0, 3) || [{ value: "", id: getUniqueID() }]
      );
    }
    setValue(`${BASIC_PATH}.isCarousel`, value);
    return true;
  };

  const onGeneratePreview = () => {
    if (refPreview?.current) {
      refPreview.current.generatePreview();
    }
  };

  const onChangeText = (name, value) => {
    setValue(name, value);
    onGeneratePreview();
  };

  //To reset values component when change selectedLevel
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await waitDelay(1);
      setIsLoading(false);
    })();
  }, [sectionSelected]);

  if (isLoading) return null;

  return (
    <>
      {isCarousel && (
        <AdsAdCarouselForm
          platform={platform}
          BASIC_PATH_AD_GROUP={BASIC_PATH_AD_GROUP}
          BASIC_PATH={BASIC_PATH}
          gridItems={grid}
          onAddText={onAddText}
          onRemoveText={onRemoveText}
          onGenerateText={onGenerateText}
          onChangeText={onChangeText}
          isSpecialAdWithOnlyTitles={isSpecialAdWithOnlyTitles}
          isSpecialAdWithoutCallToAction={isSpecialAdWithoutCallToAction}
          isGeneralDisabledFields={isGeneralDisabledFields}
          errors={errors}
          control={control}
          refPreview={refPreview}
          breakColumnFormAndPreview={breakColumnFormAndPreview}
          platformData={platformData}
          objective={objective}
          onGeneratePreview={onGeneratePreview}
          isCampaignPlatformCreated={isCampaignPlatformCreated}
          isCarousel={isCarousel}
          isCarouselAdsAvailable={isCarouselAdsAvailable}
          onChangeIsCarousel={onChangeIsCarousel}
        />
      )}
      {!isCarousel && (
        <AdsAdNormalForm
          platform={platform}
          BASIC_PATH_AD_GROUP={BASIC_PATH_AD_GROUP}
          BASIC_PATH={BASIC_PATH}
          gridItems={grid}
          onAddText={onAddText}
          onRemoveText={onRemoveText}
          onGenerateText={onGenerateText}
          onChangeText={onChangeText}
          isSpecialAdWithOnlyTitles={isSpecialAdWithOnlyTitles}
          isSpecialAdWithoutCallToAction={isSpecialAdWithoutCallToAction}
          isGeneralDisabledFields={isGeneralDisabledFields}
          errors={errors}
          control={control}
          refPreview={refPreview}
          breakColumnFormAndPreview={breakColumnFormAndPreview}
          platformData={platformData}
          objective={objective}
          isCampaignPlatformCreated={isCampaignPlatformCreated}
          isCarousel={isCarousel}
          isCarouselAdsAvailable={isCarouselAdsAvailable}
          onChangeIsCarousel={onChangeIsCarousel}
        />
      )}
    </>
  );
};

const AdsAdCarouselForm = ({
  objective,
  platform,
  platformData,
  breakColumnFormAndPreview,
  BASIC_PATH_AD_GROUP,
  BASIC_PATH,
  gridItems,
  onAddText,
  onRemoveText,
  onGenerateText,
  onChangeText,
  isSpecialAdWithOnlyTitles,
  isSpecialAdWithoutCallToAction,
  isGeneralDisabledFields,
  errors,
  control,
  refPreview,
  onGeneratePreview,
  isCampaignPlatformCreated,
  isCarousel,
  isCarouselAdsAvailable,
  onChangeIsCarousel,
}) => {
  const { watch, setValue } = useFormContext();
  const adID = watch(`${BASIC_PATH}.id`);
  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const errorMediaUrl = getReactHookFormNestedError(
    errors,
    `${BASIC_PATH}.mediaUrls`
  );

  const maxLengthTitle = ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[platform];
  const maxLengthDescription =
    ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[platform];
  const maxLengthHeadline = ADS_CAMPAIGN_PLATFORM_HEADLINE_MAX_LENGTH[platform];

  const maxTitles = maxTitlesPlatform;

  const destinationType = platformData?.destinationType;
  const websiteURL = watch("websiteURL");
  const socialNetworkURL = watch("socialNetworkURL");
  const productContext = watch("productContext");
  const targetContext = watch("targetContext");
  const language = watch("language");
  const ageRange = watch(`${BASIC_PATH_AD_GROUP}.ageRange`);
  const genders = watch(`${BASIC_PATH_AD_GROUP}.genders`);
  const mediaUrls = watch(`${BASIC_PATH}.mediaUrls`) || [""];
  const mediaData = watch(`${BASIC_PATH}.mediaData`) || [{}];
  const callToAction = watch(`${BASIC_PATH}.callToAction`);
  const titles = watch(`${BASIC_PATH}.titles`) || [{ value: "" }];
  const headlines = watch(`${BASIC_PATH}.headlines`) || [{ value: "" }];
  const descriptions = watch(`${BASIC_PATH}.descriptions`) || [{ value: "" }];
  const destinationUrls = watch(`${BASIC_PATH}.destinationUrls`) || [
    { value: "" },
  ];

  useController({
    name: `${BASIC_PATH}.mediaUrls`,
    control,
    rules: {
      required: {
        value: true,
        message: t(FIELD_REQUIRED),
      },
      validate: (value) => {
        if (value.length < 2) {
          return t(FIELD_MIN_CARDS_AD_CAROUSEL, {
            value: 2,
          });
        }
      },
    },
  });

  const showDeleteCardIcon = mediaUrls?.length > 2;
  const showAddCardIcon = mediaUrls?.length < 10;

  const onDuplicateCard = async (index) => {
    const mediaUrlItem = mediaUrls[index];
    const mediaDataItem = mediaData[index];
    const headlineItem = headlines[index];
    const descriptionItem = descriptions[index];
    const destinationUrlItem = destinationUrls[index];

    setValue(`${BASIC_PATH}.mediaUrls`, [...mediaUrls, mediaUrlItem]);
    setValue(`${BASIC_PATH}.mediaData`, [...mediaData, mediaDataItem]);

    if (!headlines?.[index + 1]) {
      setValue(`${BASIC_PATH}.headlines`, [...headlines, headlineItem]);
    }
    if (!descriptions?.[index + 1]) {
      setValue(`${BASIC_PATH}.descriptions`, [
        ...descriptions,
        descriptionItem,
      ]);
    }
    if (!destinationUrls?.[index + 1]) {
      setValue(`${BASIC_PATH}.destinationUrls`, [
        ...destinationUrls,
        destinationUrlItem,
      ]);
    }
    onGeneratePreview();
  };
  const onRemoveCard = async (index) => {
    setValue(
      `${BASIC_PATH}.mediaUrls`,
      mediaUrls.filter((_, i) => i !== index)
    );
    setValue(
      `${BASIC_PATH}.mediaData`,
      mediaData.filter((_, i) => i !== index)
    );
    setValue(
      `${BASIC_PATH}.headlines`,
      headlines.filter((_, i) => i !== index)
    );
    setValue(
      `${BASIC_PATH}.descriptions`,
      descriptions.filter((_, i) => i !== index)
    );
    setValue(
      `${BASIC_PATH}.destinationUrls`,
      destinationUrls.filter((_, i) => i !== index)
    );
    onGeneratePreview();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: breakColumnFormAndPreview ? "column" : "row",
        gap: 5,
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          height: "min-content",
        }}
      >
        <Grid
          item
          {...gridItems}
          lg={isSpecialAdWithOnlyTitles ? 12 : 6}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body1">{t(TITLES)}</Typography>
          {titles?.map((title, index) => (
            <TextFieldWithActions
              key={title.id}
              type="titles"
              label={`${t(TITLE_PLACEHOLDER)} ${index + 1}`}
              name={`${BASIC_PATH}.titles.[${index}].value`}
              disabled={isGeneralDisabledFields}
              showAddIcon={index === titles.length - 1 && index < maxTitles - 1}
              showDeleteIcon={index > 1}
              maxLength={maxLengthTitle}
              platform={platform}
              onChange={onChangeText}
              onAdd={() => onAddText({ type: "titles" })}
              onRemove={() => onRemoveText({ type: "titles", index })}
              onGenerate={async () => {
                await onGenerateText({ type: "titles", index });
              }}
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                validate: (value) => {
                  if (value.length > maxLengthTitle) {
                    return t(FIELD_MAX_LENGTH, {
                      value: maxLengthTitle,
                    });
                  }
                  const allTitles = titles.map((item) =>
                    (item.value || "").trim().toLowerCase()
                  );
                  const timesRepeated = allTitles.filter(
                    (text) => text === value.trim().toLowerCase()
                  ).length;

                  if (timesRepeated > 1) {
                    return t(FIELD_TEXT_REPEATED);
                  }
                  return true;
                },
              }}
              sx={{
                ".MuiInputBase-root": {
                  pb: 4,
                },
              }}
            />
          ))}
        </Grid>

        {!isSpecialAdWithoutCallToAction && (
          <Grid item {...gridItems}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="body1">
                {t(CALL_TO_ACTION_PLACEHOLDER)}
              </Typography>
              <SelectCallToActionAds
                disabled={isGeneralDisabledFields}
                name={`${BASIC_PATH}.callToAction`}
                fullWidth
                objective={objective}
                platform={platform}
                destinationType={platformData?.destinationType}
                doTranslate={true}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Box>
          </Grid>
        )}

        {errorMediaUrl && (
          <Grid item xs={12}>
            <Typography
              name={`${BASIC_PATH}.mediaUrls`}
              variant="body1"
              color="error"
              sx={{
                mt: 2,
                mb: -1,
              }}
            >
              {errorMediaUrl.message}
            </Typography>
          </Grid>
        )}
        {mediaUrls?.map((media, index) => {
          const description = descriptions[index] || { value: "" };
          const headline = headlines[index] || { value: "" };
          const destinationUrl = destinationUrls[index] || { value: "" };
          const mediaDataItem = mediaData?.[index] || {};

          return (
            <Grid item xs={12} key={index}>
              <Accordion
                title={`${t(CARD)} ${index + 1}`}
                actionsComponent={
                  isGeneralDisabledFields ? null : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        flexGrow: 1,
                        mr: { xs: 0, sm: 2 },
                        pl: { xs: 2, sm: 0 },
                      }}
                    >
                      {showAddCardIcon && (
                        <IconButton
                          type="button"
                          onClick={(e) => {
                            e?.stopPropagation();
                            onDuplicateCard(index);
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      )}
                      {showDeleteCardIcon && (
                        <IconButton
                          onClick={(e) => {
                            e?.stopPropagation();
                            onRemoveCard(index);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  )
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextFieldWithActions
                      key={destinationUrl.id}
                      type="destinationUrl"
                      label={`${t(DESTINATION_URL_PLACEHOLDER)}`}
                      name={`${BASIC_PATH}.destinationUrls.[${index}].value`}
                      disabled={isGeneralDisabledFields}
                      platform={platform}
                      showAddIcon={false}
                      showDeleteIcon={false}
                      maxLength={maxLengthDescription}
                      onChange={onChangeText}
                      control={control}
                      errors={errors}
                      rules={{
                        required: {
                          value: true,
                          message: t(FIELD_REQUIRED),
                        },
                        validate: (value) => {
                          if (!REGEX_URL.test(encodeURI(value))) {
                            return t(FIELD_URL_VALID);
                          }
                        },
                      }}
                      sx={{
                        ".MuiInputBase-root": {
                          pb: 4,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldWithActions
                      key={headline.id}
                      type="headlines"
                      label={`${t(HEADLINE_PLACEHOLDER)}`}
                      name={`${BASIC_PATH}.headlines.[${index}].value`}
                      disabled={isGeneralDisabledFields}
                      platform={platform}
                      showAddIcon={false}
                      showDeleteIcon={false}
                      maxLength={maxLengthHeadline}
                      onChange={onChangeText}
                      onAdd={() => onAddText({ type: "headlines" })}
                      onRemove={() =>
                        onRemoveText({ type: "headlines", index })
                      }
                      onGenerate={async () => {
                        await onGenerateText({ type: "headlines", index });
                      }}
                      control={control}
                      errors={errors}
                      rules={{
                        required: {
                          value: true,
                          message: t(FIELD_REQUIRED),
                        },
                        validate: (value) => {
                          if (value.length > maxLengthHeadline) {
                            return t(FIELD_MAX_LENGTH, {
                              value: maxLengthHeadline,
                            });
                          }

                          const allHeadlines = headlines
                            .map((item) =>
                              (item.value || "").trim().toLowerCase()
                            )
                            .slice(0, mediaUrls.length);
                          const timesRepeated = allHeadlines.filter(
                            (text) => text === value.trim().toLowerCase()
                          ).length;
                          if (timesRepeated > 1) {
                            return t(FIELD_TEXT_REPEATED);
                          }
                          return true;
                        },
                      }}
                      sx={{
                        ".MuiInputBase-root": {
                          pb: 4,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldWithActions
                      key={description.id}
                      type="descriptions"
                      label={`${t(DESCRIPTION_PLACEHOLDER)}`}
                      name={`${BASIC_PATH}.descriptions.[${index}].value`}
                      disabled={isGeneralDisabledFields}
                      platform={platform}
                      showAddIcon={false}
                      showDeleteIcon={false}
                      maxLength={maxLengthDescription}
                      onChange={onChangeText}
                      onAdd={() => onAddText({ type: "descriptions" })}
                      onRemove={() =>
                        onRemoveText({ type: "descriptions", index })
                      }
                      onGenerate={async () => {
                        await onGenerateText({ type: "descriptions", index });
                      }}
                      control={control}
                      errors={errors}
                      rules={{
                        required: {
                          value: true,
                          message: t(FIELD_REQUIRED),
                        },
                        validate: (value) => {
                          if (value.length > maxLengthDescription) {
                            return t(FIELD_MAX_LENGTH, {
                              value: maxLengthDescription,
                            });
                          }

                          const allDescriptions = descriptions
                            .map((item) =>
                              (item.value || "").trim().toLowerCase()
                            )
                            .slice(0, mediaUrls.length);
                          const timesRepeated = allDescriptions.filter(
                            (text) => text === value.trim().toLowerCase()
                          ).length;
                          if (timesRepeated > 1) {
                            return t(FIELD_TEXT_REPEATED);
                          }
                          return true;
                        },
                      }}
                      sx={{
                        ".MuiInputBase-root": {
                          pb: 4,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ContentField
                      propsSelectContentComponent={{
                        acceptedFormats: VIDEO_FORMATS.concat(IMAGE_FORMATS),
                        showButtonUpload: true,
                        showGenerateWithAIOption:
                          destinationType !==
                          ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
                        showUploadDeviceOption:
                          destinationType !==
                          ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
                        showGalleryOption:
                          destinationType !==
                          ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
                        propsGalleryAdsPlatforms: {
                          integrationID: platformData?.integrationID,
                          adAccountID: platformData?.adAccountID,
                          pageID: platformData?.pageID,
                          socialNetworkURL,
                          from:
                            destinationType ===
                            ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
                              ? ADS_CAMPAIGN_TYPE_GALLERY_ADS_PLATFORMS_POSTS
                              : ADS_CAMPAIGN_MEDIA_FROM_PHYLLO,
                        },
                        propsGenerateAI: {
                          videoData: mediaDataItem?.videoID
                            ? { ...mediaDataItem, id: mediaDataItem?.videoID }
                            : null,
                          websiteURL,
                          socialNetworkURL,
                          language,
                          integrationID: platformData?.integrationID,
                          adAccountID: platformData?.adAccountID,
                          campaignContext: {
                            objective,
                            productContext,
                            targetContext,
                            genders,
                            ageRange,
                          },
                        },
                      }}
                      minDimensions={MIN_DIMENSIONS_CONTENT}
                      onCallbackSave={({ from, content }) => {
                        const contentFormatted = generalFormatMediaUrl({
                          content,
                          from,
                        });
                        dispatch(
                          startDeleteAdMediaID({
                            adID,
                            index,
                          })
                        );
                        setValue(`${BASIC_PATH}.mediaIDs.[${index}]`, "");
                        setValue(
                          `${BASIC_PATH}.mediaUrls.[${index}]`,
                          contentFormatted.url
                        );
                        setValue(
                          `${BASIC_PATH}.mediaData.[${index}]`,
                          contentFormatted.mediaData
                        );
                        onGeneratePreview();
                      }}
                      label={t(CONTENT)}
                      name={`${BASIC_PATH}.mediaUrls.[${index}]`}
                      control={control}
                      errors={errors}
                      rules={{
                        required: {
                          value: true,
                          message: t(FIELD_REQUIRED),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
      <Box
        sx={{
          width: "100%",
          maxWidth: 320,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isCarouselAdsAvailable && (
          <Controller
            name={`${BASIC_PATH}.isCarousel`}
            control={control}
            render={() => {
              return (
                <Switch
                  disabled={isCampaignPlatformCreated}
                  isActive={isCarousel}
                  labelActive={IS_CAROUSEL}
                  labelDeactivate={IS_CAROUSEL}
                  onChangeIsActive={(checked) => onChangeIsCarousel(checked)}
                  sx={{ mr: 1.5 }}
                />
              );
            }}
          />
        )}
        <AdPreview
          adID={adID}
          platform={platform}
          ref={refPreview}
          mediaUrls={mediaUrls}
          mediaData={mediaData}
          callToAction={callToAction}
          titles={titles}
          headlines={headlines}
          descriptions={descriptions}
          destinationUrls={destinationUrls}
          basicPath={BASIC_PATH}
          basicPathAdGroup={BASIC_PATH_AD_GROUP}
        />
      </Box>
    </Box>
  );
};

const AdsAdNormalForm = ({
  objective,
  platform,
  platformData,
  breakColumnFormAndPreview,
  BASIC_PATH_AD_GROUP,
  BASIC_PATH,
  gridItems,
  onAddText,
  onRemoveText,
  onGenerateText,
  onChangeText,
  isSpecialAdWithOnlyTitles,
  isSpecialAdWithoutCallToAction,
  isGeneralDisabledFields,
  errors,
  control,
  refPreview,
  isCampaignPlatformCreated,
  isCarousel,
  isCarouselAdsAvailable,
  onChangeIsCarousel,
}) => {
  const { watch } = useFormContext();
  const { t } = useTranslationApp();

  const maxLengthTitle = ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[platform];
  const maxLengthDescription =
    ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[platform];
  const maxLengthHeadline = ADS_CAMPAIGN_PLATFORM_HEADLINE_MAX_LENGTH[platform];

  const maxTitles = maxTitlesPlatform;
  const maxDescriptions = maxDescriptionsPlatform;
  const maxHeadlines = maxHeadlinesPlatform;

  const adID = watch(`${BASIC_PATH}.id`);

  const mediaUrls = watch(`${BASIC_PATH}.mediaUrls`);
  const mediaData = watch(`${BASIC_PATH}.mediaData`);
  const callToAction = watch(`${BASIC_PATH}.callToAction`);
  const titles = watch(`${BASIC_PATH}.titles`) || [{ value: "" }];
  const headlines = watch(`${BASIC_PATH}.headlines`) || [{ value: "" }];
  const descriptions = watch(`${BASIC_PATH}.descriptions`) || [{ value: "" }];
  const isDestinationTypeWebsite =
    platformData?.destinationType ===
    ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: breakColumnFormAndPreview ? "column" : "row",
        gap: 5,
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          height: "min-content",
        }}
      >
        <Grid
          item
          {...gridItems}
          lg={isSpecialAdWithOnlyTitles ? 12 : 6}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body1">{t(TITLES)}</Typography>
          {titles?.map((title, index) => (
            <TextFieldWithActions
              key={title.id}
              type="titles"
              label={`${t(TITLE_PLACEHOLDER)} ${index + 1}`}
              name={`${BASIC_PATH}.titles.[${index}].value`}
              disabled={isGeneralDisabledFields}
              showAddIcon={index === titles.length - 1 && index < maxTitles - 1}
              showDeleteIcon={index > 1}
              platform={platform}
              onChange={onChangeText}
              onAdd={() => onAddText({ type: "titles" })}
              onRemove={() => onRemoveText({ type: "titles", index })}
              maxLength={maxLengthTitle}
              onGenerate={async () => {
                await onGenerateText({ type: "titles", index });
              }}
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                validate: (value) => {
                  if (value.length > maxLengthTitle) {
                    return t(FIELD_MAX_LENGTH, {
                      value: maxLengthTitle,
                    });
                  }

                  const allTitles = titles.map((item) =>
                    (item.value || "").trim().toLowerCase()
                  );
                  const timesRepeated = allTitles.filter(
                    (text) => text === value.trim().toLowerCase()
                  ).length;
                  if (timesRepeated > 1) {
                    return t(FIELD_TEXT_REPEATED);
                  }
                  return true;
                },
              }}
              sx={{
                ".MuiInputBase-root": {
                  pb: 4,
                },
              }}
            />
          ))}
        </Grid>
        {!isSpecialAdWithOnlyTitles && (
          <Grid
            item
            {...gridItems}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1">{t(DESCRIPTIONS)}</Typography>
            {descriptions?.map((description, index) => (
              <TextFieldWithActions
                key={description.id}
                type="descriptions"
                label={`${t(DESCRIPTION_PLACEHOLDER)} ${index + 1}`}
                name={`${BASIC_PATH}.descriptions.[${index}].value`}
                disabled={isGeneralDisabledFields}
                platform={platform}
                showAddIcon={
                  index === descriptions.length - 1 &&
                  index < maxDescriptions - 1
                }
                showDeleteIcon={index > 1}
                maxLength={maxLengthDescription}
                onChange={onChangeText}
                onAdd={() => onAddText({ type: "descriptions" })}
                onRemove={() => onRemoveText({ type: "descriptions", index })}
                onGenerate={async () => {
                  await onGenerateText({ type: "descriptions", index });
                }}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                  validate: (value) => {
                    if (value.length > maxLengthDescription) {
                      return t(FIELD_MAX_LENGTH, {
                        value: maxLengthDescription,
                      });
                    }
                    const allDescriptions = descriptions.map((item) =>
                      (item.value || "").trim().toLowerCase()
                    );
                    const timesRepeated = allDescriptions.filter(
                      (text) => text === value.trim().toLowerCase()
                    ).length;
                    if (timesRepeated > 1) {
                      return t(FIELD_TEXT_REPEATED);
                    }
                    return true;
                  },
                }}
                sx={{
                  ".MuiInputBase-root": {
                    pb: 4,
                  },
                }}
              />
            ))}
          </Grid>
        )}
        {platform === META && !isSpecialAdWithOnlyTitles && (
          <Grid
            item
            {...gridItems}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1">{t(HEADLINES)}</Typography>
            {headlines?.map((headline, index) => (
              <TextFieldWithActions
                key={headline.id}
                type="headlines"
                label={`${t(HEADLINE_PLACEHOLDER)} ${index + 1}`}
                name={`${BASIC_PATH}.headlines.[${index}].value`}
                disabled={isGeneralDisabledFields}
                platform={platform}
                showAddIcon={
                  index === headlines.length - 1 && index < maxHeadlines - 1
                }
                showDeleteIcon={index > 1}
                onChange={onChangeText}
                onAdd={() => onAddText({ type: "headlines" })}
                onRemove={() => onRemoveText({ type: "headlines", index })}
                maxLength={maxLengthHeadline}
                onGenerate={async () => {
                  await onGenerateText({ type: "headlines", index });
                }}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                  validate: (value) => {
                    if (value.length > maxLengthHeadline) {
                      return t(FIELD_MAX_LENGTH, {
                        value: maxLengthHeadline,
                      });
                    }
                    const allHeadlines = headlines.map((item) =>
                      (item.value || "").trim().toLowerCase()
                    );
                    const timesRepeated = allHeadlines.filter(
                      (text) => text === value.trim().toLowerCase()
                    ).length;
                    if (timesRepeated > 1) {
                      return t(FIELD_TEXT_REPEATED);
                    }
                    return true;
                  },
                }}
                sx={{
                  ".MuiInputBase-root": {
                    pb: 4,
                  },
                }}
              />
            ))}
          </Grid>
        )}
        {isDestinationTypeWebsite && (
          <>
            <Grid item {...gridItems}></Grid>
            <Grid item {...gridItems}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="body1">
                  {t(DESTINATION_URL_PLACEHOLDER)}
                </Typography>
                <TextField
                  name={`${BASIC_PATH}.destinationUrls.[0].value`}
                  label={t(DESTINATION_URL_PLACEHOLDER)}
                  variant="filled"
                  fullWidth
                  disabled={isGeneralDisabledFields}
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: false,
                    },
                    validate: (value) => {
                      if (!value) {
                        return t(FIELD_REQUIRED);
                      }
                      if (value && !REGEX_URL.test(encodeURI(value))) {
                        return t(FIELD_URL_VALID);
                      }
                      const isUrlValidDomain =
                        verifyDestinationURLIsABlockedDomain({
                          destinationURL: value,
                          destinationType: platformData?.destinationType,
                        });
                      if (!isUrlValidDomain.ok) {
                        return t(isUrlValidDomain.message);
                      }
                    },
                  }}
                />
              </Box>
            </Grid>
          </>
        )}
        {!isSpecialAdWithoutCallToAction && (
          <Grid item {...gridItems}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="body1">
                {t(CALL_TO_ACTION_PLACEHOLDER)}
              </Typography>
              <SelectCallToActionAds
                disabled={isGeneralDisabledFields}
                name={`${BASIC_PATH}.callToAction`}
                fullWidth
                objective={objective}
                platform={platform}
                destinationType={platformData?.destinationType}
                doTranslate={true}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <Box
        sx={{
          width: "100%",
          maxWidth: 320,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isCarouselAdsAvailable && (
          <Controller
            name={`${BASIC_PATH}.isCarousel`}
            control={control}
            render={() => {
              return (
                <Switch
                  disabled={isCampaignPlatformCreated}
                  isActive={isCarousel}
                  labelActive={IS_CAROUSEL}
                  labelDeactivate={IS_CAROUSEL}
                  onChangeIsActive={(checked) => onChangeIsCarousel(checked)}
                  sx={{ mr: 1.5 }}
                />
              );
            }}
          />
        )}
        <AdPreview
          ref={refPreview}
          adID={adID}
          mediaUrls={mediaUrls}
          mediaData={mediaData}
          callToAction={callToAction}
          titles={titles}
          headlines={headlines}
          descriptions={descriptions}
          platform={platform}
          basicPath={BASIC_PATH}
          basicPathAdGroup={BASIC_PATH_AD_GROUP}
        />
      </Box>
    </Box>
  );
};

const TextFieldWithActions = ({
  name,
  label,
  disabled,
  control,
  errors,
  rules,
  sx,
  showAddIcon = true,
  showDeleteIcon = true,
  onChange,
  onAdd = () => {},
  onRemove = () => {},
  onGenerate = () => {},
  type = "titles",
  maxLength = 0,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const getRows = () => {
    if (type === "headlines" || type === "destinationUrl") {
      return { min: 1, max: 2 };
    }
    return { min: 3, max: 3 };
  };

  const rows = getRows();

  const isDisabled = disabled || isGenerating;
  const showGenerateIcon = type !== "destinationUrl";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      <TextField
        label={label}
        name={name}
        variant="filled"
        multiline
        showCounterCharacters={true}
        maxCharacters={maxLength}
        minRows={rows.min}
        maxRows={rows.max}
        fullWidth
        disabled={disabled}
        doOnChange={false}
        actionsChange={(value) => onChange(name, value)}
        control={control}
        errors={errors}
        rules={rules}
        sx={{ ...sx, zIndex: 1, position: "relative" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          position: "absolute",
          mb: 1,
          mr: 1,
          right: 0,
          height: "100%",
          mt: -5,
        }}
      >
        {showDeleteIcon && (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={() => onRemove()}
            sx={{
              p: 0.4,
              zIndex: 1,
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {showGenerateIcon && (
          <>
            {isGenerating ? (
              <Loader
                size={20}
                hasMarginTop={false}
                sx={{
                  mb: 0.75,
                }}
              />
            ) : (
              <IconButton
                disabled={isDisabled}
                size={"small"}
                onClick={async () => {
                  setIsGenerating(true);
                  await onGenerate();
                  setIsGenerating(false);
                }}
                sx={{
                  p: 0.4,
                  zIndex: 1,
                }}
              >
                <AutoAwesomeIcon />
              </IconButton>
            )}
          </>
        )}
        {showAddIcon && (
          <IconButton
            disabled={isDisabled}
            size={"small"}
            onClick={() => onAdd()}
            sx={{
              p: 0.4,
              zIndex: 1,
            }}
          >
            <AddIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default AdMetaForm;
