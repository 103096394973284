import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
} from "../../../../utils/constants";
import Switch from "../../../../components/Form/Switch";
import Box from "@mui/material/Box";
import { ACTIVE, DEACTIVATED } from "../../../../i18n/keysTranslations";
import { waitDelay } from "../../../../utils/date";

const SwitchStatusItem = ({
  selectedSection,
  hasLabel = false,
  forceRefreshForSelectedSection = false,
  sx = {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const {
    onGetItemSelectedLevel,
    watch,
    control,
    onChangeStatusCampaign,
    isUpdating,
  } = useFormContext();
  const itemLevel = onGetItemSelectedLevel(selectedSection);

  const getName = () => {
    if (itemLevel === "platform") {
      return `states.${selectedSection.platform}`;
    }

    if (itemLevel === "adGroup") {
      const adsGroups = watch("adsGroups") || [];
      const adGroupID = selectedSection.adGroupID;
      const indexAdGroup = adsGroups.findIndex(
        (adGroup) => adGroup.id === adGroupID
      );
      return `adsGroups.[${indexAdGroup}].status`;
    }

    if (itemLevel === "ad") {
      const adsGroups = watch("adsGroups") || [];
      const adGroupID = selectedSection.adGroupID;
      const adID = selectedSection.adID;
      const indexAdGroup = adsGroups.findIndex(
        (adGroup) => adGroup.id === adGroupID
      );
      const indexAd = adsGroups[indexAdGroup].ads.findIndex(
        (ad) => ad.id === adID
      );
      return `adsGroups.[${indexAdGroup}].ads.[${indexAd}].status`;
    }
  };

  const onChangeSwitch = async (value) => {
    setIsSaving(true);

    let indexAdGroup = "";
    let indexAd = "";

    if (itemLevel === "adGroup") {
      const adsGroups = watch("adsGroups") || [];
      indexAdGroup = adsGroups.findIndex(
        (adGroup) => adGroup.id === selectedSection.adGroupID
      );
    }
    if (itemLevel === "ad") {
      const adsGroups = watch("adsGroups") || [];
      indexAdGroup = adsGroups.findIndex(
        (adGroup) => adGroup.id === selectedSection.adGroupID
      );
      indexAd = adsGroups[indexAdGroup].ads.findIndex(
        (ad) => ad.id === selectedSection.adID
      );
    }

    await onChangeStatusCampaign({
      newStatus: value
        ? ADS_CAMPAIGN_STATUS_ACTIVE
        : ADS_CAMPAIGN_STATUS_DEACTIVATED,
      target: itemLevel,
      platform: selectedSection.platform,
      adGroupID: indexAdGroup,
      adID: indexAd,
    });
    setIsSaving(false);
  };

  //To reset values component when change selectedSection
  useEffect(() => {
    (async () => {
      if (!forceRefreshForSelectedSection) return;
      setIsLoading(true);
      await waitDelay(1);
      setIsLoading(false);
    })();
  }, [selectedSection, forceRefreshForSelectedSection]);

  if (isLoading) return <Box sx={{ height: 38 }} />;

  return (
    <Box sx={sx}>
      <Controller
        name={getName()}
        control={control}
        render={({ field }) => (
          <Switch
            hasLabel={hasLabel}
            disabled={isUpdating}
            loading={isSaving}
            labelActive={ACTIVE}
            labelDeactivate={DEACTIVATED}
            isActive={field.value === ADS_CAMPAIGN_STATUS_ACTIVE}
            labelPlacement="end"
            onChangeIsActive={(value) => onChangeSwitch(value)}
          />
        )}
      />
    </Box>
  );
};

export default SwitchStatusItem;
