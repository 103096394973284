import {
  createElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Skeleton from "@mui/material/Skeleton";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import {
  startDeleteAdMediaID,
  startGetAdPreview,
} from "../../../actions/adsCampaigns";
import { Controller, useForm, useFormContext } from "react-hook-form";
import {
  formatMediaUrlAdFromCreatify,
  formatMediaUrl,
  formattedIframeByType,
  getAdsWithUnRenderedAndFailedRenderedMedia,
  getIsSpecialAdWithoutCallToAction,
} from "../../../services/adsCampaigns";
import { useTranslationApp } from "../../../lib/i18next";
import {
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_STANDARD,
  ADS_CAMPAIGN_AD_FORMAT_TIKTOK_FEED_CAROUSEL_ADS,
  ADS_CAMPAIGN_AD_FORMAT_TIKTOK_FEED_SINGLE_VIDEO,
  ADS_CAMPAIGN_MEDIA_FROM_CREATIFY,
  ADS_CAMPAIGN_MEDIA_FROM_PHYLLO,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
  ADS_CAMPAIGN_RENDERED_VIDEO_ACTION,
  ADS_CAMPAIGN_TYPE_GALLERY_ADS_PLATFORMS_POSTS,
  AFTER_RENDER_ACTION_DOWNLOAD,
  ALERT_ICON_TYPE_ERROR,
  DELAY_DEBOUNCE,
  FACEBOOK,
  GOOGLE,
  IMAGE_FORMATS,
  META,
  MIN_DIMENSIONS_CONTENT,
  TIKTOK,
  VIDEO_DONE_STATUS_CREATIFY,
  VIDEO_FORMATS,
  VIDEO_IN_QUEUE_STATUS_CREATIFY,
} from "../../../utils/constants";
import {
  ADS_CAMPAIGN_AD_FORMAT_FACEBOOK_MOBILE_FEED_STANDARD,
  ADS_CAMPAIGN_AD_FORMAT_FACEBOOK_REELS_MOBILE,
  ADS_CAMPAIGN_AD_FORMAT_GOOGLE_SEARCH,
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_REEL,
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_STORY,
  BUTTON_CHANGE_CONTENT,
  BUTTON_DOWNLOAD,
  BUTTON_SELECT_CONTENT,
  CREATIFY_VIDEO_ERROR_RENDER_CLARIFICATION,
  CREATIFY_VIDEO_PREVIEW_CLARIFICATION,
  CREATIFY_VIDEO_RENDERING_CLARIFICATION,
  ERROR,
  FIELD_AD_FORMAT_REQUIRED_AD_PREVIEW,
  FIELD_CALL_TO_ACTION_REQUIRED_AD_PREVIEW,
  FIELD_CONTENT_IS_BEING_PROCESSED,
  FIELD_IDENTITY_TIK_TOK_ID_REQUIRED_AD_PREVIEW,
  FIELD_INSTAGRAM_ID_REQUIRED_AD_PREVIEW,
  FIELD_PAGE_ID_REQUIRED_AD_PREVIEW,
  FIELD_POST_ID_REQUIRED_AD_PREVIEW,
  FIELD_REQUIRED,
  FORMAT_PLACEHOLDER,
} from "../../../i18n/keysTranslations";
import { useDebounced } from "../../../hooks/useDebounce";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconButton from "../../../components/Buttons/IconButton";
import Select from "../../../components/Form/Select";
import Typography from "@mui/material/Typography";
import IconButtonAddContent from "../../../components/Buttons/IconButtonAddContent";
import { getPathDatabaseByRole } from "../../../actions/auth";
import { getReactHookFormNestedError } from "../../../utils/errors";
import { useMediaQuery } from "@mui/material";
import _ from "lodash";
import MetaContainerIntegration from "../../../components/Meta/MetaContainerIntegration";
import TikTokBusinessContainerIntegration from "../../../components/TikTokBusiness/TikTokBusinessContainerIntegration";
import CardMedia from "../../../components/Media/CardMedia";
import {
  getExtensionFile,
  verifyDestinationURLIsABlockedDomain,
} from "../../../utils/string";
import DownloadIcon from "@mui/icons-material/Download";
import {
  startGetAwsDownloadUrl,
  startRenderVideoFromCreatify,
} from "../../../actions/user";
import BackdropLoaderRenderMediaCreatify from "../../../components/Loaders/BackdropLoaderRenderMediaCreatify";
import { SimpleAlert } from "../../../components/Alerts/Alerts";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import { useNavigate } from "react-router-dom";
import { getIsBlockedActions } from "../../../actions/getters";
import GoogleContainerIntegration from "../../../components/Google/GoogleContainerIntegration";

const COMPONENTS_PLATFORM = {
  [META]: MetaContainerIntegration,
  [TIKTOK]: TikTokBusinessContainerIntegration,
  [GOOGLE]: GoogleContainerIntegration,
};
const FORMATS_CAROUSEL = [
  ADS_CAMPAIGN_AD_FORMAT_FACEBOOK_MOBILE_FEED_STANDARD,
  ADS_CAMPAIGN_AD_FORMAT_FACEBOOK_REELS_MOBILE,
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_STORY,
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_STANDARD,
];
const FORMATS_INSTAGRAM = [
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_STORY,
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_REEL,
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_STANDARD,
];
const FORMATS_TIKTOK_VIDEO = [ADS_CAMPAIGN_AD_FORMAT_TIKTOK_FEED_SINGLE_VIDEO];
const FORMATS_TIKTOK_IMAGE = [ADS_CAMPAIGN_AD_FORMAT_TIKTOK_FEED_CAROUSEL_ADS];
const FORMATS_GOOGLE = [ADS_CAMPAIGN_AD_FORMAT_GOOGLE_SEARCH];

const getAvailableFormats = ({ platform, isVideo, isCarousel }) => {
  switch (platform) {
    case META:
      if (isCarousel) {
        return FORMATS_CAROUSEL;
      }
      return FORMATS_INSTAGRAM;
    case TIKTOK:
      return isVideo ? FORMATS_TIKTOK_VIDEO : FORMATS_TIKTOK_IMAGE;
    case GOOGLE:
      return FORMATS_GOOGLE;
    default:
      return [];
  }
};

const getConfigurationPreviewType = ({
  platform,
  mediaData = [],
  mediaUrls = [],
  platformData,
}) => {
  if (platform === GOOGLE) {
    const showAdPreview = Boolean(
      mediaUrls?.length > 0 &&
        platformData?.adAccountID &&
        platformData?.integrationID
    );
    const showMediaUpload = Boolean(!showAdPreview && mediaUrls?.length > 0);
    return {
      showPreviewCreatify: false,
      showAdPreview,
      showMediaUpload,
    };
  } else {
    const hasEmptyMediaUrls = mediaUrls?.some((mediaUrl) => !mediaUrl);
    const hasEmptyMediaDataPostID = mediaData?.some(
      (mediaDataItem) => !mediaDataItem?.postID
    );

    const showPreviewCreatify = mediaData?.some((mediaDataItem, index) => {
      const mediaUrl = mediaUrls?.[index];

      return Boolean(
        mediaDataItem?.from === ADS_CAMPAIGN_MEDIA_FROM_CREATIFY &&
          mediaDataItem?.preview &&
          !mediaUrl
      );
    });

    const showAdPreview = Boolean(
      ((!hasEmptyMediaUrls && mediaUrls?.length > 0) ||
        !hasEmptyMediaDataPostID) &&
        platformData?.adAccountID &&
        platformData?.integrationID
    );

    const showMediaUpload = Boolean(
      !showPreviewCreatify && !showAdPreview && mediaUrls?.length > 0
    );

    return {
      showPreviewCreatify,
      showAdPreview,
      showMediaUpload,
    };
  }
};

const AdPreview = forwardRef((props, ref) => {
  const {
    adID,
    platform,
    mediaUrls = [],
    mediaData = [],
    callToAction,
    titles,
    headlines,
    descriptions,
    basicPath,
    basicPathAdGroup,
  } = props;
  const { isGeneralDisabledFields } = useFormContext();
  const [currentMediaIndex] = useState(0);
  const [adPreview, setAdPreview] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [triggerGeneratePreview, setTriggerGeneratePreview] = useState(0);
  const [isGenerating, setIsGenerating] = useState(true);
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [error, setError] = useState(null);
  const [renderMediaData, setRenderMediaData] = useState(null);

  const currentMediaUrl = mediaUrls[currentMediaIndex];
  const currentMediaData = mediaData[currentMediaIndex];

  const { campaignID, watch, setValue, getValues } = useFormContext();

  const isCarousel = watch(`${basicPath}.isCarousel`);

  const navigate = useNavigate();
  const extension = getExtensionFile(currentMediaUrl);
  const isVideo = VIDEO_FORMATS.includes(extension);
  const formats = getAvailableFormats({ platform, isVideo, isCarousel });

  const {
    control,
    errors,
    setValue: setValueComponent,
    watch: watchComponent,
  } = useForm({
    defaultValues: {
      adFormat: formats[0],
    },
  });
  const errorMediaUrl = getReactHookFormNestedError(
    errors,
    `${basicPath}.mediaUrls`
  );
  const adFormat = watchComponent("adFormat");

  const platformData = watch(`platformsData.${platform}`) || {};
  const destinationType = platformData?.destinationType;
  const platforms = watch("platforms");
  const websiteURL = watch("websiteURL");
  const language = watch("language");
  const objective = watch("objective");
  const mediaIDs = watch(`${basicPath}.mediaIDs`);
  const destinationUrls = watch(`${basicPath}.destinationUrls`) || [];

  const dispatch = useDispatchApp();

  const metaIntegrations = useSelectorApp(
    (state) => state?.user?.integrations?.[FACEBOOK]
  );
  const { t } = useTranslationApp();
  const isMobile = useMediaQuery(() => "(max-width: 360px)");
  const noHasPlatformData = _.isEmpty(metaIntegrations);

  const { showPreviewCreatify, showAdPreview, showMediaUpload } =
    getConfigurationPreviewType({
      platform,
      mediaData,
      mediaUrls,
      platformData,
    });

  const { height, width, src } = formattedIframeByType({
    type: adFormat,
    iframe: adPreview || "",
    isMobile,
  });
  const { userID } = dispatch(getPathDatabaseByRole());

  const pageID = platformData?.pageID;
  const instagramID = platformData?.instagramID;
  const identityTikTokID = platformData?.identityTikTokID;

  const validateDataToGeneratePreview = () => {
    const destinationTypeIsInstagram =
      destinationType ===
      ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION;

    const destinationURL = destinationUrls?.[0]?.value;
    const isUrlValidDomain = verifyDestinationURLIsABlockedDomain({
      destinationURL,
      destinationType,
    });

    if (!isUrlValidDomain.ok && !destinationTypeIsInstagram) {
      return isUrlValidDomain;
    }
    if (!adFormat) {
      return { ok: false, message: FIELD_AD_FORMAT_REQUIRED_AD_PREVIEW };
    }
    if (
      ((platform === META &&
        !getIsSpecialAdWithoutCallToAction({
          platform,
          objective,
          destinationType,
        })) ||
        platform === TIKTOK) &&
      !callToAction
    ) {
      return { ok: false, message: FIELD_CALL_TO_ACTION_REQUIRED_AD_PREVIEW };
    }
    if (platform === META) {
      if (!pageID) {
        return { ok: false, message: FIELD_PAGE_ID_REQUIRED_AD_PREVIEW };
      }
      if (!instagramID) {
        return { ok: false, message: FIELD_INSTAGRAM_ID_REQUIRED_AD_PREVIEW };
      }
      if (
        destinationType === ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION &&
        !currentMediaData?.postID
      )
        return {
          ok: false,
          message: FIELD_POST_ID_REQUIRED_AD_PREVIEW,
        };
    }
    if (platform === TIKTOK) {
      if (!identityTikTokID) {
        return {
          ok: false,
          message: FIELD_IDENTITY_TIK_TOK_ID_REQUIRED_AD_PREVIEW,
        };
      }
    }

    return { ok: true };
  };

  const getInitialData = useDebounced(async () => {
    const someMediaUrlIsBlob = mediaUrls?.some((mediaUrl) =>
      mediaUrl?.includes("blob:")
    );
    if (someMediaUrlIsBlob) {
      return;
    }

    if (showPreviewCreatify) {
      return;
    }

    if (!showAdPreview) {
      setIsGenerating(false);
      setIsLoadingIframe(true);
      return;
    }

    const isValidData = validateDataToGeneratePreview();
    if (!isValidData.ok) {
      setIsGenerating(false);
      setIsLoadingIframe(true);
      return setError(t(isValidData.message));
    }

    const response = await dispatch(
      startGetAdPreview({
        platform,
        campaignID,
        adID,
        mediaUrls,
        mediaData,
        mediaIDs,
        callToAction,
        titles: titles ? titles.map((title) => title.value) : [],
        headlines: headlines ? headlines.map((headline) => headline.value) : [],
        descriptions: descriptions
          ? descriptions.map((description) => description.value)
          : [],
        destinationUrls: destinationUrls
          ? destinationUrls.map((url) => url.value)
          : [],
        pageID,
        instagramID,
        identityTikTokID,
        adFormats: [adFormat],
        platformData,
        isCarousel,
      })
    );
    if (!response.ok) {
      setError(response.message);
    }

    if (response.ok) {
      const { adPreviews: adPreviewsResponse } = response.data;
      setAdPreview(adPreviewsResponse[adFormat]);
    }
    setIsLoadingIframe(true);
    setIsGenerating(false);
  }, [DELAY_DEBOUNCE * 4]);

  useEffect(() => {
    if (formats.includes(adFormat)) return;
    setValueComponent("adFormat", formats[0]);

    // eslint-disable-next-line
  }, [isCarousel]);

  useEffect(() => {
    setError(null);
    setIsGenerating(true);
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentMediaUrl,
    currentMediaData?.postID,
    callToAction,
    pageID,
    instagramID,
    identityTikTokID,
    triggerGeneratePreview,
    adFormat,
    destinationType,
    isCarousel,
  ]);

  const onTriggerGeneratePreview = () =>
    setTriggerGeneratePreview((prev) => prev + 1);

  useImperativeHandle(ref, () => ({
    generatePreview: onTriggerGeneratePreview,
  }));

  const onChangeAdFormat = (direction) => {
    const currentIndex = formats.indexOf(adFormat);
    let nextIndex;
    if (direction === "next") {
      nextIndex = currentIndex + 1;
      if (nextIndex >= formats.length) {
        nextIndex = 0;
      }
    }
    if (direction === "back") {
      nextIndex = currentIndex - 1;
      if (nextIndex < 0) {
        nextIndex = formats.length - 1;
      }
    }

    setValueComponent("adFormat", formats[nextIndex]);
  };

  const onRenderCreatifyVideo = async () => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_CAMPAIGN_RENDERED_VIDEO_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }

    setIsDownloading(true);

    const campaign = getValues();

    const { adsWithUnRenderedMedia } =
      getAdsWithUnRenderedAndFailedRenderedMedia(campaign);

    if (adsWithUnRenderedMedia.length === 0) return;

    const renderItemDataFound = adsWithUnRenderedMedia.find(
      (renderData) => renderData.videoID === currentMediaData.videoID
    );

    if (!renderItemDataFound) return;

    const response = await dispatch(
      startRenderVideoFromCreatify({
        videoID: renderItemDataFound.videoID,
        adIDs: renderItemDataFound.adIDs,
      })
    );

    setIsDownloading(false);

    if (!response.ok) {
      return;
    }

    const adsGroups = campaign.adsGroups;
    adsGroups.forEach((adsGroup, indexAdGroup) => {
      const ads = adsGroup.ads;

      ads.forEach((ad, indexAd) => {
        if (renderItemDataFound.adIDs.includes(ad.id)) {
          setValue(
            `adsGroups.[${indexAdGroup}].ads.[${indexAd}].mediaData.[${currentMediaIndex}].status`,
            VIDEO_IN_QUEUE_STATUS_CREATIFY
          );
        }
      });
    });

    setRenderMediaData([renderItemDataFound]);
    navigate(
      `/ads-campaign?campaignID=${campaignID}&afterRenderAction=${AFTER_RENDER_ACTION_DOWNLOAD}`
    );
  };
  const onUpdateProgress = async (renderItemData) => {
    const adIDs = renderItemData.adIDs;
    const videoData = renderItemData.videoData;
    const newStatus = videoData.status;
    const videoOutput = videoData.videoOutput;

    const data = getValues();
    const adsGroups = data.adsGroups;

    adsGroups.forEach((adsGroup, indexAdGroup) => {
      const ads = adsGroup.ads;

      ads.forEach((ad, indexAd) => {
        if (!adIDs.includes(ad.id)) return;
        setValue(
          `adsGroups.[${indexAdGroup}].ads.[${indexAd}].mediaData.[${currentMediaIndex}].status`,
          newStatus
        );
        if (newStatus === VIDEO_DONE_STATUS_CREATIFY) {
          setValue(
            `adsGroups.[${indexAdGroup}].ads.[${indexAd}].mediaUrls.[${currentMediaIndex}]`,
            videoOutput || ""
          );
        }
      });
    });

    if (newStatus !== VIDEO_DONE_STATUS_CREATIFY) {
      SimpleAlert({
        title: t(ERROR),
        text: t(CREATIFY_VIDEO_ERROR_RENDER_CLARIFICATION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      setRenderMediaData(null);
      return;
    }

    setRenderMediaData(null);
    await dispatch(startGetAwsDownloadUrl(videoOutput));
  };

  const commonSxContainer = {
    display: "flex",
    flexDirection: "column",
    gap: 1.5,
    maxWidth: width,
    width: "100%",
  };

  if (showPreviewCreatify) {
    return (
      <Box sx={commonSxContainer}>
        {renderMediaData && (
          <BackdropLoaderRenderMediaCreatify
            open={Boolean(renderMediaData)}
            data={renderMediaData}
            onRenderMediaFinished={onUpdateProgress}
            mainLabel={CREATIFY_VIDEO_RENDERING_CLARIFICATION}
            sx={{
              maxWidth: 720,
              width: "100%",
            }}
          />
        )}
        {noHasPlatformData &&
          !isGeneralDisabledFields &&
          createElement(COMPONENTS_PLATFORM[platform], {
            onChangeIntegration: ({ integrationID, adAccountID }) => {
              setValue(
                `platformsData.${platform}.integrationID`,
                integrationID
              );
              setValue(`platformsData.${platform}.adAccountID`, adAccountID);
            },
          })}
        <SelectNewContent
          userID={userID}
          adID={adID}
          basicPath={basicPath}
          basicPathAdGroup={basicPathAdGroup}
          control={control}
          videoData={currentMediaData}
          mediaUrl={currentMediaData.preview}
          currentMediaIndex={currentMediaIndex}
          onTriggerGeneratePreview={onTriggerGeneratePreview}
          platforms={platforms}
          platformData={platformData}
          websiteURL={websiteURL}
          language={language}
          errorMediaUrl={errorMediaUrl}
        />
        {isLoadingIframe && (
          <Skeleton variant="rectangular" width={width} height={height} />
        )}
        <iframe
          src={currentMediaData.preview}
          width={"100%"}
          height={height}
          onLoad={() => setIsLoadingIframe(false)}
          style={{
            display: isLoadingIframe ? "none" : "block",
            border: isMobile ? "none" : "1px solid white",
            overflow: "hidden",
            maxWidth: width,
          }}
        />
        {currentMediaData.preview && !isGeneralDisabledFields && (
          <LoadingButton
            loading={isDownloading}
            type="button"
            startIcon={<DownloadIcon />}
            onClick={onRenderCreatifyVideo}
          >
            {t(BUTTON_DOWNLOAD)}
          </LoadingButton>
        )}
        <Alert variant="outlined" severity="info">
          {t(CREATIFY_VIDEO_PREVIEW_CLARIFICATION)}
        </Alert>
      </Box>
    );
  }

  if (showMediaUpload) {
    return (
      <Box sx={commonSxContainer}>
        {noHasPlatformData &&
          !isGeneralDisabledFields &&
          createElement(COMPONENTS_PLATFORM[platform], {
            onChangeIntegration: ({ integrationID, adAccountID }) => {
              setValue(
                `platformsData.${platform}.integrationID`,
                integrationID
              );
              setValue(`platformsData.${platform}.adAccountID`, adAccountID);
            },
          })}
        <SelectNewContent
          userID={userID}
          adID={adID}
          basicPath={basicPath}
          basicPathAdGroup={basicPathAdGroup}
          control={control}
          mediaUrl={currentMediaUrl}
          currentMediaIndex={currentMediaIndex}
          onTriggerGeneratePreview={onTriggerGeneratePreview}
          platforms={platforms}
          platformData={platformData}
          websiteURL={websiteURL}
          language={language}
          errorMediaUrl={errorMediaUrl}
        />
        <CardMedia
          url={currentMediaUrl}
          sx={{
            height,
            maxWidth: width,
            width: { xs: "100%", sm: width },
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        maxWidth: width,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <SelectAdPreviewFormat
          control={control}
          errors={errors}
          onChangeAdFormat={onChangeAdFormat}
          formats={formats}
        />
        <SelectNewContent
          userID={userID}
          adID={adID}
          basicPath={basicPath}
          basicPathAdGroup={basicPathAdGroup}
          control={control}
          mediaUrl={currentMediaUrl}
          currentMediaIndex={currentMediaIndex}
          onTriggerGeneratePreview={onTriggerGeneratePreview}
          platforms={platforms}
          platformData={platformData}
          websiteURL={websiteURL}
          language={language}
          errorMediaUrl={errorMediaUrl}
        />
      </Box>
      {showAdPreview && (
        <AdMediaPreview
          isGenerating={isGenerating}
          isMobile={isMobile}
          isLoadingIframe={isLoadingIframe}
          src={src}
          width={width}
          height={height}
          error={error}
          onChangeIsLoadingIframe={setIsLoadingIframe}
        />
      )}
    </Box>
  );
});

const AdMediaPreview = ({
  isGenerating,
  isMobile,
  isLoadingIframe,
  src,
  width,
  height,
  error,
  onChangeIsLoadingIframe,
}) => {
  return (
    <Box>
      {isGenerating && (
        <Skeleton
          variant="rounded"
          sx={{
            maxWidth: width,
            width: "100%",
            height,
          }}
        />
      )}
      {!isGenerating && error && (
        <Box>
          <Typography variant="body1" color="error" align="center">
            {error}
          </Typography>
        </Box>
      )}
      {!isGenerating && !error && (
        <Box
          sx={{
            maxWidth: width,
            width: "100%",
            height,
          }}
        >
          {isLoadingIframe && (
            <Skeleton
              variant="rectangular"
              sx={{
                maxWidth: width,
                width: "100%",
                height,
              }}
            />
          )}
          <iframe
            src={src}
            onLoad={() => onChangeIsLoadingIframe(false)}
            scrolling="no"
            style={{
              display: isLoadingIframe ? "none" : "block",
              border: isMobile ? "none" : "1px solid white",
              overflow: "hidden",
              maxWidth: width,
              width: "100%",
              height,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

const SelectNewContent = ({
  basicPath,
  basicPathAdGroup,
  control,
  userID,
  adID,
  currentMediaIndex,
  onTriggerGeneratePreview,
  videoData: videoDataProp,
  mediaUrl,
  platforms,
  platformData,
  websiteURL,
  language,
  errorMediaUrl,
}) => {
  const { t } = useTranslationApp();
  const { isGeneralDisabledFields, setValue, watch } = useFormContext();
  const dispatch = useDispatchApp();

  const socialNetworkURL = watch("socialNetworkURL");
  const destinationType = platformData?.destinationType;
  const objective = watch("objective");
  const productContext = watch("productContext");
  const targetContext = watch("targetContext");
  const ageRange = watch(`${basicPathAdGroup}.ageRange`);
  const genders = watch(`${basicPathAdGroup}.genders`);
  const isCarousel = watch(`${basicPath}.isCarousel`);

  const onChangeContent = async (content) => {
    await dispatch(
      startDeleteAdMediaID({
        adID,
        index: currentMediaIndex,
      })
    );
    setValue(`${basicPath}.mediaIDs.[${currentMediaIndex}]`, "");
    setValue(
      `${basicPath}.mediaUrls.[${currentMediaIndex}]`,
      formatMediaUrl(content).url
    );
    setValue(
      `${basicPath}.mediaData.[${currentMediaIndex}]`,
      formatMediaUrl(content).mediaData
    );
    onTriggerGeneratePreview();
  };

  const onChangeContentWithGenerateID = async (content) => {
    const contentFormatted = formatMediaUrlAdFromCreatify(content);

    await dispatch(
      startDeleteAdMediaID({
        adID,
        index: currentMediaIndex,
      })
    );
    setValue(`${basicPath}.mediaIDs.[${currentMediaIndex}]`, "");
    setValue(
      `${basicPath}.mediaUrls.[${currentMediaIndex}]`,
      contentFormatted.url
    );
    setValue(
      `${basicPath}.mediaData.[${currentMediaIndex}]`,
      contentFormatted.mediaData
    );
    onTriggerGeneratePreview();
  };

  if (isGeneralDisabledFields || isCarousel) return;

  return (
    <>
      <Controller
        name={`${basicPath}.mediaUrls`}
        control={control}
        rules={{
          required: {
            value: true,
            message: t(FIELD_REQUIRED),
          },
          validate: (value) => {
            if (value?.includes("blob:")) {
              return t(FIELD_CONTENT_IS_BEING_PROCESSED);
            }
          },
        }}
        render={() => (
          <IconButtonAddContent
            id={`add-content-media-${basicPath}`}
            mode="button"
            minDimensions={MIN_DIMENSIONS_CONTENT}
            showButtonUpload={true}
            pathStorage={`adsCampaigns/${userID}/`}
            modeUpload="sync"
            onAddContent={(contents) => {
              onChangeContent(contents[0]);
            }}
            onCallbackSaveAI={(videoData) =>
              onChangeContentWithGenerateID(videoData)
            }
            multiple={false}
            acceptedFormats={VIDEO_FORMATS.concat(
              platforms.includes(TIKTOK) ? [] : IMAGE_FORMATS
            )}
            urlContent={mediaUrl}
            textButton={
              mediaUrl ? BUTTON_CHANGE_CONTENT : BUTTON_SELECT_CONTENT
            }
            showGenerateWithAIOption={
              destinationType !==
              ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
            }
            showUploadDeviceOption={
              destinationType !==
              ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
            }
            showGalleryOption={
              destinationType !==
              ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
            }
            propsGalleryAdsPlatforms={{
              integrationID: platformData?.integrationID,
              adAccountID: platformData?.adAccountID,
              pageID: platformData?.pageID,
              socialNetworkURL,
              from:
                destinationType ===
                ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
                  ? ADS_CAMPAIGN_TYPE_GALLERY_ADS_PLATFORMS_POSTS
                  : ADS_CAMPAIGN_MEDIA_FROM_PHYLLO,
            }}
            propsGenerateAI={{
              videoData: videoDataProp?.videoID
                ? { ...videoDataProp, id: videoDataProp?.videoID }
                : null,
              websiteURL,
              socialNetworkURL,
              language,
              integrationID: platformData?.integrationID,
              adAccountID: platformData?.adAccountID,
              campaignContext: {
                objective,
                productContext,
                targetContext,
                genders,
                ageRange,
              },
            }}
            color="secondary"
            sx={{
              gap: 1,
              alignItems: "flex-start",
              width: "100%",
            }}
            sxButton={{
              width: "100%",
            }}
          />
        )}
      />
      {errorMediaUrl && (
        <Typography color="error" variant="caption">
          {errorMediaUrl?.message}
        </Typography>
      )}
    </>
  );
};

const SelectAdPreviewFormat = ({
  control,
  errors,
  onChangeAdFormat,
  formats,
}) => {
  const { t } = useTranslationApp();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "100%",
      }}
    >
      {formats.length > 1 && (
        <IconButton onClick={() => onChangeAdFormat("back")} edge="start">
          <ArrowBackIcon />
        </IconButton>
      )}
      <Select
        variant="filled"
        name="adFormat"
        label={t(FORMAT_PLACEHOLDER)}
        options={formats.map((format) => ({
          value: format,
          label: t(format),
        }))}
        control={control}
        errors={errors}
        fullWidth={true}
      />
      {formats.length > 1 && (
        <IconButton onClick={() => onChangeAdFormat("next")} edge="end">
          <ArrowForwardIcon />
        </IconButton>
      )}
    </Box>
  );
};

AdPreview.displayName = "AdPreview";

export default AdPreview;
