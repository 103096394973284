import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import {
  BUTTON_CONNECT_ACCOUNT,
  BUTTON_CONNECT_META,
  ENDING_CONNECTION,
  RETRIEVING_AD_ACCOUNTS,
  RETRIEVING_FACEBOOK_PAGES,
  RETRIEVING_GENERAL_INFORMATION,
  RETRIEVING_INSTAGRAM_ACCOUNTS,
} from "../../i18n/keysTranslations";
import {
  ALERT_ICON_TYPE_INFO,
  BUTTON_META_ACTION_ID,
  META_WHITE,
  META,
} from "../../utils/constants";
import { useTranslationApp } from "../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import LoadingButton from "./LoadingButton";
import { GRADIENT_META } from "../../utils/colors";
import { startConnectFacebook } from "../../actions/user";
import { logoPlatform } from "../../utils/string";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "./IconButton";
import Loader from "../Loaders/Loader";
import { ConfirmAlert } from "../Alerts/Alerts";
import LinearLoaderWithTime from "../Loaders/LinearLoaderWithTime";
import { getNameBusinessTheme } from "../../actions/getters";
import AdsPlatformsLogos from "../Containers/AdsPlatformsLogos";
import { createPortal } from "react-dom";

const ButtonMetaAction = ({
  id = BUTTON_META_ACTION_ID,
  text = BUTTON_CONNECT_META,
  disabled = false,
  prevBlockClick = false,
  onPrevBlockClick = () => {},
  onClick = () => {},
  showGlobalLoading = false,
  sx = {},
  // scope = META_SCOPES,
  logo = META_WHITE,
  showLogo = true,
  hasShadow = true,
  type = "button",
  mode = "verify",
  icon = <AddIcon />,
  disableRipple = false,
  showButtonIcon = false,
  doTranslation = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const integrations = useSelectorApp((state) => state?.user?.integrations);
  const metaIntegrations = integrations?.facebook || {};
  const isMetaConnected = Object.values(metaIntegrations).some(
    (integration) => integration.isActive
  );
  const NAME_BUSINESS = dispatch(getNameBusinessTheme());

  if (type === "icon") {
    return (
      <>
        {openBackDrop &&
          createPortal(<BackDropLoader open={openBackDrop} />, document.body)}
        <IconButton
          id={id}
          disableRipple={disableRipple}
          sx={{ ...sx }}
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (prevBlockClick) {
              onPrevBlockClick();
              return;
            }

            if ((!isMetaConnected && mode === "verify") || mode === "add") {
              const result = await ConfirmAlert({
                icon: ALERT_ICON_TYPE_INFO,
                confirmButtonText: t(BUTTON_CONNECT_ACCOUNT),
                html: (
                  <AdsPlatformsLogos
                    platform={META}
                    NAME_BUSINESS={NAME_BUSINESS}
                  />
                ),
              });
              if (!result.isConfirmed) return;

              dispatch(
                startConnectFacebook({
                  showGlobalLoading,
                  onChangeLoadingAuth: (loading) => setIsLoading(loading),
                  onChangeLoadingConnect: (loading) => setOpenBackDrop(loading),
                  onCallbackConnected: onClick,
                })
              );
              return;
            }

            setIsLoading(true);
            onClick();
            setIsLoading(false);
          }}
        >
          {isLoading ? <Loader hasMarginTop={false} size={20} /> : icon}
        </IconButton>
      </>
    );
  }

  return (
    <>
      {openBackDrop &&
        createPortal(<BackDropLoader open={openBackDrop} />, document.body)}
      <LoadingButton
        id={id}
        loading={isLoading}
        disabled={disabled}
        color="primary"
        hasShadow={hasShadow}
        sx={{
          textTransform: "none",
          background: GRADIENT_META,
          width: "100%",
          ...sx,
        }}
        onClick={async (e) => {
          e.preventDefault();
          if (prevBlockClick) {
            onPrevBlockClick();
            return;
          }

          if ((!isMetaConnected && mode === "verify") || mode === "add") {
            const result = await ConfirmAlert({
              html: <AdsPlatformsLogos platform={META} />,
              icon: ALERT_ICON_TYPE_INFO,
              confirmButtonText: t(BUTTON_CONNECT_ACCOUNT),
            });
            if (!result.isConfirmed) return;
            dispatch(
              startConnectFacebook({
                showGlobalLoading,
                onChangeLoadingAuth: (loading) => setIsLoading(loading),
                onChangeLoadingConnect: (loading) => setOpenBackDrop(loading),
                onCallbackConnected: onClick,
              })
            );
            return;
          }

          setIsLoading(true);
          await onClick();
          setIsLoading(false);
        }}
      >
        {showButtonIcon && icon}
        {showLogo && (
          <Avatar
            src={logoPlatform(logo)}
            variant="square"
            sx={{
              width: "20px",
              height: "20px",
              mr: 1,
              ".MuiAvatar-img": {
                objectFit: "contain",
              },
            }}
          />
        )}
        {doTranslation ? t(text) : text}
      </LoadingButton>
    </>
  );
};

const BackDropLoader = ({ open }) => {
  const { t } = useTranslationApp();

  return (
    <Backdrop
      sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 100 })}
      open={open}
    >
      <Box
        sx={{
          maxWidth: 720,
          width: "100%",
        }}
      >
        <LinearLoaderWithTime
          time={30 * 1000}
          labels={[
            t(RETRIEVING_GENERAL_INFORMATION),
            t(RETRIEVING_AD_ACCOUNTS),
            t(RETRIEVING_FACEBOOK_PAGES),
            t(RETRIEVING_INSTAGRAM_ACCOUNTS),
            t(ENDING_CONNECTION),
          ]}
        />
      </Box>
    </Backdrop>
  );
};
export default ButtonMetaAction;
