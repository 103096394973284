import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import IconButton from "../Buttons/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../Loaders/Loader";
import { useTranslationApp } from "../../lib/i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ConfirmAlert, SimpleAlert } from "../Alerts/Alerts";
import Typography from "@mui/material/Typography";
import { logoPlatform } from "../../utils/string";
import AddIcon from "@mui/icons-material/Add";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";
import {
  AD_ACCOUNTS,
  ALERT_DESCRIPTION_DISCONNECT_GOOGLE,
  ARE_YOU_SURE,
  BUTTON_CONNECTED,
  BUTTON_DISCONNECT,
  READY,
} from "../../i18n/keysTranslations";
import {
  ALERT_ICON_TYPE_SUCCESS,
  BORDER_RADIUS,
  GOOGLE,
} from "../../utils/constants";
import {
  startRevokeGoogleAdsAccount,
  startSetLastSelectedIntegrationDataByPlatform,
} from "../../actions/user";
import ButtonGoogleAction from "../Buttons/ButtonGoogleAction";

const GoogleContainerIntegration = ({
  textConnected = BUTTON_CONNECTED,
  id,
  sx = {},
  onClick = () => {},
  modeConnected = "add",
  modeDisconnected = "verify",
  onChangeIntegration = () => {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const onOpenMenu = (e) => setAnchorEl(e.currentTarget);
  const onCloseMenu = () => setAnchorEl(null);

  const dispatch = useDispatchApp();
  const {
    isLoading,
    integrations,
    lastSelectedIntegrationData: {
      [GOOGLE]: {
        adAccountID: adAccountIDSelected,
        integrationID: integrationIDSelected,
      } = {
        adAccountID: null,
        integrationID: null,
      },
    } = {},
  } = useSelectorApp((state) => state.user);
  const googleIntegrations = integrations?.google || {};
  const isGoogleConnected = Object.values(googleIntegrations).some(
    (integration) => integration.isActive
  );

  const { t } = useTranslationApp();

  useEffect(() => {
    if (!integrationIDSelected && !adAccountIDSelected) {
      onPreSelectData();
    }

    onChangeIntegration({
      integrationID: integrationIDSelected,
      adAccountID: adAccountIDSelected,
    });
    // eslint-disable-next-line
  }, [integrationIDSelected, adAccountIDSelected]);

  const onChangeSelectedAdAccount = ({ integrationID, adAccountID }) =>
    dispatch(
      startSetLastSelectedIntegrationDataByPlatform({
        platform: GOOGLE,
        adAccountID,
        integrationID,
      })
    );

  const onPreSelectData = () => {
    if (_.isEmpty(googleIntegrations)) return;

    const integrationID = Object.keys(googleIntegrations || {})[0];
    const adAccountID = Object.keys(
      googleIntegrations?.[integrationID]?.advertisers || {}
    )[0];

    onChangeSelectedAdAccount({
      integrationID,
      adAccountID,
    });
  };

  const getAdAccountInformation = () => {
    const integration = googleIntegrations?.[integrationIDSelected] || {};
    const adAccount = integration?.advertisers?.[adAccountIDSelected] || {};

    return adAccount;
  };
  const searchIntegrationIDByAdAccountID = (adAccountID) => {
    const integration = Object.values(googleIntegrations).find((integration) =>
      Object.keys(integration.advertisers).includes(adAccountID)
    );
    return integration?.id;
  };

  const adAccount = getAdAccountInformation();

  if (isLoading) return null;
  return (
    <>
      {isGoogleConnected ? (
        <Box
          onClick={(e) => {
            onOpenMenu(e);
            onClick(e);
          }}
          sx={{
            backgroundColor: "primary.main",
            display: "flex",
            alignItems: "center",
            px: 2,
            borderRadius: BORDER_RADIUS,
            gap: 1,
            width: "fit-content",
            cursor: "pointer",
            minHeight: 40,
            ...sx,
          }}
        >
          <AvatarMini src={logoPlatform(GOOGLE)} />
          <Typography
            variant="body1"
            sx={{
              minWidth: 80,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {!_.isEmpty(adAccount) ? adAccount.name : t(textConnected)}
          </Typography>
          <KeyboardArrowDownIcon />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Divider orientation="vertical" flexItem />
            <ButtonGoogleAction
              id={id}
              type="icon"
              mode={modeConnected}
              icon={<AddIcon />}
              disableRipple={true}
              sx={{ mr: -2 }}
              onClick={() => {
                SimpleAlert({
                  title: t(READY),
                  icon: ALERT_ICON_TYPE_SUCCESS,
                });
              }}
            />
          </Box>
        </Box>
      ) : (
        <ButtonGoogleAction
          id={id}
          mode={modeDisconnected}
          type="button"
          onClick={onClick}
          sx={{
            ...sx,
          }}
        />
      )}
      <FormControl
        sx={{
          position: "absolute",
        }}
      >
        <RadioGroup
          value={adAccountIDSelected}
          onChange={(e, adAccountID) => {
            const integrationID = searchIntegrationIDByAdAccountID(adAccountID);
            onChangeSelectedAdAccount({
              integrationID,
              adAccountID,
            });
          }}
        >
          <Menu anchorEl={anchorEl} open={open} onClose={onCloseMenu}>
            {Object.values(googleIntegrations).map((integration) => (
              <AccountIntegrationMenu
                key={integration.id}
                integration={integration}
              />
            ))}
          </Menu>
        </RadioGroup>
      </FormControl>
    </>
  );
};

const AccountIntegrationMenu = ({ integration = {} }) => {
  const [isFetching, setIsFetching] = useState(false);
  const { id, advertisers = {} } = integration;

  const advertisersKeys = Object.keys(advertisers);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const onRemoveTokenTikTokBusiness = async () => {
    const result = await ConfirmAlert({
      title: t(ARE_YOU_SURE),
      text: t(ALERT_DESCRIPTION_DISCONNECT_GOOGLE),
      confirmButtonText: t(BUTTON_DISCONNECT),
    });
    if (!result.isConfirmed) return;
    setIsFetching(true);
    await dispatch(
      startRevokeGoogleAdsAccount({
        showGlobalLoading: false,
        id,
      })
    );
    setIsFetching(false);
  };

  const commonSxContainerType = {
    width: "100%",
    mt: 1,
    display: "flex",
    flexDirection: "column",
  };

  return (
    <MenuItem
      disableTouchRipple={true}
      disableRipple={true}
      sx={{
        display: "flex",
        flexDirection: "column",
        "&:hover": {
          backgroundColor: "inherit",
          cursor: "default",
        },
        mb: 0.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: 1,
        }}
      >
        <Typography
          variant="body1"
          textAlign={"left"}
          sx={{
            width: "fit-content",
          }}
        >
          {id}
        </Typography>
        {isFetching ? (
          <Loader size={15} hasMarginTop={false} />
        ) : (
          <IconButton
            onClick={onRemoveTokenTikTokBusiness}
            sx={{
              mt: "-1px",
              p: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      {advertisersKeys.length > 0 && (
        <Box sx={commonSxContainerType}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 700,
            }}
          >
            {t(AD_ACCOUNTS)}
          </Typography>
          {advertisersKeys.map((key) => (
            <FormControlLabel
              key={advertisers[key].id}
              value={advertisers[key].id}
              control={
                <Radio
                  sx={{
                    p: 0,
                    pt: 0,
                    pb: 0.5,
                    px: 1,
                  }}
                />
              }
              label={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                  }}
                >
                  <Typography variant="body1">
                    {advertisers[key].name}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "text.secondary",
                      mt: -0.5,
                    }}
                  >
                    {advertisers[key].id}
                  </Typography>
                </Box>
              }
            />
          ))}
        </Box>
      )}
    </MenuItem>
  );
};

const AvatarMini = ({ src, sx }) => (
  <Avatar
    alt="Logo de TikTok"
    src={src}
    variant="square"
    sx={{
      height: 20,
      width: 20,
      borderRadius: BORDER_RADIUS,
      "& .MuiAvatar-img": {
        objectFit: "contain",
      },
      ...sx,
    }}
  />
);
export default GoogleContainerIntegration;
