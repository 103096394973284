import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { useGenerateCampaignFormGoogle } from "../../../../hooks/platforms/google/useGenerateCampaignFormGoogle";
import AnswerContainer from "../../../TypeForm/AnswerContainer";
import AnswerContainerTitleAndField from "../../../TypeForm/AnswerContainerTitleAndField";
import { Alert, LinearProgress, IconButton } from "@mui/material";
import AnswerTitle from "../../../TypeForm/AnswerTitle";
import AnswerSubTitle from "../../../TypeForm/AnswerSubtitle";
import ActionsButtons from "../../../TypeForm/ActionsButtons";
import { useTranslationApp } from "../../../../lib/i18next";
import Select from "../../../Form/Select";
import { optionsCampaignsTypeGoogleAds } from "../../../../utils/options";
import {
  AD_ACCOUNT_GOOGLE_PLACEHOLDER,
  CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER,
  CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER,
  SELECT_A_OPTION,
  VALUE_EXPRESSED_IN,
} from "../../../../i18n/keysTranslations";
import { ADS_CAMPAIGN_MEDIA_FROM_CREATIFY } from "../../../../utils/constants";
import SelectBusinessAccount from "../../../Meta/SelectBusinessAccount";
import TextField from "../../../Form/TextField";
import NumberField from "../../../Form/NumberField";
import CardMedia from "../../../Media/CardMedia";
import CloseIcon from "@mui/icons-material/Close";
import { GOOGLE } from "../../../../utils/constants";
import _ from "lodash";

const GenerateCampaignFormGoogle = ({
  initialData,
  campaignID,
  onCallbackSave = () => {},
}) => {
  const config = useGenerateCampaignFormGoogle({
    campaignID,
    onCallbackSave,
  });
  const currentAnswer = config.currentAnswer;
  const fieldsConfig = config.fields;
  const { setValue } = config.utils;

  const steps = config.steps;
  useEffect(() => {
    if (_.isEmpty(initialData)) return;

    Object.keys(initialData).forEach((key) => {
      if (key === "destinationURL" && initialData?.[key]) {
        setValue("destinationURL", initialData?.[key]);
      }
      if (
        key === "objective" &&
        initialData[key] &&
        optionsCampaignsTypeGoogleAds.find(
          (option) => option.value === initialData?.[key]
        )
      ) {
        setValue("objective", initialData?.[key]);
      }
    });
    // eslint-disable-next-line
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 400,
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {steps.map((step) => {
          const fieldConfig = fieldsConfig[step];
          if (!fieldConfig.isVisible || currentAnswer !== fieldConfig.name)
            return null;

          return (
            <CommonQuestionContainer
              key={fieldConfig.name}
              formConfig={config}
              fieldConfig={fieldConfig}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const ObjectiveQuestion = ({ fieldConfig, control }) => {
  const { t } = useTranslationApp();
  return (
    <Select
      options={optionsCampaignsTypeGoogleAds}
      size="small"
      name={fieldConfig.name}
      variant="filled"
      displayEmpty={true}
      showEmptyOption={true}
      fullWidth
      placeholder={t(SELECT_A_OPTION)}
      doTranslate={true}
      control={control}
    />
  );
};

const IdentitiesQuestion = ({ fieldConfig, control }) => {
  const { t } = useTranslationApp();
  const { setValue } = fieldConfig.utils;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
      }}
    >
      <SelectBusinessAccount
        variant="filled"
        label={t(AD_ACCOUNT_GOOGLE_PLACEHOLDER)}
        control={control}
        platform={GOOGLE}
        name={`adAccountID`}
        onChangeAction={(value) => {
          setValue(`integrationID`, value?.integrationID);
        }}
        fullWidth={true}
      />
    </Box>
  );
};

const DestinationURLQuestion = ({ fieldConfig, control }) => {
  const { t } = useTranslationApp();

  return (
    <TextField
      control={control}
      name={fieldConfig.name}
      fullWidth
      placeholder={t(CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER)}
      variant="filled"
    />
  );
};

const BudgetQuestion = ({ fieldConfig, control }) => {
  const { t } = useTranslationApp();
  return (
    <TextField
      control={control}
      name={fieldConfig.name}
      placeholder={t(CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER)}
      fullWidth
      variant="filled"
      InputProps={{
        inputComponent: NumberField,
      }}
      helperText={t(VALUE_EXPRESSED_IN, {
        currency: "USD",
      })}
    />
  );
};

const MediaUrlsQuestion = ({ fieldConfig }) => {
  const { setValue, watch } = fieldConfig.utils;
  const mediaUrls = watch("mediaUrls");
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        overflowX: "auto",
        width: "100%",
      }}
    >
      {mediaUrls.length > 0 &&
        mediaUrls.map((mediaUrl, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              gap: 1,
              mb: 1,
              minHeight: 160,
              minWidth: 110,
              height: 160,
              width: 110,
              position: "relative",
            }}
          >
            {mediaUrl?.mediaData?.from === ADS_CAMPAIGN_MEDIA_FROM_CREATIFY ? (
              <iframe
                src={mediaUrl?.mediaData?.preview}
                style={{
                  display: "block",
                  border: "none",
                  overflow: "hidden",
                  height: "100%",
                  width: "100%",
                }}
              />
            ) : (
              <CardMedia
                url={mediaUrl.url}
                propsType={{
                  controls: false,
                  autoplay: true,
                }}
              />
            )}
            <IconButton
              onClick={() => {
                setValue(
                  "mediaUrls",
                  mediaUrls.filter((_, i) => i !== index)
                );
              }}
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                p: 0,
              }}
            >
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
        ))}
    </Box>
  );
};

const COMPONENTS = {
  objective: <ObjectiveQuestion />,
  identities: <IdentitiesQuestion />,
  destinationURL: <DestinationURLQuestion />,
  budget: <BudgetQuestion />,
  mediaUrls: <MediaUrlsQuestion />,
};

const CommonQuestionContainer = ({ formConfig, fieldConfig }) => {
  const { t } = useTranslationApp();

  const progress = formConfig.progress;
  const { control, setValue } = fieldConfig.utils;
  const error = formConfig.error;

  const COMPONENT = COMPONENTS[fieldConfig.name];

  if (!COMPONENT) return null;
  return (
    <>
      <LinearProgress
        sx={{ width: "100%", mb: 3, maxWidth: 600 }}
        variant="determinate"
        value={progress}
      />
      <AnswerContainer sx={{ display: "flex", height: "100%" }}>
        <AnswerContainerTitleAndField>
          {fieldConfig.title && (
            <AnswerTitle sx={{ mb: 1 }}>
              {`${t(fieldConfig.title, fieldConfig.titleExtrapolation)}`}
            </AnswerTitle>
          )}
          {fieldConfig.subtitle && (
            <AnswerSubTitle>
              {t(fieldConfig.subtitle, fieldConfig.subtitleExtrapolation)}
            </AnswerSubTitle>
          )}
          {error && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {error}
            </Alert>
          )}
          {React.cloneElement(COMPONENT, {
            fieldConfig,
            control,
            setValue,
            error,
          })}
        </AnswerContainerTitleAndField>
        {fieldConfig.showActionsButtons && (
          <ActionsButtons
            onPreviousStep={fieldConfig.onPreviousStep}
            showPreviousButton={fieldConfig.showPreviousButton}
            onNextStep={fieldConfig.onNextStep}
            showNextButton={fieldConfig.showNextButton}
            nextButtonText={fieldConfig.nextButtonText}
            saveButtonText={fieldConfig.saveButtonText}
            showSaveButton={fieldConfig.isFinishQuestion}
            onFinish={fieldConfig.onFinish}
            showLoadingComponent={fieldConfig.showLoadingComponent}
            customComponentPreviousNext={
              fieldConfig.customComponentPreviousNext
            }
            loading={formConfig.isCreating}
          />
        )}
      </AnswerContainer>
    </>
  );
};

export default GenerateCampaignFormGoogle;
