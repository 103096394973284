import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslationApp } from "../../../lib/i18next";
import {
  CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION,
  CREATE_ADS_CAMPAIGN_BUDGET_DESCRIPTION_QUESTION,
  CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION,
  CREATE_ADS_CAMPAIGN_IDENTITIES_ACCOUNTS_QUESTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION,
  CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION,
  FIELD_REQUIRED,
  FIELDS_REQUIRED,
} from "../../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_SEARCH_OPTION,
  GOOGLE,
} from "../../../utils/constants";

export function useGenerateCampaignFormGoogle({ onCallbackSave = () => {} }) {
  const STEPS = ["objective", "identities", "destinationURL", "budget"];
  const [error, setError] = useState(null);
  const { control, watch, setValue, getValues } = useForm({
    defaultValues: {
      step: 0,
      objective: ADS_CAMPAIGN_TYPE_SEARCH_OPTION,
      integrationID: "",
      adAccountID: "",
      destinationURL: "",
      typeBudget: ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
      budget: "",
      startDate: null,
      endDate: null,
      mediaUrls: [],
    },
  });

  const step = watch("step");
  const objective = watch("objective");
  const typeBudget = watch("typeBudget");

  const utils = {
    control,
    setValue,
    watch,
    getValues,
  };

  const { t } = useTranslationApp();

  const actionsToValidAnswer = () => {
    setError(null);
  };

  const onFinished = async () => {
    const formValues = getValues();

    const requestObject = {
      objective: formValues.objective,
      integrationID: formValues.integrationID,
      adAccountID: formValues.adAccountID,
      destinationURL: formValues.destinationURL,
      typeBudget: formValues.typeBudget,
      budget: formValues.budget,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      mediaUrls: formValues.mediaUrls,
      platform: GOOGLE,
    };

    onCallbackSave(requestObject);
  };

  const { steps, fields } = useMemo(() => {
    const fieldsConfig = {
      objective: {
        isVisible: () => true,
        isFinishQuestion: false,
        utils,
        name: "objective",
        title: CREATE_ADS_CAMPAIGN_OBJECTIVE_QUESTION,
        subtitle: CREATE_ADS_CAMPAIGN_OBJECTIVE_DESCRIPTION,
        showActionsButtons: true,
        showPreviousButton: false,
        onPreviousStep: () => {
          actionsToValidAnswer();
        },
        showNextButton: true,
        onNextStep: () => {
          const objective = getValues("objective");
          if (!objective || objective?.length === 0) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      identities: {
        isVisible: () => true,
        isFinishQuestion: false,
        utils,
        name: "identities",
        title: CREATE_ADS_CAMPAIGN_IDENTITIES_ACCOUNTS_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const integrationID = getValues("integrationID");
          const adAccountID = getValues("adAccountID");
          if (!adAccountID || !integrationID) {
            setError(t(FIELDS_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      destinationURL: {
        isVisible: () => true,
        isFinishQuestion: false,
        utils,
        name: "destinationURL",
        title: CREATE_ADS_CAMPAIGN_DESTINATION_URL_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: true,
        onNextStep: () => {
          const destinationURL = getValues("destinationURL");
          if (!destinationURL) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          setValue("step", step + 1);
        },
      },
      budget: {
        isVisible: () => true,
        isFinishQuestion: true,
        utils,
        name: "budget",
        title: CREATE_ADS_CAMPAIGN_BUDGET_DAILY_QUESTION,
        subtitle: CREATE_ADS_CAMPAIGN_BUDGET_DESCRIPTION_QUESTION,
        showActionsButtons: true,
        showPreviousButton: true,
        onPreviousStep: () => {
          actionsToValidAnswer();
          setValue("step", step - 1);
        },
        showNextButton: false,
        onFinish: () => {
          const budget = getValues("budget");
          if (!budget) {
            setError(t(FIELD_REQUIRED));
            return false;
          }
          actionsToValidAnswer();
          onFinished();
        },
      },
    };

    const stepsVisible = STEPS.filter((step) => fieldsConfig[step].isVisible());

    return {
      steps: stepsVisible,
      fields: fieldsConfig,
    };

    // eslint-disable-next-line
  }, [step, objective, typeBudget]);

  const currentAnswer = steps[step];
  const totalSteps = steps.length;
  const progress = Math.round(((step + 1) / totalSteps) * 100);

  return { steps, fields, utils, currentAnswer, error, progress };
}
