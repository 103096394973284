import { GOOGLE, META, TIKTOK } from "./constants";
import {
  optionsAdsCampaignObjectives,
  optionsCampaignsTypeGoogleAds,
} from "./options";

export const ADS_CAMPAIGNS_OBJECTIVES_SUPPORTED_BY_PLATFORM = {
  [META]: optionsAdsCampaignObjectives,
  [TIKTOK]: optionsAdsCampaignObjectives,
  [GOOGLE]: optionsCampaignsTypeGoogleAds,
};
