import i18next from "i18next";
import { SimpleAlert } from "../components/Alerts/Alerts";
import axios from "../lib/axios";
import {
  getFormattedCampaign,
  getLanguageFormatted,
  getObjectError,
} from "../utils/string";
import { getPathDatabaseByRole, renewToken } from "./auth";
import {
  COPY,
  ERROR,
  ERROR_PROCESSING_CAMPAIGNS,
  ERROR_DESCRIPTION_GENERIC,
  ERROR_GETTING_URL_META_DATA_AND_IMAGES,
} from "../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_MEDIA_FROM_PHYLLO,
  ADS_CAMPAIGN_STATUS_DRAFT,
  ADS_CAMPAIGNS_DEMO,
  ALERT_ICON_TYPE_ERROR,
  META,
  TIKTOK,
} from "../utils/constants";
import types from "../types";
import { db } from "../lib/firebase";
import {
  get,
  limitToFirst,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  remove,
  set,
  startAfter,
  update,
} from "firebase/database";
import { ERRORS_BILLING, LaunchError } from "../utils/errors";
import _ from "lodash";
import { waitDelay } from "../utils/date";
import { replaceUndefinedWithNull } from "../utils/forms";

const url = import.meta.env.VITE_CLOUD_FUNCTIONS_URL;

export const startHasOneCampaign = () => async (dispatch) => {
  try {
    const { pathCampaigns } = dispatch(getPathDatabaseByRole());

    const dbRef = ref(db, pathCampaigns);
    const q = query(dbRef, limitToFirst(1));
    const snapshot = await get(q);

    return snapshot.exists();
  } catch (error) {
    dispatch(LaunchError(error, false));
    return false;
  }
};
export const startGetDetailedReport =
  ({ campaignID }) =>
  async (dispatch) => {
    try {
      const { pathDetailedReports } = dispatch(getPathDatabaseByRole());

      const dbRef = ref(db, `${pathDetailedReports}/${campaignID}`);
      const snapshot = await get(dbRef);

      if (!snapshot.exists()) {
        return {
          ok: false,
        };
      }

      return { ok: true, data: snapshot.val() };
    } catch (error) {
      dispatch(LaunchError(error, false));
      return {
        ok: false,
      };
    }
  };
export const startListenerAdsContentsUploaded =
  ({
    onReferenceAvailable = () => {},
    onUpdatedDataCallback = () => {},
    campaignID,
  }) =>
  async (dispatch) => {
    try {
      const { pathAdsContentsUploaded } = dispatch(getPathDatabaseByRole());
      const dbRef = ref(db, `${pathAdsContentsUploaded}/${campaignID}`);
      onReferenceAvailable(dbRef);
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        onUpdatedDataCallback(data);
      });
      return true;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startDeleteAdMediaID =
  ({ adID, index }) =>
  async (dispatch) => {
    try {
      const { pathAds } = dispatch(getPathDatabaseByRole());
      const dbRef = ref(db, `${pathAds}/${adID}/mediaIDs/${index}`);
      await set(dbRef, "");
      return true;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };
export const startSaveReportOptimizationsDone =
  ({ campaignID, optimizationsDone }) =>
  async (dispatch) => {
    try {
      if (ADS_CAMPAIGNS_DEMO.includes(campaignID)) {
        await waitDelay(5000);
        return true;
      }

      const { pathCampaigns } = dispatch(getPathDatabaseByRole());
      const dbRef = ref(
        db,
        `${pathCampaigns}/${campaignID}/report/optimizations/optimizationsDone`
      );
      await set(dbRef, optimizationsDone);
      return true;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startDeleteAdsContentUploaded =
  ({ campaignID, contentID }) =>
  async (dispatch) => {
    try {
      const { pathAdsContentsUploaded } = dispatch(getPathDatabaseByRole());
      const dbRef = ref(
        db,
        `${pathAdsContentsUploaded}/${campaignID}/${contentID}`
      );
      await remove(dbRef);
      return true;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };
export const startUpdateMediaUrlAd =
  ({ adID, mediaUrl, index }) =>
  (dispatch) => {
    try {
      const { pathAds } = dispatch(getPathDatabaseByRole());
      const dbRef = ref(db, `${pathAds}/${adID}/mediaUrls/${index}`);
      set(dbRef, mediaUrl);
      return true;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };
export const startUpdateMediaUrlPlatformData =
  ({ campaignID, platform, mediaUrl, index }) =>
  (dispatch) => {
    try {
      const { pathCampaigns } = dispatch(getPathDatabaseByRole());
      const dbRef = ref(
        db,
        `${pathCampaigns}/${campaignID}/platformsData/${platform}/mediaUrls/${index}`
      );
      set(dbRef, mediaUrl);
      return true;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };
export const startGetAdsCampaigns =
  ({
    lastKey,
    onChangeLastKey = () => {},
    onLoadLastKey = () => {},
    limit = 12,
  }) =>
  async (dispatch) => {
    try {
      const { pathCampaigns, pathAdSets, pathAds } = dispatch(
        getPathDatabaseByRole()
      );

      const dbRef = ref(db, pathCampaigns);
      let q;

      if (lastKey) {
        q = query(
          dbRef,
          orderByChild("creationTimeNegative"),
          startAfter(lastKey),
          limitToFirst(limit)
        );
      } else {
        q = query(
          dbRef,
          orderByChild("creationTimeNegative"),
          limitToFirst(limit)
        );
      }
      const snapshot = await get(q);

      if (!snapshot.exists()) {
        lastKey
          ? dispatch(getNextAdsCampaigns({}))
          : dispatch(getAdsCampaigns({}));

        onLoadLastKey();

        return false;
      }

      const data = snapshot.val();
      const campaignIDs = Object.keys(data);
      onChangeLastKey(data[campaignIDs[0]].creationTimeNegative);

      const adGroupsQueries = [];
      const adsQueries = [];

      Object.keys(data).forEach((key) => {
        const adsGroups = data[key]?.adsGroups;
        if (!adsGroups) return;

        Object.keys(adsGroups).forEach((key) => {
          adGroupsQueries.push(get(ref(db, `${pathAdSets}/${key}`)));
        });

        data[key].adsGroups = {};

        return;
      });

      const adGroupsSnapshots = await Promise.allSettled(adGroupsQueries);

      adGroupsSnapshots.forEach((snapshot) => {
        if (snapshot.status !== "fulfilled") return;

        const dataAdGroup = snapshot.value.val();
        if (!dataAdGroup) return;

        const campaignID = dataAdGroup?.campaignID;
        const adGroupID = dataAdGroup?.id;
        if (!data[campaignID]) return;
        if (!data?.[campaignID]?.adsGroups) data[campaignID].adsGroups = {};

        data[campaignID].adsGroups[adGroupID] = dataAdGroup;

        const ads = dataAdGroup?.ads;
        if (!ads) return;

        Object.keys(ads).forEach((key) => {
          adsQueries.push(get(ref(db, `${pathAds}/${key}`)));
        });

        data[campaignID].adsGroups[adGroupID].ads = {};
      });

      const adsSnapshots = await Promise.allSettled(adsQueries);

      adsSnapshots.forEach((snapshot) => {
        if (snapshot.status !== "fulfilled") return;

        const dataAd = snapshot.value.val();
        if (!dataAd) return;

        const adID = dataAd.id;
        const adGroupID = dataAd.adGroupID;
        const campaignID = dataAd.campaignID;

        if (!data[campaignID]?.adsGroups?.[adGroupID]?.ads) {
          data[campaignID].adsGroups[adGroupID].ads = {};
        }

        data[campaignID].adsGroups[adGroupID].ads[adID] = dataAd;

        return;
      });

      lastKey
        ? dispatch(getNextAdsCampaigns(data))
        : dispatch(getAdsCampaigns(data));

      return data;
    } catch (error) {
      console.log(error);
      dispatch(LaunchError(error));
      return false;
    }
  };

// export const

export const startUpdateAdsCampaignsInformationFromPlatforms =
  ({ campaignIDs }) =>
  async (dispatch) => {
    try {
      const { userID: userIDCalling } = dispatch(getPathDatabaseByRole());
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/updateAdsCampaignsInformationFromPlatforms`,
        data: { campaignIDs },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { userID: userIDEnded } = dispatch(getPathDatabaseByRole());

      if (userIDCalling !== userIDEnded) return false;

      if (data.ok) {
        const campaignsFormatted = {};
        campaignIDs.forEach((campaignID) => {
          dispatch(
            saveAdsCampaign({
              key: campaignID,
              data: data.data[campaignID],
            })
          );

          campaignsFormatted[campaignID] = getFormattedCampaign({
            data: data.data[campaignID],
            format: "load",
          });
        });

        return campaignsFormatted;
      }

      return false;
    } catch (error) {
      dispatch(LaunchError(error, false));
      return false;
    }
  };
export const startGetCampaignReportState =
  ({ campaignID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const language = getLanguageFormatted(i18next.language);

      const { data } = await axios({
        method: "post",
        url: `${url}/getCampaignReportState`,
        data: { campaignID, language },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        dispatch(saveReportAdsCampaign({ key: campaignID, report: data.data }));
        return data.data;
      }

      return false;
    } catch (error) {
      dispatch(LaunchError(error, false));
      return false;
    }
  };
const saveReportAdsCampaign = (data) => ({
  type: types.SAVE_REPORT_ADS_CAMPAIGN_FINISH,
  payload: data,
});
export const startGetDetailedCampaignReport =
  ({ campaignID, breakdown, startDateTime, endDateTime }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const language = getLanguageFormatted(i18next.language);

      const { data } = await axios({
        method: "post",
        url: `${url}/getDetailedCampaignReport`,
        data: { campaignID, language, breakdown, startDateTime, endDateTime },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return {
        ok: data.ok,
        data: data.data,
      };
    } catch (error) {
      dispatch(LaunchError(error, false));
      return false;
    }
  };

export const startChangeStatusAdsCampaign =
  ({ states, campaignID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/updateAdsCampaignPlatforms`,
        data: {
          states,
          campaignID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        dispatch(
          saveAdsCampaign({
            key: campaignID,
            data: {
              states,
            },
          })
        );
      }

      return data.ok;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      if (ERRORS_BILLING.includes(errorFormatted.code)) {
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: errorFormatted.message,
            code: errorFormatted.code,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
        });
      } else {
        dispatch(LaunchError(error));
      }
      return false;
    }
  };

const getAdsCampaigns = (data) => ({
  type: types.GET_ADS_CAMPAIGNS_FINISH,
  payload: data,
});
const getNextAdsCampaigns = (data) => ({
  type: types.GET_NEXT_ADS_CAMPAIGNS__FINISH,
  payload: data,
});

const saveAdsCampaign = (data) => ({
  type: types.SAVE_ADS_CAMPAIGN_FINISH,
  payload: data,
});

export const startGetCurrencyPlatform =
  ({ platform }) =>
  async (dispatch, getState) => {
    try {
      let currency;

      const integrations = getState()?.user?.integrations;
      if (platform === META) {
        currency = integrations?.facebook?.currency;
      }
      if (platform === TIKTOK) {
        currency = integrations?.tiktokBusiness?.currency;
      }

      if (currency) {
        return currency;
      }

      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/getCurrencyPlatform`,
        data: { platform },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        return data?.data?.currency;
      }

      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const startGetRecommendedSettingsAdsCampaign =
  (campaign) => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());

      const language = getLanguageFormatted(i18next.language);
      const geoIP = getState().user.geoIP;

      const { data } = await axios({
        method: "post",
        url: `${url}/generateRecommendedSettingsAdsCampaign`,
        data: {
          ...campaign,
          languageReasoning: language,
          userLocation: geoIP,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        const campaign = data?.data?.campaign;
        return {
          ok: true,
          data: getFormattedCampaign({
            data: campaign,
            format: "load",
          }),
        };
      }
      return {
        ok: false,
      };
    } catch (error) {
      const errorFormatted = dispatch(
        LaunchError(error, true, [ERROR_GETTING_URL_META_DATA_AND_IMAGES])
      );
      return errorFormatted;
    }
  };
export const startGetRecommendedSettingsPlatform =
  (campaign) => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());

      const language = getLanguageFormatted(i18next.language);
      const geoIP = getState().user.geoIP;

      const { data } = await axios({
        method: "post",
        url: `${url}/generateRecommendedSettingsPlatform`,
        data: {
          ...campaign,
          languageReasoning: language,
          userLocation: geoIP,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const campaignGenerated = data?.data?.campaign;
      return {
        ok: true,
        data: getFormattedCampaign({
          data: campaignGenerated,
          format: "load",
        }),
      };
    } catch (error) {
      const errorFormatted = dispatch(
        LaunchError(error, true, [ERROR_GETTING_URL_META_DATA_AND_IMAGES])
      );
      return errorFormatted;
    }
  };

export const startCreateAdsCampaign = (campaign) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());

    const { data } = await axios({
      method: "post",
      url: `${url}/createAdsCampaignPlatforms`,
      data: getFormattedCampaign({ data: campaign, format: "save" }),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (data.ok) {
      const campaign = data?.data?.campaign;

      dispatch(
        saveAdsCampaign({
          key: campaign?.id,
          data: campaign,
        })
      );

      return {
        ok: true,
        campaign: getFormattedCampaign({
          data: campaign,
          format: "load",
        }),
      };
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    if (errorFormatted.code === ERROR_PROCESSING_CAMPAIGNS) {
      const data = errorFormatted.data;
      dispatch(
        saveAdsCampaign({
          key: data?.id,
          data: data?.campaign,
        })
      );
      const returnError = {
        ...errorFormatted,
        ...data,
        campaign: getFormattedCampaign({
          data: data?.campaign,
          format: "load",
        }),
      };

      delete returnError.data;
      return returnError;
    }

    dispatch(LaunchError(error));

    return { ok: false };
  }
};

export const startDeleteAdsCampaign = (campaign) => async (dispatch) => {
  try {
    const { userID } = dispatch(getPathDatabaseByRole());
    const campaignID = campaign?.id;

    const updates = {};
    updates[`adsCampaigns/${userID}/${campaignID}`] = null;
    campaign?.adsGroups?.forEach((adGroup) => {
      updates[`adsGroups/${userID}/${adGroup?.id}`] = null;
      adGroup?.ads?.forEach((ad) => {
        updates[`ads/${userID}/${ad?.id}`] = null;
      });
    });

    await update(ref(db), updates);
    dispatch(deleteCampaign({ campaignID }));
  } catch (error) {
    console.log(error);
    dispatch(LaunchError(error));

    return { ok: false };
  }
};

const deleteCampaign = (payload) => ({
  type: types.DELETE_ADS_CAMPAIGN_FINISH,
  payload,
});

export const generateAdsCampaignDescription =
  (campaign) => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data: result } = await axios({
        method: "post",
        url: `${url}/generateAdsCampaignDescription`,
        data: campaign,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.ok) {
        return result?.data?.description;
      }
      SimpleAlert({
        title: i18next.t(ERROR),
        text: result.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startGetAdsCampaignTrackingIDs =
  ({ platform, integrationID, adAccountID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${url}/getAdsCampaignTrackingIDs`,
        data: { platform, integrationID, adAccountID },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        return data.data;
      }

      return false;
    } catch (error) {
      dispatch(LaunchError(error, false));
      return false;
    }
  };

export const startGetAdsCampaign = (campaignID) => async (dispatch) => {
  try {
    const paths = dispatch(getPathDatabaseByRole());
    const { pathCampaigns, pathAdSets, pathAds } = paths;

    const dbRef = ref(db, `${pathCampaigns}/${campaignID}`);

    const snapshot = await get(dbRef);

    if (!snapshot.exists()) {
      return false;
    }

    const data = snapshot.val();

    const adGroupsQueries = [];
    const adsQueries = [];

    const adsGroups = data?.adsGroups;
    if (!adsGroups) {
      const dataFormatted = getFormattedCampaign({ data, format: "load" });
      dispatch(
        getAdsCampaigns({
          [campaignID]: dataFormatted,
        })
      );
      return dataFormatted;
    }

    Object.keys(adsGroups).forEach((key) => {
      adGroupsQueries.push(get(ref(db, `${pathAdSets}/${key}`)));
    });

    data.adsGroups = {};

    const adGroupsSnapshots = await Promise.allSettled(adGroupsQueries);

    adGroupsSnapshots.forEach((snapshot) => {
      if (snapshot.status !== "fulfilled") return;

      const dataAdGroup = snapshot.value.val();
      if (!dataAdGroup) return;

      const adGroupID = dataAdGroup?.id;

      data.adsGroups[adGroupID] = dataAdGroup;

      const ads = dataAdGroup?.ads;
      if (!ads) return;

      Object.keys(ads).forEach((key) => {
        adsQueries.push(get(ref(db, `${pathAds}/${key}`)));
      });

      data.adsGroups[adGroupID].ads = {};
    });

    if (adsQueries.length === 0) {
      const dataFormatted = getFormattedCampaign({ data, format: "load" });
      dispatch(
        getAdsCampaigns({
          [campaignID]: dataFormatted,
        })
      );
      return dataFormatted;
    }

    const adsSnapshots = await Promise.allSettled(adsQueries);

    adsSnapshots.forEach((snapshot) => {
      if (snapshot.status !== "fulfilled") return;

      const dataAd = snapshot.value.val();
      if (!dataAd) return;

      const adID = dataAd.id;
      const adGroupID = dataAd.adGroupID;

      data.adsGroups[adGroupID].ads[adID] = dataAd;
    });

    const dataFormatted = getFormattedCampaign({ data, format: "load" });

    dispatch(
      getAdsCampaigns({
        [campaignID]: dataFormatted,
      })
    );

    return dataFormatted;
  } catch (error) {
    dispatch(LaunchError(error));
    return false;
  }
};
export const startGetAdsCampaignStatisticsHistorical =
  (campaignID) => async (dispatch) => {
    try {
      const paths = dispatch(getPathDatabaseByRole());
      const { pathCampaignsHistoricalStatistics } = paths;

      const dbRef = ref(
        db,
        `${pathCampaignsHistoricalStatistics}/${campaignID}`
      );

      const snapshot = await get(dbRef);

      if (!snapshot.exists()) {
        return false;
      }

      const data = snapshot.val();
      return data;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startSaveAdsCampaignDraft =
  ({ campaign, campaignID }) =>
  async (dispatch) => {
    try {
      const { userID } = dispatch(getPathDatabaseByRole());

      const campaignFormatted = replaceUndefinedWithNull(
        getFormattedCampaign({
          data: campaign,
          format: "save",
        })
      );
      const updates = {};

      const adsGroups = {};

      Object.keys(campaignFormatted?.adsGroups).forEach((key) => {
        adsGroups[key] = true;
        const ads = {};

        Object.keys(campaignFormatted?.adsGroups[key]?.ads).forEach((keyAd) => {
          ads[keyAd] = true;

          updates[`ads/${userID}/${keyAd}`] = {
            ...campaignFormatted?.adsGroups[key]?.ads[keyAd],
            isCarousel:
              campaignFormatted?.adsGroups?.[key]?.ads?.[keyAd]?.isCarousel ??
              false,
          };
        });

        updates[`adsGroups/${userID}/${key}`] = {
          ...campaignFormatted?.adsGroups[key],
          ads,
        };
      });
      updates[`adsCampaigns/${userID}/${campaignID}`] = {
        ...campaignFormatted,
        adsGroups,
      };

      const dbRef = ref(db);
      await update(dbRef, updates);

      dispatch(
        saveAdsCampaign({
          key: campaignID,
          data: campaignFormatted,
        })
      );

      return true;
    } catch (error) {
      console.log(error);
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startDuplicateAdsCampaign =
  ({ campaign }) =>
  async (dispatch) => {
    try {
      const { userID } = dispatch(getPathDatabaseByRole());
      const updates = {};

      const newCampaign = _.cloneDeep(campaign);
      const newCampaignID = push(ref(db, `adsCampaigns/${userID}`)).key;
      const statesKeys = Object.keys(newCampaign.states);
      const newStates = {};
      statesKeys.forEach((key) => {
        newStates[key] = ADS_CAMPAIGN_STATUS_DRAFT;
      });

      newCampaign.id = newCampaignID;
      newCampaign.name = `${newCampaign.name} - ${i18next.t(COPY)}`;
      newCampaign.states = newStates;
      newCampaign.status = ADS_CAMPAIGN_STATUS_DRAFT;
      newCampaign.creationTime = new Date().getTime();
      newCampaign.creationTimeNegative = -newCampaign.creationTime;
      delete newCampaign.firstActiveTime;
      delete newCampaign.lastActiveTime;
      delete newCampaign.isFirstActive;
      delete newCampaign.platformIDs;
      delete newCampaign.report;
      delete newCampaign.statistics;
      delete newCampaign.statistics;

      const adsGroups = newCampaign.adsGroups;
      const newAdsGroupsIDs = {};
      adsGroups.forEach((adGroup) => {
        const newAdGroupID = push(ref(db, `adsGroups/${userID}`)).key;
        newAdsGroupsIDs[newAdGroupID] = true;

        adGroup.id = newAdGroupID;
        adGroup.campaignID = newCampaignID;
        adGroup.creationTime = new Date().getTime();
        adGroup.creationTimeNegative = -adGroup.creationTime;
        adGroup.name = `${adGroup.name} - ${i18next.t(COPY)}`;
        delete adGroup.idPlatform;

        const ads = adGroup.ads;
        const newAdsIDs = {};

        ads.forEach((ad) => {
          const newAdID = push(ref(db, `ads/${userID}`)).key;
          newAdsIDs[newAdID] = true;

          ad.id = newAdID;
          ad.adGroupID = newAdGroupID;
          ad.campaignID = newCampaignID;
          ad.creationTime = new Date().getTime();
          ad.creationTimeNegative = -ad.creationTime;
          ad.name = `${ad.name} - ${i18next.t(COPY)}`;
          delete ad.idPlatform;
          delete ad.creativeID;
          delete ad.mediaIDs;

          if (ad.titles) {
            ad.titles = ad.titles.map((title) => {
              return title.value;
            });
          }
          if (ad.descriptions) {
            ad.descriptions = ad.descriptions.map((description) => {
              return description.value;
            });
          }
          if (ad.headlines) {
            ad.headlines = ad.headlines.map((headline) => {
              return headline.value;
            });
          }
          if (ad.destinationUrls) {
            ad.destinationUrls = ad.destinationUrls.map((destinationUrl) => {
              return destinationUrl.value;
            });
          }

          updates[`ads/${userID}/${newAdID}`] = ad;
        });

        adGroup.ads = newAdsIDs;
        updates[`adsGroups/${userID}/${newAdGroupID}`] = adGroup;
      });

      newCampaign.adsGroups = newAdsGroupsIDs;
      updates[`adsCampaigns/${userID}/${newCampaignID}`] = newCampaign;

      await update(ref(db), updates);

      return {
        ok: true,
        data: {
          campaignID: newCampaignID,
        },
      };
    } catch (error) {
      console.log(error);
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };

export const startSaveAdsCampaign =
  ({ campaign, campaignID, from = "update" }) =>
  async (dispatch) => {
    try {
      if (ADS_CAMPAIGNS_DEMO.includes(campaignID) && from === "optimization") {
        await waitDelay(5000);

        return {
          ok: true,
          campaign,
        };
      }

      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/updateAdsCampaignPlatforms`,
        data: getFormattedCampaign({
          data: {
            ...campaign,
            campaignID,
          },
          format: "save",
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        const campaign = data?.data?.campaign;

        dispatch(
          saveAdsCampaign({
            key: campaignID,
            data: campaign,
          })
        );
        return {
          ok: true,
          campaign: getFormattedCampaign({ data: campaign, format: "load" }),
        };
      }

      return { ok: false };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      if (errorFormatted.code === ERROR_PROCESSING_CAMPAIGNS) {
        const data = errorFormatted.data;
        dispatch(
          saveAdsCampaign({
            key: data?.id,
            data: data?.campaign,
          })
        );
        const returnError = {
          ...errorFormatted,
          ...data,
          campaign: getFormattedCampaign({
            data: data?.campaign,
            format: "load",
          }),
        };

        delete returnError.data;
        return returnError;
      }

      dispatch(LaunchError(error));
      return { ok: false };
    }
  };
export const startVerifyAndSetAdsUsage =
  ({ actionID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/verifyAndSetAdsUsage`,
        data: {
          actionID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data.ok;
    } catch (error) {
      console.log(error);
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startGetAdsCampaignSegmentation =
  ({ keyWord, type, platform, objective, integrationID, adAccountID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const language = getLanguageFormatted(i18next.language);

      const { data } = await axios({
        method: "post",
        url: `${url}/getAdsCampaignSegmentation`,
        data: {
          keyWords: [keyWord],
          type,
          platform,
          objective,
          language,
          integrationID,
          adAccountID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.ok) {
        return data.data;
      }

      return false;
    } catch (error) {
      dispatch(LaunchError(error, false));
      return false;
    }
  };

export const startGetInstantForm =
  ({
    lastKey,
    integrationID,
    pageID,
    onChangeLastKey = () => {},
    onChangeLoadLastItem = () => {},
  }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/getInstantFormsAdsCampaigns`,
        data: {
          lastKey,
          integrationID,
          pageID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!data.ok) {
        return {
          ok: false,
          message: data.message,
          code: data.code,
        };
      }

      const response = data.data;

      if (!response?.hasMore) {
        onChangeLoadLastItem(true);
      }

      onChangeLastKey(response?.lastKey);

      return {
        ok: true,
        data: response?.instantForms || [],
      };
    } catch (error) {
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };
export const startGetCustomAudiences =
  ({
    platform,
    lastKey,
    integrationID,
    adAccountID,
    onChangeLastKey = () => {},
    onChangeLoadLastItem = () => {},
  }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/getCustomAudiencesAdsCampaigns`,
        data: {
          platform,
          lastKey,
          integrationID,
          adAccountID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!data.ok) {
        return {
          ok: false,
          message: data.message,
          code: data.code,
        };
      }

      const response = data.data;

      if (!response?.hasMore) {
        onChangeLoadLastItem(true);
      }

      onChangeLastKey(response?.lastKey);

      return {
        ok: true,
        data: response?.customAudiences || [],
      };
    } catch (error) {
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };

export const startCreateCustomAudience =
  (customAudience) => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/createCustomAudienceAdsCampaign`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: customAudience,
      });

      if (!data.ok) {
        return {
          ok: false,
        };
      }

      const response = data.data;

      return { ok: true, data: response };
    } catch (error) {
      const errorFormatted = dispatch(LaunchError(error));
      return {
        ok: false,
        code: errorFormatted.code,
        message: errorFormatted.message,
      };
    }
  };

export const startGetGalleryAdsPlatforms =
  ({
    platform,
    integrationID,
    adAccountID,
    pageID,
    socialNetworkURL,
    lastKey,
    onChangeLastKey = () => {},
    onChangeLoadLastItem = () => {},
    onChangeIsCalling = () => {},
    from = ADS_CAMPAIGN_MEDIA_FROM_PHYLLO,
  }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      onChangeIsCalling(true);
      const { data } = await axios({
        method: "post",
        url: `${url}/getGalleryMediaFromPlatforms`,
        data: {
          platform,
          integrationID,
          adAccountID,
          pageID,
          socialNetworkURL,
          from,
          lastKey,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onChangeIsCalling(false);

      if (!data.ok) {
        return {
          ok: false,
          message: data.message,
          code: data.code,
        };
      }

      const response = data.data;

      if (!response?.hasMore) {
        onChangeLoadLastItem(true);
      }
      onChangeLastKey(response?.lastKey);

      return {
        ok: true,
        data: response?.media || [],
      };
    } catch (error) {
      onChangeLoadLastItem(true);
      onChangeIsCalling(false);
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };
export const startGetIdentitiesTikTokBusiness =
  ({ objective, adAccountID, integrationID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${url}/getIdentitiesTikTokBusiness`,
        data: {
          objective,
          integrationID,
          adAccountID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!data.ok) {
        return {
          ok: false,
          message: data.message,
          code: data.code,
        };
      }

      const identities = data.data;

      return {
        ok: true,
        data: identities,
      };
    } catch (error) {
      dispatch(LaunchError(error, false));
      return {
        ok: false,
      };
    }
  };
export const startCreateIdentityTikTokBusiness =
  ({ name, imageURL, integrationID, adAccountID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${url}/createIdentityTikTokBusiness`,
        data: {
          name,
          imageURL,
          integrationID,
          adAccountID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!data.ok) {
        return {
          ok: false,
          message: data.message,
          code: data.code,
        };
      }

      const identity = data.data;

      return {
        ok: true,
        data: identity,
      };
    } catch (error) {
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };

export const startGetTrackingCustomEvents =
  ({
    platform,
    lastKey,
    integrationID,
    adAccountID,
    onChangeLastKey = () => {},
    onChangeLoadLastItem = () => {},
  }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/getTrackingCustomEventsAdsCampaigns`,
        data: {
          platform,
          lastKey,
          integrationID,
          adAccountID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!data.ok) {
        return {
          ok: false,
          message: data.message,
          code: data.code,
        };
      }

      const response = data.data;

      if (!response?.hasMore) {
        onChangeLoadLastItem(true);
      }

      onChangeLastKey(response?.lastKey);

      return {
        ok: true,
        data: response?.trackingCustomEvents || [],
      };
    } catch (error) {
      dispatch(LaunchError(error));
      return {
        ok: false,
      };
    }
  };

export const startGetAdPreview = (adPreviewData) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());

    const { data } = await axios({
      method: "post",
      url: `${url}/getAdPreviewAdsCampaigns`,
      data: adPreviewData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!data.ok) {
      return {
        ok: false,
        message: data.message,
        code: data.code,
      };
    }

    const adsPreviewsData = data.data;

    return {
      ok: true,
      data: adsPreviewsData,
    };
  } catch (error) {
    const response = dispatch(LaunchError(error, false));
    return response;
  }
};

export const startVerifyUrlCanBeScrape = (urlToScrape) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());

    const { data } = await axios({
      method: "post",
      url: `${url}/verifyUrlCanBeScraped`,
      data: { url: urlToScrape },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.ok;
  } catch (error) {
    dispatch(LaunchError(error, false));
    return false;
  }
};
export const startExtractBrandInformationFromUrl =
  (urlToScrape) => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/extractBrandInformationFromUrl`,
        data: { url: urlToScrape },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    } catch (error) {
      dispatch(LaunchError(error));
      return false;
    }
  };

export const startSaveBrandInformationFromUrl =
  ({
    websiteUrl,
    socialNetworks,
    businessName,
    businessDescription,
    businessTextStyle,
    logoUrl,
  }) =>
  async (dispatch) => {
    try {
      const { pathBrandInformation } = dispatch(getPathDatabaseByRole());

      const updates = {};

      updates[`${pathBrandInformation}/websiteUrl`] = websiteUrl || "";
      updates[`${pathBrandInformation}/socialNetworks`] = socialNetworks || [];
      updates[`${pathBrandInformation}/businessName`] = businessName || "";
      updates[`${pathBrandInformation}/businessDescription`] =
        businessDescription || "";
      updates[`${pathBrandInformation}/businessTextStyle`] =
        businessTextStyle || "";
      updates[`${pathBrandInformation}/logoUrl`] = logoUrl || "";

      await update(ref(db), updates);

      return { ok: true };
    } catch (error) {
      console.log(error);
      dispatch(LaunchError(error, true));
      return { ok: false };
    }
  };
export const startGetBrandInformation = () => async (dispatch) => {
  try {
    const { pathBrandInformation } = dispatch(getPathDatabaseByRole());

    const refDB = ref(db, pathBrandInformation);

    const snapshot = await get(refDB);

    if (!snapshot.exists()) {
      return { ok: false };
    }
    return { ok: true, data: snapshot.val() };
  } catch (error) {
    console.log(error);
    dispatch(LaunchError(error, true));
    return { ok: false };
  }
};

export const startGetWhatsappBusinessPhoneNumbers =
  ({ integrationID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      const { data } = await axios({
        method: "post",
        url: `${url}/getWhatsappBusinessPhoneNumbersAdsCampaigns`,
        data: {
          integrationID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!data.ok) {
        return {
          ok: false,
          message: data.message,
          code: data.code,
        };
      }

      const response = data.data;

      return {
        ok: true,
        data: response || [],
      };
    } catch (error) {
      dispatch(LaunchError(error, false));
      return {
        ok: false,
      };
    }
  };
