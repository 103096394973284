import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  startRegisterAdvertiserUser,
  startAuthGoogle,
} from "../../../actions/auth";
import {
  ALREADY_HAVE_AN_ACCOUNT,
  BUTTON_CONTINUE,
  BUTTON_REGISTER,
  BUTTON_REGISTER_WITH_GOOGLE,
  BUTTON_RESEND_CODE,
  BUTTON_VERIFY_CODE,
  EMAIL_PLACEHOLDER,
  ERROR_OCCURRED,
  FIELD_EMAIL_REGISTERED,
  FIELD_EMAIL_VALID,
  FIELD_MIN_LENGTH,
  FIELD_PASSWORD_INSECURE,
  FIELD_REQUIRED,
  LOG_IN,
  MODIFY_PHONE_NUMBER,
  NAME_PLACEHOLDER,
  PASSWORD_PLACEHOLDER,
  PHONE_NUMBER,
  VERIFICATION_CODE_PLACEHOLDER,
  WE_SEND_VERIFICATION_CODE_TO_YOUR_PHONE,
} from "../../../i18n/keysTranslations";
import { useForm } from "react-hook-form";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import { REGEX_EMAIL, REGEX_PASSWORD } from "../../../utils/regex";
import TextField from "../../../components/Form/TextField";
import { useTranslationApp } from "../../../lib/i18next";
import PhoneField from "../../../components/Form/PhoneField";
import {
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../../../lib/firebase";
import OTPField from "../../../components/Form/OTPField";
import Button from "../../../components/Buttons/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LaunchError } from "../../../utils/errors";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonGoogle from "../../../components/Buttons/ButtonGoogle";
import _ from "lodash";
import { waitDelay } from "../../../utils/date";

const RegisterForm = () => {
  const {
    control,
    handleSubmit,
    formState,
    watch,
    setValue,
    getValues,
    setError: setErrorForm,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      signUpCountry: undefined,
      phone: "",
      code: "",
    },
    mode: "onSubmit",
  });
  const errors = formState.errors;
  useFocusErrorForm(formState);
  const [isSendCode, setIsSendCode] = useState(false);
  const [modeForm, setModeForm] = useState("email");
  const [currentProviderData, setCurrentProviderData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState(30);
  const [reCaptcha, setReCaptcha] = useState(null);
  const [isResendAllowed, setIsResendAllowed] = useState(false);
  const [isResendingCode, setIsResendingCode] = useState(false);

  const showNameField = modeForm === "email";
  const showPhoneField = modeForm === "email" || modeForm === "google";
  const showEmailField = modeForm === "email";
  const showPasswordField = modeForm === "email";

  const country = watch("signUpCountry");
  const phone = watch("phone");
  const dispatch = useDispatchApp();
  const navigate = useNavigate();

  const { t } = useTranslationApp();
  const userIP = useSelectorApp((state) => state?.user?.geoIP);

  const location = useLocation();

  useEffect(() => {
    if (!userIP) return;

    setValue("signUpCountry", userIP?.countryCode?.toLowerCase());
    // eslint-disable-next-line
  }, [userIP]);

  useEffect(() => {
    const state = location?.state || {};
    if (_.isEmpty(state)) return;

    const providerData = state;
    if (providerData.modeForm === "google") {
      const credential = GoogleAuthProvider.credential(
        providerData.credential.idToken,
        providerData.credential.accessToken
      );
      setCurrentProviderData({
        ...providerData,
        credential,
      });
    }
    setValue("email", providerData.email);
    setValue("name", providerData.name);
    setValue("userID", providerData.userID);
    setModeForm(providerData.modeForm);

    //clear location state

    navigate(location.pathname, { state: {} });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const countryCodePhone = phone?.info?.countryCode?.toLowerCase();
    if (!countryCodePhone) return;

    if (countryCodePhone !== country) {
      setValue("signUpCountry", countryCodePhone);
      setValue("phone", {
        ...phone,
        value: "",
      });
    }
    // eslint-disable-next-line
  }, [phone]);

  useEffect(() => {
    let timer;
    if (isSendCode && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsResendAllowed(true);
    }
    return () => clearTimeout(timer);
  }, [countdown, isSendCode]);

  const resendCode = async () => {
    if (!isResendAllowed) return;

    setIsResendingCode(true);
    await onSendCode();
    setIsResendingCode(false);
    setCountdown(30);
    setIsResendAllowed(false);
  };

  const onChangeShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onRegister = async (formValues) => {
    const { code, ...rest } = formValues;
    const confirmResult = window.confirmationResult;

    if (!confirmResult) {
      setError(t(ERROR_OCCURRED));
      console.error("No confirmation result");
      return;
    }

    try {
      const responseCode = await confirmResult.confirm(code);

      setIsFetching(true);
      const response = await dispatch(
        startRegisterAdvertiserUser({
          ...rest,
          userIDProvider: currentProviderData?.userID,
          userIDPhone: responseCode?.user?.uid,
        })
      );
      if (!response.ok) {
        setError(response.message);
        return;
      }

      if (modeForm === "google") {
        await signInWithCredential(auth, currentProviderData.credential);
        await waitDelay(250);
      }

      navigate(`/ads-campaign`);

      return;
    } catch (error) {
      console.log(error);
      const errorFormatted = dispatch(LaunchError(error, false));
      setError(errorFormatted.message);
    }
  };

  const onSendCode = async () => {
    try {
      const formValues = getValues();
      const { email } = formValues;

      if (showEmailField) {
        const result = await fetchSignInMethodsForEmail(auth, email);
        if (result.length > 0) {
          setErrorForm("email", {
            type: "manual",
            message: t(FIELD_EMAIL_REGISTERED),
          });
          return;
        }
      }

      const appVerifier = reCaptcha;
      const phoneNumber = formValues.phone.value.replace(/ /g, "");

      setError(null);
      const response = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );

      window.confirmationResult = response;
      setIsSendCode(true);
    } catch (error) {
      const errorFormatted = dispatch(LaunchError(error, false));
      setError(errorFormatted.message);
    }
  };
  const onRegisterGoogle = async () => {
    setError(null);
    setIsFetching(true);
    const response = await dispatch(startAuthGoogle());
    setIsFetching(false);
    if (!response.ok) {
      setError(response.message);
      return;
    }

    setCurrentProviderData(response.data);
    setValue("email", response.data.email);
    setValue("name", response.data.name);
    setValue("userID", response.data.userID);
    setModeForm("google");
  };

  const onSubmit = async (formValues) => {
    setError(null);
    const phone = formValues.phone;
    const data = {
      ...formValues,
      phone: {
        value: phone.value.replace(/ /g, ""),
        info: {
          countryCallingCode: phone.info.countryCallingCode,
          countryCode: phone.info.countryCode.toLowerCase(),
          nationalNumber: phone.info.nationalNumber,
        },
      },
    };

    setIsFetching(true);
    if (isSendCode) {
      await onRegister(data);
    } else {
      await onSendCode(data);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (reCaptcha) return;
    const reCaptchaVerifier = new RecaptchaVerifier(
      "sign-up-button",
      {
        size: "invisible",
        callback: () => {},
      },
      auth
    );

    setReCaptcha(reCaptchaVerifier);

    return () => {
      reCaptcha?.clear();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        mt: 3,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 1.5,
      }}
    >
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <div id="sign-up-button"></div>

      {isSendCode && (
        <>
          <Button
            startIcon={<ArrowBackIcon />}
            variant="text"
            color="white"
            onClick={() => setIsSendCode(false)}
            sx={{
              width: "fit-content",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            {t(MODIFY_PHONE_NUMBER)}
          </Button>
          <OTPField
            fullWidth
            label={t(VERIFICATION_CODE_PLACEHOLDER)}
            subLabel={t(WE_SEND_VERIFICATION_CODE_TO_YOUR_PHONE)}
            name="code"
            variant="filled"
            color="primary"
            InputLabelProps={{ shrink: true }}
            control={control}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
              validate: (value) => {
                if (value.length >= 1 && value.length <= 5) {
                  return t(FIELD_MIN_LENGTH, {
                    value: 6,
                  });
                }
              },
            }}
            errors={errors}
          />
        </>
      )}
      {!isSendCode && (
        <>
          {showNameField && (
            <TextField
              fullWidth
              label={t(NAME_PLACEHOLDER)}
              name="name"
              variant="filled"
              color="primary"
              InputLabelProps={{ shrink: true }}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
              errors={errors}
            />
          )}

          {showPhoneField && (
            <PhoneField
              id="phone"
              name="phone"
              label={t(PHONE_NUMBER)}
              control={control}
              errors={errors}
              defaultCountry={country}
              disableDropdown={false}
              variant="filled"
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          )}

          {showEmailField && (
            <TextField
              fullWidth
              id="email"
              label={t(EMAIL_PLACEHOLDER)}
              name="email"
              variant="filled"
              color="primary"
              InputLabelProps={{ shrink: true }}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                validate: {
                  emailValid: (value) => {
                    if (!REGEX_EMAIL.test(value)) {
                      return t(FIELD_EMAIL_VALID);
                    }
                  },
                },
              }}
              errors={errors}
            />
          )}

          {showPasswordField && (
            <TextField
              fullWidth
              id="password"
              name="password"
              label={t(PASSWORD_PLACEHOLDER)}
              type={showPassword ? "text" : "password"}
              variant="filled"
              color="primary"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={onChangeShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
                validate: (value) => {
                  if (!REGEX_PASSWORD.test(value)) {
                    return t(FIELD_PASSWORD_INSECURE);
                  }
                },
              }}
              errors={errors}
            />
          )}
        </>
      )}
      {!isSendCode && (
        <>
          <LoadingButton
            loading={isFetching}
            type="submit"
            color="secondary"
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
          >
            {t(!currentProviderData ? BUTTON_REGISTER : BUTTON_CONTINUE)}
          </LoadingButton>
          {!currentProviderData && (
            <ButtonGoogle
              loading={isFetching}
              label={t(BUTTON_REGISTER_WITH_GOOGLE)}
              onClick={onRegisterGoogle}
            />
          )}
          {!currentProviderData && (
            <Box>
              <Typography variant="body2" align="center" sx={{ mt: 1, mb: 2 }}>
                {t(ALREADY_HAVE_AN_ACCOUNT)}
              </Typography>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <LoadingButton
                  disabled={isFetching}
                  type="button"
                  color="primary"
                  fullWidth
                  variant="contained"
                >
                  {t(LOG_IN)}
                </LoadingButton>
              </Link>
            </Box>
          )}
        </>
      )}
      {isSendCode && (
        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <LoadingButton
            loading={isResendingCode}
            onClick={resendCode}
            disabled={!isResendAllowed || isFetching}
            color="primary"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            {`${t(BUTTON_RESEND_CODE)}${
              isResendAllowed ? "" : ` (${countdown})`
            }`}
          </LoadingButton>
          <LoadingButton
            loading={isFetching}
            type="submit"
            color="secondary"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            {t(BUTTON_VERIFY_CODE)}
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

export default RegisterForm;
