import { z } from "zod";
import {
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  META,
  PLATFORMS_SUPPORTED,
  TIKTOK,
} from "../../../../utils/constants";
import i18next from "i18next";
import {
  FIELD_REQUIRED,
  FIELD_URL_VALID,
} from "../../../../i18n/keysTranslations";
import { metaCampaignSchema } from "./platforms/metaCampaignSchema";
import { tiktokCampaignSchema } from "./platforms/tiktokCampaignSchema";
import { googleCampaignSchema } from "./platforms/googleCampaignSchema";
import { adGroupSchema } from "./adgroup/adGroupGeneralSchema";
import { REGEX_URL } from "../../../../utils/regex";

export const getCampaignSchema = () =>
  z
    .object({
      context: z.string().min(1, { message: i18next.t(FIELD_REQUIRED) }),
      language: z.string().min(1, { message: i18next.t(FIELD_REQUIRED) }),
      locations: z.string().min(1, { message: i18next.t(FIELD_REQUIRED) }),
      name: z.string().min(1, { message: i18next.t(FIELD_REQUIRED) }),
      platforms: z.array(z.enum(PLATFORMS_SUPPORTED)).default([]),

      platformsData: z
        .object({
          meta: metaCampaignSchema.optional(),
          google: googleCampaignSchema.optional(),
          tiktok: tiktokCampaignSchema.optional(),
        })
        .optional(),

      adsGroups: z.array(adGroupSchema).default([]),
    })
    .superRefine((data, ctx) => {
      const adsGroups = data.adsGroups || [];

      adsGroups.forEach((adGroup, index) => {
        const ads = adGroup.ads || [];

        ads.forEach((ad, adIndex) => {
          if (ad.platform === TIKTOK) {
            const objective = data.platformsData?.[TIKTOK]?.objective;
            if (
              objective !== ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION &&
              !ad.callToAction
            ) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["adsGroups", index, "ads", adIndex, "callToAction"],
                message: i18next.t(FIELD_REQUIRED),
              });
            }
          }

          if (ad.platform === META) {
            const destinationType =
              data.platformsData?.[META]?.destinationType ||
              ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION;

            const isDestinationTypeWebsite =
              destinationType ===
              ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION;

            const adIsCarousel = ad.isCarousel;

            if (isDestinationTypeWebsite) {
              if (adIsCarousel) {
                const expectedLength = ad.mediaUrls.length;
                const destinationUrls =
                  ad.destinationUrls?.slice(0, expectedLength) || [];

                destinationUrls.forEach((destinationUrl, index) => {
                  if (destinationUrl.value.trim() === "") {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: i18next.t(FIELD_REQUIRED),
                      path: [
                        "adsGroups",
                        index,
                        "ads",
                        adIndex,
                        "destinationUrls",
                        index,
                        "value",
                      ],
                    });
                  }

                  if (!REGEX_URL.test(encodeURI(destinationUrl.value))) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: i18next.t(FIELD_URL_VALID),
                      path: [
                        "adsGroups",
                        index,
                        "ads",
                        adIndex,
                        "destinationUrls",
                        index,
                        "value",
                      ],
                    });
                  }
                });
              } else {
                if (
                  !ad?.destinationUrls?.length ||
                  !ad?.destinationUrls?.[0]?.value?.trim()
                ) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18next.t(FIELD_REQUIRED),
                    path: [
                      "adsGroups",
                      index,
                      "ads",
                      adIndex,
                      "destinationUrls",
                      0,
                      "value",
                    ],
                  });
                }
                if (
                  !REGEX_URL.test(encodeURI(ad?.destinationUrls?.[0]?.value))
                ) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18next.t(FIELD_URL_VALID),
                    path: [
                      "adsGroups",
                      index,
                      "ads",
                      adIndex,
                      "destinationUrls",
                      0,
                      "value",
                    ],
                  });
                }
              }
            }
          }
        });
      });
    });
