import i18next from "i18next";
import { z } from "zod";
import {
  FIELD_MAX_LENGTH,
  FIELD_REQUIRED,
  FIELD_TEXT_REPEATED,
  TIKTOK,
} from "../../../../../i18n/keysTranslations";
import { ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH } from "../../../../../utils/constants";

function getIndicesMap(arrayOfStrings) {
  const map = {};
  arrayOfStrings.forEach((txt, i) => {
    if (!map[txt]) {
      map[txt] = [];
    }
    map[txt].push(i);
  });
  return map;
}

const textItemSchema = z.object({
  id: z.string().optional(),
  value: z.string().min(1, i18next.t(FIELD_REQUIRED)),
});

export const adTikTokBaseSchema = z
  .object({
    platform: z.literal(TIKTOK),
    titles: z.array(
      textItemSchema.extend({
        value: z
          .string()
          .min(1, i18next.t(FIELD_REQUIRED))
          .max(
            ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[TIKTOK],
            i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[TIKTOK],
            })
          ),
      })
    ),

    callToAction: z.string().optional(),
    mediaUrls: z.array(z.any()),
    mediaData: z.array(z.any()),
  })
  .superRefine((data, ctx) => {
    const titlesNormalized = data.titles.map((item) =>
      item.value.trim().toLowerCase()
    );
    const titlesMap = getIndicesMap(titlesNormalized);

    Object.entries(titlesMap).forEach(([, indexes]) => {
      if (indexes.length > 1) {
        indexes.forEach((i) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["titles", i, "value"],
            message: i18next.t(FIELD_TEXT_REPEATED),
          });
        });
      }
    });

    const titles = data?.titles || [];
    if (!titles?.length || !titles?.[0]?.value?.trim()) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18next.t(FIELD_REQUIRED),
        path: ["titles", 0, "value"],
      });
    }

    const mediaUrls = data?.mediaUrls || [];
    if (!mediaUrls?.length || !mediaUrls[0]?.trim()) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18next.t(FIELD_REQUIRED),
        path: ["mediaUrls", 0],
      });
    }
  });
