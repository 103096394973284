import Box from "@mui/material/Box";
import { useGenerateCampaignFormMeta } from "../../../../hooks/platforms/meta/useGenerateCampaignFormMeta";
import AnswerContainer from "../../../TypeForm/AnswerContainer";
import AnswerContainerTitleAndField from "../../../TypeForm/AnswerContainerTitleAndField";
import { Alert, LinearProgress, IconButton } from "@mui/material";
import AnswerTitle from "../../../TypeForm/AnswerTitle";
import AnswerSubTitle from "../../../TypeForm/AnswerSubtitle";
import React, { useEffect } from "react";
import ActionsButtons from "../../../TypeForm/ActionsButtons";
import { useTranslationApp } from "../../../../lib/i18next";
import Select from "../../../Form/Select";
import {
  optionsAdsCampaignObjectives,
  optionsAdsCampaignTypeBudgets,
} from "../../../../utils/options";
import {
  AD_ACCOUNT_META_PLACEHOLDER,
  ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION,
  CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER,
  CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER,
  END_DATE_PLACEHOLDER,
  INSTAGRAM_ACCOUNT_PLACEHOLDER,
  PAGE_FACEBOOK_PLACEHOLDER,
  SELECT_A_OPTION,
  START_DATE_PLACEHOLDER,
  TRACKING_META_ID_PLACEHOLDER,
  VALUE_EXPRESSED_IN,
} from "../../../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_MEDIA_FROM_CREATIFY,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  FORMATS_MEDIA_META,
  META,
} from "../../../../utils/constants";
import SelectDestinationTypeMeta from "../../../Meta/SelectDestinationTypeMeta";
import SelectInstagramAccount from "../../../Meta/SelectInstagramAccount";
import SelectPageFacebook from "../../../Meta/SelectPageFacebook";
import SelectBusinessAccount from "../../../Meta/SelectBusinessAccount";
import TooltipModal from "../../../Tooltip/TooltipModal";
import ContentCommon from "../../../Tooltip/ContentCommon";
import SelectTrackingIDField from "../../../Form/SelectTrackingIDField";
import TextField from "../../../Form/TextField";
import NumberField from "../../../Form/NumberField";
import DateField from "../../../Form/DateField";
import { addDays } from "date-fns";
import CardMedia from "../../../Media/CardMedia";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import { getExtensionFile } from "../../../../utils/string";

const GenerateCampaignFormMeta = ({
  initialData = {},
  campaignID,
  onCallbackSave = () => {},
}) => {
  const config = useGenerateCampaignFormMeta({
    campaignID,
    onCallbackSave,
  });
  const currentAnswer = config.currentAnswer;
  const fieldsConfig = config.fields;
  const { setValue } = config.utils;
  const steps = config.steps;

  useEffect(() => {
    if (_.isEmpty(initialData)) return;

    Object.keys(initialData).forEach((key) => {
      if (key === "destinationURL" && initialData?.[key]) {
        setValue("destinationURL", initialData?.[key]);
      }
      if (
        key === "objective" &&
        initialData[key] &&
        optionsAdsCampaignObjectives.find(
          (option) => option.value === initialData?.[key]
        )
      ) {
        setValue("objective", initialData?.[key]);
      }
      if (key === "mediaUrls" && initialData?.[key]?.length > 0) {
        const mediaUrls = initialData?.[key].filter((mediaUrlData) => {
          if (!mediaUrlData.url) return false;

          const url = mediaUrlData.url;
          const extension = getExtensionFile(url);
          if (FORMATS_MEDIA_META.includes(extension)) return true;
        });

        setValue("mediaUrls", mediaUrls);
      }
    });
    // eslint-disable-next-line
  }, [initialData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 400,
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {steps.map((step) => {
          const fieldConfig = fieldsConfig[step];
          if (!fieldConfig.isVisible || currentAnswer !== fieldConfig.name)
            return null;

          return (
            <CommonQuestionContainer
              key={fieldConfig.name}
              formConfig={config}
              fieldConfig={fieldConfig}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const ObjectiveQuestion = ({ fieldConfig, control }) => {
  const { t } = useTranslationApp();
  const { setValue } = fieldConfig.utils;
  return (
    <Select
      options={optionsAdsCampaignObjectives}
      size="small"
      name={fieldConfig.name}
      variant="filled"
      displayEmpty={true}
      showEmptyOption={true}
      fullWidth
      placeholder={t(SELECT_A_OPTION)}
      doTranslate={true}
      control={control}
      actionsChange={() => {
        setValue(
          `destinationType`,
          ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
        );
      }}
    />
  );
};

const DestinationTypeQuestion = ({ fieldConfig, control }) => {
  const { watch } = fieldConfig.utils;
  const objective = watch("objective");

  return (
    <SelectDestinationTypeMeta
      control={control}
      name={fieldConfig.name}
      fullWidth
      variant="filled"
      placeholder={SELECT_A_OPTION}
      objective={objective}
    />
  );
};

const IdentitiesQuestion = ({ fieldConfig, control }) => {
  const { t } = useTranslationApp();
  const { watch, setValue } = fieldConfig.utils;

  const integrationID = watch(`integrationID`);
  const adAccountID = watch(`adAccountID`);
  const pageID = watch(`pageID`);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
      }}
    >
      <SelectBusinessAccount
        variant="filled"
        label={t(AD_ACCOUNT_META_PLACEHOLDER)}
        control={control}
        platform={META}
        name={`adAccountID`}
        onChangeAction={(value) => {
          setValue(`integrationID`, value?.integrationID);
          setValue(`trackingID`, "");
          setValue(`pageID`, "");
          setValue(`instagramID`, "");
        }}
        fullWidth={true}
      />
      <SelectPageFacebook
        variant="filled"
        control={control}
        integrationID={integrationID}
        adAccountID={adAccountID}
        name={`pageID`}
        label={t(PAGE_FACEBOOK_PLACEHOLDER)}
        fullWidth={true}
      />
      <SelectInstagramAccount
        variant="filled"
        control={control}
        integrationID={integrationID}
        adAccountID={adAccountID}
        pageID={pageID}
        name={`instagramID`}
        label={t(INSTAGRAM_ACCOUNT_PLACEHOLDER)}
        fullWidth={true}
      />
    </Box>
  );
};

const TrackingIDQuestion = ({ fieldConfig, control }) => {
  const { t } = useTranslationApp();
  const { getValues } = fieldConfig.utils;

  const integrationID = getValues(`integrationID`);
  const adAccountID = getValues(`adAccountID`);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "100%",
      }}
    >
      <SelectTrackingIDField
        control={control}
        name={fieldConfig.name}
        label={t(TRACKING_META_ID_PLACEHOLDER)}
        fullWidth
        variant="filled"
        platform={META}
        integrationID={integrationID}
        adAccountID={adAccountID}
      />
      <TooltipModal
        sx={{
          mt: 0.5,
        }}
        title={t(ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION)}
        content={
          <ContentCommon text={ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION} />
        }
      />
    </Box>
  );
};

const DestinationURLQuestion = ({ fieldConfig, control }) => {
  const { t } = useTranslationApp();

  return (
    <TextField
      control={control}
      name={fieldConfig.name}
      fullWidth
      placeholder={t(CREATE_CAMPAIGN_TRAFFIC_LINK_PLACEHOLDER)}
      variant="filled"
    />
  );
};

const TypeBudgetQuestion = ({ fieldConfig, control }) => {
  const { t } = useTranslationApp();
  return (
    <Select
      name={fieldConfig.name}
      fullWidth
      variant="filled"
      placeholder={t(SELECT_A_OPTION)}
      showEmptyOption={true}
      displayEmpty={true}
      doTranslate={true}
      options={optionsAdsCampaignTypeBudgets}
      control={control}
    />
  );
};

const BudgetQuestion = ({ fieldConfig, control }) => {
  const { t } = useTranslationApp();
  return (
    <TextField
      control={control}
      name={fieldConfig.name}
      placeholder={t(CREATE_ADS_CAMPAIGN_BUDGET_PLACEHOLDER)}
      fullWidth
      variant="filled"
      InputProps={{
        inputComponent: NumberField,
      }}
      helperText={t(VALUE_EXPRESSED_IN, {
        currency: "USD",
      })}
    />
  );
};

const DatesQuestion = ({ fieldConfig, control }) => {
  const { t } = useTranslationApp();
  const { watch } = fieldConfig.utils;
  const startDate = watch("startDate");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
      }}
    >
      <DateField
        variant="filled"
        minDate={new Date()}
        name="startDate"
        label={t(START_DATE_PLACEHOLDER)}
        control={control}
      />
      <DateField
        variant="filled"
        name="endDate"
        minDate={addDays(startDate || new Date(), 1)}
        label={t(END_DATE_PLACEHOLDER)}
        control={control}
      />
    </Box>
  );
};

const MediaUrlsQuestion = ({ fieldConfig }) => {
  const { setValue, watch } = fieldConfig.utils;
  const mediaUrls = watch("mediaUrls");
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        overflowX: "auto",
        width: "100%",
      }}
    >
      {mediaUrls.length > 0 &&
        mediaUrls.map((mediaUrl, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              gap: 1,
              mb: 1,
              minHeight: 160,
              minWidth: 110,
              height: 160,
              width: 110,
              position: "relative",
            }}
          >
            {mediaUrl?.mediaData?.from === ADS_CAMPAIGN_MEDIA_FROM_CREATIFY ? (
              <iframe
                src={mediaUrl?.mediaData?.preview}
                style={{
                  display: "block",
                  border: "none",
                  overflow: "hidden",
                  height: "100%",
                  width: "100%",
                }}
              />
            ) : (
              <CardMedia
                url={mediaUrl.url}
                propsType={{
                  controls: false,
                  autoplay: true,
                }}
              />
            )}
            <IconButton
              onClick={() => {
                setValue(
                  "mediaUrls",
                  mediaUrls.filter((_, i) => i !== index)
                );
              }}
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                p: 0,
              }}
            >
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
        ))}
    </Box>
  );
};

const COMPONENTS = {
  objective: <ObjectiveQuestion />,
  destinationType: <DestinationTypeQuestion />,
  identities: <IdentitiesQuestion />,
  trackingID: <TrackingIDQuestion />,
  destinationURL: <DestinationURLQuestion />,
  typeBudget: <TypeBudgetQuestion />,
  budget: <BudgetQuestion />,
  dates: <DatesQuestion />,
  mediaUrls: <MediaUrlsQuestion />,
};

const CommonQuestionContainer = ({ formConfig, fieldConfig }) => {
  const { t } = useTranslationApp();

  const progress = formConfig.progress;
  const { control, setValue } = fieldConfig.utils;
  const error = formConfig.error;

  const COMPONENT = COMPONENTS[fieldConfig.name];

  if (!COMPONENT) return null;
  return (
    <>
      <LinearProgress
        sx={{ width: "100%", mb: 3, maxWidth: 600 }}
        variant="determinate"
        value={progress}
      />
      <AnswerContainer sx={{ display: "flex", height: "100%" }}>
        <AnswerContainerTitleAndField>
          {fieldConfig.title && (
            <AnswerTitle sx={{ mb: 1 }}>
              {`${t(fieldConfig.title, fieldConfig.titleExtrapolation)}`}
            </AnswerTitle>
          )}
          {fieldConfig.subtitle && (
            <AnswerSubTitle>
              {t(fieldConfig.subtitle, fieldConfig.subtitleExtrapolation)}
            </AnswerSubTitle>
          )}
          {error && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {error}
            </Alert>
          )}
          {React.cloneElement(COMPONENT, {
            fieldConfig,
            control,
            setValue,
            error,
          })}
        </AnswerContainerTitleAndField>
        {fieldConfig.showActionsButtons && (
          <ActionsButtons
            onPreviousStep={fieldConfig.onPreviousStep}
            showPreviousButton={fieldConfig.showPreviousButton}
            onNextStep={fieldConfig.onNextStep}
            showNextButton={fieldConfig.showNextButton}
            nextButtonText={fieldConfig.nextButtonText}
            saveButtonText={fieldConfig.saveButtonText}
            showSaveButton={fieldConfig.isFinishQuestion}
            onFinish={fieldConfig.onFinish}
            showLoadingComponent={fieldConfig.showLoadingComponent}
            customComponentPreviousNext={
              fieldConfig.customComponentPreviousNext
            }
            loading={formConfig.isCreating}
          />
        )}
      </AnswerContainer>
    </>
  );
};

export default GenerateCampaignFormMeta;
