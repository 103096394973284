import _ from "lodash";
import {
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_FEED,
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_REEL,
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_STANDARD,
  ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_STORY,
  ADS_CAMPAIGN_AD_FORMAT_TIKTOK_FEED_SINGLE_VIDEO,
  ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION,
  TIKTOK,
  META,
  VIDEO_FORMATS,
  IMAGE_FORMATS,
  ADS_TIKTOK_CAMPAIGN_OBJECTIVES_VALID_WITH_IMAGES,
  ADS_CAMPAIGN_AD_FORMAT_TIKTOK_FEED_CAROUSEL_ADS,
  ADS_CAMPAIGN_STATUS_DRAFT,
  ADS_CAMPAIGN_MEDIA_FROM_UPLOAD,
  ADS_CAMPAIGN_MEDIA_FROM_CREATIFY,
  VIDEO_PENDING_STATUS_CREATIFY,
  ALL_VIDEO_FAILED_STATUS_CREATIFY,
  VIDEO_RUNNING_STATUS_CREATIFY,
  VIDEO_IN_QUEUE_STATUS_CREATIFY,
  ADS_CAMPAIGN_MEDIA_FROM_FACEBOOK_POST,
  ADS_CAMPAIGN_MEDIA_FROM_INSTAGRAM_POST,
  ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_VIDEO_OPTION,
  GOOGLE,
  ADS_CAMPAIGNS_PLATFORMS_WITH_TRACKING_ID,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK,
  ADS_CAMPAIGN_OBJECTIVES_WITH_DESTINATION_TYPE_META,
} from "../utils/constants";
import {
  ADS_CAMPAIGN_AD_FORMAT_FACEBOOK_MOBILE_FEED_STANDARD,
  ADS_CAMPAIGN_AD_FORMAT_FACEBOOK_REELS_MOBILE,
  CLICKS_BY_PLATFORM,
  CPA,
  IMPRESSIONS_BY_PLATFORM,
  MAIN_ACTIONS_BY_PLATFORM,
  RESULTS,
  SPEND,
} from "../i18n/keysTranslations";
import i18next from "i18next";
import { getUniqueID } from "../utils/numbers";

export const getStatusAdsCampaign = ({ states, target = "general" }) => {
  const status = states?.[target];
  return status;
};

export const getMetricsValueType = (statistic) => {
  switch (statistic) {
    case "impressions":
    case "clicks":
      return "number";

    case "ctr":
      return "percentage";

    case "mainActionLabel":
    case "currency":
      return "string";

    default:
      return "number";
  }
};

export const getGeneralStatistics = (statistics) => {
  const platforms = Object.keys(statistics);
  let generalStatistics = {};
  let lastUpdatedTime;

  platforms.forEach((platform) => {
    const platformStatistics = statistics[platform];
    if (!lastUpdatedTime) {
      lastUpdatedTime = platformStatistics.lastUpdatedTime;
    }

    if (lastUpdatedTime < platformStatistics.lastUpdatedTime) {
      lastUpdatedTime = platformStatistics.lastUpdatedTime;
    }

    Object.keys(platformStatistics).forEach((statistic) => {
      const valueType = getMetricsValueType(statistic);

      if (valueType === "number" || valueType === "percentage") {
        if (!generalStatistics[statistic]) {
          generalStatistics[statistic] = 0;
        }
        generalStatistics[statistic] += Number(platformStatistics[statistic]);
      }
      if (valueType === "string") {
        generalStatistics[statistic] = platformStatistics[statistic];
      }
    });
  });

  Object.keys(generalStatistics).forEach((statistic) => {
    const valueType = getMetricsValueType(statistic);

    if (valueType === "percentage") {
      generalStatistics[statistic] =
        generalStatistics[statistic] / platforms.length;
    }
  });

  if (_.isEmpty(generalStatistics)) {
    return {};
  }

  return { ...generalStatistics, lastUpdatedTime };
};

// Actualiza los estados de la campaña a nivel general, plataforma, adgroup o ad
export const getCampaignUpdatedByStatus = ({
  campaign,
  status,
  target = "general",
  platform,
  adGroupID,
  adID,
}) => {
  let objectUpdate = _.cloneDeep(campaign);
  switch (target) {
    case "campaign":
      return updateCampaignByCampaignStatus({ campaign: objectUpdate, status });
    case "platform":
      return updateCampaignByPlatformStatus({
        campaign: objectUpdate,
        platform,
        status,
      });
    case "adGroup":
      return updateCampaignByAdGroupStatus({
        campaign: objectUpdate,
        platform,
        adGroupID,
        status,
      });
    case "ad":
      return updateCampaignByAdStatus({
        campaign: objectUpdate,
        platform,
        adGroupID,
        adID,
        status,
      });
    default:
      return objectUpdate;
  }
};

const updateCampaignByCampaignStatus = ({ campaign, status }) => {
  const objectUpdate = _.cloneDeep(campaign);

  objectUpdate.states.general = status;

  objectUpdate.platforms?.forEach((platform) => {
    const currentStatus = objectUpdate.states[platform];
    if (currentStatus === ADS_CAMPAIGN_STATUS_DRAFT) return;
    objectUpdate.states[platform] = status;
  });

  if (objectUpdate.adsGroups) {
    objectUpdate.adsGroups?.forEach((adGroup, adGroupIndex) => {
      if (adGroup.ads) {
        adGroup.ads?.forEach((ad, adIndex) => {
          const currentStatus =
            objectUpdate.adsGroups[adGroupIndex].ads[adIndex].status;
          if (currentStatus === ADS_CAMPAIGN_STATUS_DRAFT) return;
          objectUpdate.adsGroups[adGroupIndex].ads[adIndex].status = status;
        });
      }
      const currentStatus = objectUpdate.adsGroups[adGroupIndex].status;
      if (currentStatus === ADS_CAMPAIGN_STATUS_DRAFT) return;
      objectUpdate.adsGroups[adGroupIndex].status = status;
    });
  }

  return objectUpdate;
};

const updateCampaignByPlatformStatus = ({ campaign, platform, status }) => {
  let objectUpdate = _.cloneDeep(campaign);
  const { adsGroups, platforms } = objectUpdate;

  // Actualiza el estado de la plataforma
  objectUpdate.states[platform] = status;

  // Verifica si hay alguna plataforma activa
  const hasOnePlatformActive = platforms.some(
    (platformKey) =>
      objectUpdate?.states?.[platformKey] === ADS_CAMPAIGN_STATUS_ACTIVE
  );

  // Si no queda ninguna plataforma activa, desactiva la campaña
  if (!hasOnePlatformActive) {
    return updateCampaignByCampaignStatus({ campaign: objectUpdate, status });
  }

  // Si la campaña está desactivada y se activa una plataforma, activa la campaña
  if (
    status === ADS_CAMPAIGN_STATUS_ACTIVE &&
    objectUpdate.states.general !== ADS_CAMPAIGN_STATUS_ACTIVE
  ) {
    objectUpdate.states.general = ADS_CAMPAIGN_STATUS_ACTIVE;
  }

  // Recorre los adGroups de la plataforma y actualiza sus estados
  adsGroups
    .filter((adGroup) => adGroup.platform === platform)
    .forEach((adGroup) => {
      adGroup.ads?.forEach((ad) => {
        ad.status = status;
      });
      adGroup.status = status;
    });

  return objectUpdate;
};

const updateCampaignByAdGroupStatus = ({
  campaign,
  platform,
  adGroupID,
  status,
}) => {
  let objectUpdate = _.cloneDeep(campaign);

  // Encuentra el adGroup correspondiente al adGroupID y platform
  objectUpdate.adsGroups?.forEach((adGroup, adGroupIndex) => {
    if (adGroup.platform === platform && adGroupIndex === adGroupID) {
      // Actualiza el estado de los ads dentro del adgroup
      adGroup.ads?.forEach((ad, adIndex) => {
        objectUpdate.adsGroups[adGroupIndex].ads[adIndex].status = status;
      });

      // Actualiza el estado del adgroup
      objectUpdate.adsGroups[adGroupIndex].status = status;
    }
  });

  // Verifica si queda al menos un adgroup activo en la plataforma
  const hasOneAdGroupActive = objectUpdate.adsGroups?.some(
    (adGroup) =>
      adGroup.platform === platform &&
      adGroup.status === ADS_CAMPAIGN_STATUS_ACTIVE
  );

  // Si no queda ningún adgroup activo, desactiva la plataforma
  if (!hasOneAdGroupActive && status === ADS_CAMPAIGN_STATUS_DEACTIVATED) {
    return updateCampaignByPlatformStatus({
      campaign: objectUpdate,
      platform,
      status,
    });
  }

  // Si la plataforma está desactivada y se activa un adgroup, solo activa la plataforma
  if (
    status === ADS_CAMPAIGN_STATUS_ACTIVE &&
    objectUpdate.states[platform] !== ADS_CAMPAIGN_STATUS_ACTIVE
  ) {
    objectUpdate.states[platform] = ADS_CAMPAIGN_STATUS_ACTIVE;

    //si la campaña está desactivada, activa la campaña
    if (objectUpdate.states.general !== ADS_CAMPAIGN_STATUS_ACTIVE) {
      objectUpdate.states.general = ADS_CAMPAIGN_STATUS_ACTIVE;
    }
  }

  return objectUpdate;
};

const updateCampaignByAdStatus = ({
  campaign,
  platform,
  adGroupID,
  adID,
  status,
}) => {
  let objectUpdate = _.cloneDeep(campaign);

  // Encuentra los adGroups correspondientes a la plataforma
  const adsGroupsPlatform = objectUpdate.adsGroups?.filter(
    (adGroup) => adGroup.platform === platform
  );

  if (adsGroupsPlatform.length === 0) {
    // Si no hay adGroups en la plataforma, actualiza la plataforma
    return updateCampaignByPlatformStatus({
      campaign: objectUpdate,
      platform,
      status,
    });
  }

  // Actualiza el estado del ad correspondiente
  adsGroupsPlatform.forEach((adGroup, adGroupIndex) => {
    if (adGroupIndex === adGroupID) {
      const adIndex = adGroup.ads.findIndex((ad) => ad.id === adID);

      if (adIndex !== -1) {
        objectUpdate.adsGroups[adGroupIndex].ads[adIndex].status = status;
      }
    }
  });

  // Verifica si queda al menos un ad activo en el adGroup
  const hasOneAdActive = objectUpdate.adsGroups
    .find(
      (adGroup) => adGroup.platform === platform && adGroup.id === adGroupID
    )
    ?.ads.some((ad) => ad.status === ADS_CAMPAIGN_STATUS_ACTIVE);

  // Si no queda ningún ad activo en el adGroup, desactiva el adGroup
  if (!hasOneAdActive && status === ADS_CAMPAIGN_STATUS_DEACTIVATED) {
    return updateCampaignByAdGroupStatus({
      campaign: objectUpdate,
      platform,
      adGroupID,
      status,
    });
  }

  // Verifica si queda al menos un adGroup activo en la plataforma
  const hasOneAdGroupActive = objectUpdate.adsGroups
    .filter((adGroup) => adGroup.platform === platform)
    .some((adGroup) => adGroup.status === ADS_CAMPAIGN_STATUS_ACTIVE);

  // Si el ad está activado y el adGroup está desactivado, activa el adGroup
  if (status === ADS_CAMPAIGN_STATUS_ACTIVE && !hasOneAdGroupActive) {
    objectUpdate = updateCampaignByAdGroupStatus({
      campaign: objectUpdate,
      platform,
      adGroupID,
      status,
    });
  }

  // Verifica si queda al menos un adGroup activo en la plataforma
  if (status === ADS_CAMPAIGN_STATUS_ACTIVE && !objectUpdate.states[platform]) {
    // Si el platform está desactivado, activa el platform
    objectUpdate.states[platform] = ADS_CAMPAIGN_STATUS_ACTIVE;

    // Si la campaña está desactivada, activa la campaña
    if (objectUpdate.states.general !== ADS_CAMPAIGN_STATUS_ACTIVE) {
      objectUpdate.states.general = ADS_CAMPAIGN_STATUS_ACTIVE;
    }
  }

  return objectUpdate;
};

export const formattedIframeByType = ({ iframe = "", type, isMobile }) => {
  let newWidth;
  let newHeight;

  if (!iframe) {
    if (isMobile) {
      newWidth = 272;
      newHeight = 550;
    } else {
      newWidth = 320;
      newHeight = 567;
    }

    return {
      iframe: "",
      src: "",
      width: newWidth,
      height: newHeight,
    };
  }

  if (
    type === ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_STORY ||
    type === ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_REEL ||
    type === ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_FEED
  ) {
    if (isMobile) {
      newWidth = 272;
      newHeight = 550;
    } else {
      newWidth = 320;
      newHeight = 567;
    }
  }
  if (type === ADS_CAMPAIGN_AD_FORMAT_INSTAGRAM_STANDARD) {
    if (isMobile) {
      newWidth = 282;
      newHeight = 550;
    } else {
      newWidth = 320;
      newHeight = 612;
    }
  }
  if (type === ADS_CAMPAIGN_AD_FORMAT_FACEBOOK_MOBILE_FEED_STANDARD) {
    if (isMobile) {
      newWidth = 282;
      newHeight = 550;
    } else {
      newWidth = 320;
      newHeight = 500;
    }
  }
  if (type === ADS_CAMPAIGN_AD_FORMAT_FACEBOOK_REELS_MOBILE) {
    if (isMobile) {
      newWidth = 282;
      newHeight = 550;
    } else {
      newWidth = 320;
      newHeight = 530;
    }
  }

  if (
    type === ADS_CAMPAIGN_AD_FORMAT_TIKTOK_FEED_SINGLE_VIDEO ||
    type === ADS_CAMPAIGN_AD_FORMAT_TIKTOK_FEED_CAROUSEL_ADS
  ) {
    newWidth = 250;
    newHeight = 556;
  }

  const src = iframe.match(/src="([^"]*)"/)?.[1]?.replace(/&amp;/g, "&");

  let iframeFormatted = iframe
    .replace(/width="[^"]*"/, `width="${newWidth}"`)
    .replace(/height="[^"]*"/, `height="${newHeight}"`)
    .replace(/scrolling="[^"]*"/, `scrolling="no"`);

  const newStyle = isMobile
    ? "border: none; overflow: hidden;"
    : "border: 1px solid white; overflow: hidden;";
  if (iframeFormatted.includes('style="')) {
    iframeFormatted = iframeFormatted.replace(
      /style="[^"]*"/,
      `style="${newStyle}"`
    );
  } else {
    iframeFormatted = iframeFormatted.replace(
      "<iframe",
      `<iframe style="${newStyle}"`
    );
  }
  return { iframe: iframeFormatted, width: newWidth, height: newHeight, src };
};

export const getDefaultEventTrackingMeta = (objective) => {
  switch (objective) {
    case ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION:
      return "CONTENT_VIEW";
    case ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION:
      return "COMPLETE_REGISTRATION";
    case ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION:
      return "PURCHASE";
    default:
      return "";
  }
};
export const getDefaultEventTrackingTikTok = (objective) => {
  switch (objective) {
    case ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION:
      return "ON_WEB_REGISTER";
    case ADS_CAMPAIGN_OBJECTIVE_SALES_OPTION:
      return "SHOPPING";
    default:
      return "";
  }
};
export const getDefaultPlatformData = (platform) => {
  if (platform === TIKTOK) {
    return {
      integrationID: "",
      adAccountID: "",
      identityTikTokID: "",
      trackingID: "",
      trackingCustomEventID: "",
    };
  }
  if (platform === META) {
    return {
      integrationID: "",
      adAccountID: "",
      pageID: "",
      instagramID: "",
      trackingID: "",
      trackingCustomEventID: "",
    };
  }
  if (platform === GOOGLE) {
    return {
      integrationID: "",
      adAccountID: "",
      trackingID: "",
      trackingCustomEventID: "",
    };
  }
};
export const getFormatsUpload = ({
  platforms,
  objective,
  platform,
  mode = "general",
}) => {
  if (mode === "general") {
    const hasTikTokPlatform = platforms?.includes(TIKTOK);

    if (!hasTikTokPlatform) {
      return VIDEO_FORMATS.concat(IMAGE_FORMATS);
    }

    if (ADS_TIKTOK_CAMPAIGN_OBJECTIVES_VALID_WITH_IMAGES.includes(objective)) {
      return VIDEO_FORMATS.concat(IMAGE_FORMATS);
    }

    return VIDEO_FORMATS;
  }

  if (platform === TIKTOK) {
    return VIDEO_FORMATS;
  }

  return VIDEO_FORMATS.concat(IMAGE_FORMATS);
};

export const formatMediaUrl = (content) => {
  if (content?.from === ADS_CAMPAIGN_MEDIA_FROM_FACEBOOK_POST) {
    return {
      url: "",
      mediaData: {
        from: ADS_CAMPAIGN_MEDIA_FROM_FACEBOOK_POST,
        postID: content.id,
      },
    };
  }
  if (content.from === ADS_CAMPAIGN_MEDIA_FROM_INSTAGRAM_POST) {
    return {
      url: "",
      mediaData: {
        from: ADS_CAMPAIGN_MEDIA_FROM_INSTAGRAM_POST,
        postID: content.id,
      },
    };
  }

  if (content?.from === ADS_CAMPAIGN_MEDIA_FROM_UPLOAD) {
    return {
      url: content.url,
      mediaData: {
        from: ADS_CAMPAIGN_MEDIA_FROM_UPLOAD,
      },
    };
  }

  return {
    url: content.url,
    mediaData: {
      from: ADS_CAMPAIGN_MEDIA_FROM_UPLOAD,
    },
  };
};
export const formatMediaUrlAdFromCreatify = (videoData) => ({
  url: videoData.videoOutput || "",
  mediaData: {
    from: ADS_CAMPAIGN_MEDIA_FROM_CREATIFY,
    videoID: videoData.id,
    status: videoData.status,
    preview: videoData.preview,
  },
});

export const generalFormatMediaUrl = ({ from, content }) => {
  switch (from) {
    case "upload":
      return formatMediaUrl(content);

    case "creatify":
      return formatMediaUrlAdFromCreatify(content);
  }
};

export const getAdsWithUnRenderedAndFailedRenderedMedia = (campaign = {}) => {
  try {
    let adsWithUnRenderedMedia = {};
    let adsWithRenderFailedMedia = {};
    campaign?.adsGroups?.forEach((adsGroup) => {
      const ads = adsGroup?.ads || [];
      ads.forEach((ad) => {
        const mediaData = ad?.mediaData || [];
        const mediaUrls = ad?.mediaUrls || [];

        mediaData.forEach((mediaDataItem, index) => {
          const isUnRenderedMedia =
            mediaDataItem?.from === ADS_CAMPAIGN_MEDIA_FROM_CREATIFY &&
            mediaDataItem?.status === VIDEO_PENDING_STATUS_CREATIFY &&
            !mediaUrls[index];
          const isRenderFailedMedia =
            mediaDataItem?.from === ADS_CAMPAIGN_MEDIA_FROM_CREATIFY &&
            ALL_VIDEO_FAILED_STATUS_CREATIFY.includes(mediaDataItem?.status) &&
            !mediaUrls[index];

          if (isUnRenderedMedia) {
            const videoID = mediaDataItem?.videoID;
            if (!adsWithUnRenderedMedia[videoID]) {
              adsWithUnRenderedMedia[videoID] = {
                adIDs: [],
                videoID,
              };
            }
            adsWithUnRenderedMedia[videoID].adIDs.push(ad.id);
          }

          if (isRenderFailedMedia) {
            const videoID = mediaDataItem?.videoID;
            if (!adsWithRenderFailedMedia[videoID]) {
              adsWithRenderFailedMedia[videoID] = {
                adIDs: [],
                videoID,
              };
            }
            adsWithRenderFailedMedia[videoID].adIDs.push(ad.id);
          }
        });
      });
    });

    adsWithUnRenderedMedia = Object.values(adsWithUnRenderedMedia);
    adsWithRenderFailedMedia = Object.values(adsWithRenderFailedMedia);
    return {
      ok: true,
      adsWithUnRenderedMedia,
      adsWithRenderFailedMedia,
    };
  } catch (error) {
    console.error("Error getAdsWithUnRenderedAndFailedRenderedMedia", error);
    return {
      ok: false,
      adsWithUnRenderedMedia: [],
      adsWithRenderFailedMedia: [],
    };
  }
};

export const getAdsWithRenderingMedia = (campaign = {}) => {
  try {
    let adsWithRenderingMedia = {};
    campaign?.adsGroups?.forEach((adsGroup) => {
      const ads = adsGroup?.ads || [];
      ads.forEach((ad) => {
        const statusInRendering = [
          VIDEO_RUNNING_STATUS_CREATIFY,
          VIDEO_IN_QUEUE_STATUS_CREATIFY,
        ];

        const mediaData = ad?.mediaData || [];
        const mediaUrls = ad?.mediaUrls || [];

        mediaData.forEach((mediaDataItem, index) => {
          const isRenderingMedia =
            mediaDataItem?.from === ADS_CAMPAIGN_MEDIA_FROM_CREATIFY &&
            statusInRendering.includes(mediaDataItem?.status) &&
            !mediaUrls[index];

          if (isRenderingMedia) {
            const videoID = mediaDataItem?.videoID;

            if (!adsWithRenderingMedia[videoID]) {
              adsWithRenderingMedia[videoID] = {
                adIDs: [],
                videoID,
              };
            }
            adsWithRenderingMedia[videoID].adIDs.push(ad.id);
          }
        });
      });
    });

    adsWithRenderingMedia = Object.values(adsWithRenderingMedia);
    return {
      ok: true,
      adsWithRenderingMedia,
    };
  } catch (error) {
    console.error("Error getAdsWithRenderingMedia", error);
    return {
      ok: false,
      adsWithRenderingMedia: [],
    };
  }
};

export const mapStatusFromOptimization = (status) => {
  switch (status) {
    case true:
      return ADS_CAMPAIGN_STATUS_ACTIVE;

    case false:
      return ADS_CAMPAIGN_STATUS_DEACTIVATED;

    default:
      return "";
  }
};

export const mapTitleStatisticsByMetric = ({ metric }) => {
  switch (metric) {
    case "spend":
      return `${i18next.t(SPEND)}`;
    case "mainAction":
      return i18next.t(RESULTS);
    case "costPerMainAction":
      return `${i18next.t(CPA)}`;

    case "impressionsByPlatform":
      return i18next.t(IMPRESSIONS_BY_PLATFORM);
    case "clicksByPlatform":
      return i18next.t(CLICKS_BY_PLATFORM);
    case "mainActionByPlatform":
      return i18next.t(MAIN_ACTIONS_BY_PLATFORM);

    default:
      return i18next.t(metric);
  }
};

export const getFromStatisticsAdsCampaign = (data) => {
  let totalStatistics = _.cloneDeep(data?.historicalStatistics?.total) || {};
  let historicalStatistics =
    _.cloneDeep(data?.historicalStatistics?.historical) || {};
  let statistics = _.cloneDeep(data?.statistics) || {};

  if (
    _.isEmpty(historicalStatistics) &&
    _.isEmpty(statistics) &&
    _.isEmpty(totalStatistics)
  ) {
    return { from: "empty", platforms: [], statistics: {}, historical: {} };
  }

  if (
    Object.keys(historicalStatistics).length > 0 ||
    Object.keys(totalStatistics).length > 0
  ) {
    let hasData = false;

    Object.keys(totalStatistics).forEach((platform) => {
      if (
        totalStatistics?.[platform]?.campaign?.spend > 0 ||
        totalStatistics?.[platform]?.campaign?.impressions > 0
      ) {
        hasData = true;
      }
    });
    Object.keys(historicalStatistics).forEach((snapshotKey) => {
      const snapshot = _.cloneDeep(historicalStatistics[snapshotKey]);
      delete snapshot.startDateTime;
      delete snapshot.endDateTime;

      Object.keys(snapshot).forEach((platform) => {
        if (
          snapshot?.[platform]?.campaign?.spend > 0 ||
          snapshot?.[platform]?.campaign?.impressions > 0
        ) {
          hasData = true;
        }
      });
    });

    if (!hasData) {
      return { from: "empty", platforms: [], statistics: {}, historical: {} };
    }

    if (_.isEmpty(historicalStatistics)) {
      const startDateTime = new Date(data.firstActiveTime).getTime();
      const endDateTime = new Date().getTime();

      historicalStatistics = {
        [getUniqueID()]: {
          startDateTime,
          endDateTime,
          ...totalStatistics,
        },
      };
    }

    return {
      from: "historical",
      platforms: Object.keys(totalStatistics),
      statistics: totalStatistics,
      historical: historicalStatistics,
    };
  }

  let hasData = false;

  Object.keys(statistics).forEach((platform) => {
    if (
      statistics?.[platform]?.spend > 0 ||
      statistics?.[platform]?.impressions > 0
    ) {
      hasData = true;
    }
  });

  if (!hasData) {
    return { from: "empty", platforms: [], statistics: {}, historical: {} };
  }

  return {
    from: "current",
    platforms: Object.keys(statistics),
    statistics,
    historical: {},
  };
};

export const getIsSpecialAdWithOnlyTitles = ({
  platform,
  objective,
  destinationType,
}) => {
  if (platform !== META && platform !== GOOGLE) {
    return false;
  }

  if (
    objective === ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION &&
    destinationType ===
      ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION
  )
    return true;

  if (
    objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION &&
    destinationType === ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
  )
    return true;

  return false;
};

export const getIsSpecialAdWithoutCallToAction = ({
  platform,
  objective,
  destinationType,
}) => {
  if (platform !== META) {
    return false;
  }

  if (
    objective === ADS_CAMPAIGN_OBJECTIVE_TRAFFIC_OPTION &&
    destinationType ===
      ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION
  )
    return true;

  if (
    objective === ADS_CAMPAIGN_OBJECTIVE_ENGAGEMENT_OPTION &&
    destinationType === ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION
  )
    return true;

  return false;
};

export const getIsCarouselAdIsAvailable = ({ platform, destinationType }) => {
  if (platform !== META) {
    return false;
  }

  if (
    destinationType === ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_POST_OPTION ||
    destinationType === ADS_CAMPAIGN_META_DESTINATION_TYPE_ON_VIDEO_OPTION
  ) {
    return false;
  }

  return true;
};

export const getAdsTextsFormatted = ({ texts = [], format = "load" }) => {
  try {
    if (format === "load") {
      return texts.map((text) => {
        return {
          id: getUniqueID(),
          value: text,
        };
      });
    }

    return texts.map((text) => text.value);
  } catch (error) {
    console.error("Error getAdsTextsFormatted", error);
    return [];
  }
};

export const sumStatisticsAdsCampaign = ({ current = {}, addition = {} }) => {
  if (_.isEmpty(current) && _.isEmpty(addition)) return {};
  if (_.isEmpty(current)) return addition;
  const sumStatistics = {};

  const fieldsWithSum = [
    "spend",
    "reach",
    "click",
    "impressions",
    "mainAction",
  ];

  fieldsWithSum.forEach((field) => {
    sumStatistics[field] = (current[field] || 0) + (addition[field] || 0);
  });

  sumStatistics.frequency =
    (current.frequency || 0 + addition.frequency || 0) / 2;

  if (sumStatistics.spend && sumStatistics.impressions) {
    sumStatistics.cpm =
      (sumStatistics.spend / sumStatistics.impressions) * 1000;
  } else {
    sumStatistics.cpm = 0;
  }
  if (sumStatistics.spend && sumStatistics.click) {
    sumStatistics.cpc = sumStatistics.spend / sumStatistics.click;
  } else {
    sumStatistics.cpc = 0;
  }
  if (sumStatistics.click && sumStatistics.impressions) {
    sumStatistics.ctr = (sumStatistics.click / sumStatistics.impressions) * 100;
  } else {
    sumStatistics.ctr = 0;
  }
  if (sumStatistics.mainAction && sumStatistics.spend) {
    sumStatistics.costPerMainAction =
      sumStatistics.spend / sumStatistics.mainAction;
  }

  sumStatistics.currency = current.currency || "USD";
  sumStatistics.mainActionLabel = current.mainActionLabel || "";

  return sumStatistics;
};

export const getPlatformsWithTrackingID = ({
  platforms,
  platformsData,
  objective,
}) => {
  let isObjectiveWithPossibleTrackingID = false;

  platforms.forEach((platform) => {
    if (
      platform === META &&
      ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective)
    ) {
      isObjectiveWithPossibleTrackingID = true;
    }

    if (
      platform === TIKTOK &&
      ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK.includes(objective)
    ) {
      isObjectiveWithPossibleTrackingID = true;
    }
  });

  if (!isObjectiveWithPossibleTrackingID) {
    return [];
  }

  const platformsWithTrackingID = [];

  platforms.forEach((platform) => {
    if (!ADS_CAMPAIGNS_PLATFORMS_WITH_TRACKING_ID.includes(platform)) return;
    const platformData = platformsData?.[platform];
    const destinationType =
      platformData?.destinationType ||
      ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION;
    if (destinationType !== ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION)
      return;

    platformsWithTrackingID.push(platform);
  });

  return platformsWithTrackingID;
};

export const getIsRequiredDestinationType = ({ platforms, objective }) => {
  if (
    platforms.includes(META) &&
    ADS_CAMPAIGN_OBJECTIVES_WITH_DESTINATION_TYPE_META.includes(objective)
  )
    return true;

  return false;
};

export const getIsRequiredDestinationURL = ({ platforms, platformsData }) => {
  if (platforms.length >= 2) {
    return true;
  }

  let isRequiredDestinationURL = false;

  const destinationTypeMeta =
    platformsData[META]?.destinationType ||
    ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION;

  if (
    platforms.length === 1 &&
    platforms.includes(META) &&
    destinationTypeMeta === ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
  ) {
    isRequiredDestinationURL = true;
  }

  return isRequiredDestinationURL;
};
