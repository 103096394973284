import { useState } from "react";
import {
  AD_ACCOUNT_PLATFORMS_LABELS,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK,
  LABELS_TRACKING_CUSTOM_EVENTS,
  LABELS_TRACKING_ID,
  TIKTOK,
} from "../../../../utils/constants";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { useFormContext } from "react-hook-form";
import { useTranslationApp } from "../../../../lib/i18next";
import _ from "lodash";
import SelectBusinessAccount from "../../../Meta/SelectBusinessAccount";
import {
  ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION,
  FIELD_REQUIRED,
  IDENTITY_TIK_TOK_PLACEHOLDER,
  OBJECTIVE_PLACEHOLDER,
} from "../../../../i18n/keysTranslations";
import { optionsAdsCampaignObjectives } from "../../../../utils/options";
import Select from "../../../Form/Select";
import SelectTrackingIDField from "../../../Form/SelectTrackingIDField";
import TooltipModal from "../../../Tooltip/TooltipModal";
import ContentCommon from "../../../Tooltip/ContentCommon";
import SelectCustomEventAds from "../../../Form/SelectCustomEventAds";
import SelectTikTokIdentityCustom from "../../../Form/SelectTikTokIdentityCustom";
import ReasoningContainer from "../../../../pages/NewAdsCampaign/components/Common/ReasoningContainer";

const platform = TIKTOK;
const grid = {
  xs: 12,
  lg: 6,
};

const CampaignTikTokForm = () => {
  const [pixels, setPixels] = useState([]);

  const {
    control,
    formState,
    watch,
    setValue,
    getValues,
    isGeneralDisabledFields,
    getIsCampaignPlatformCreated,
  } = useFormContext();
  useFocusErrorForm(formState);
  const errors = formState.errors;

  const { t } = useTranslationApp();

  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);

  const basicPath = `platformsData.${platform}`;
  const platformData = watch(basicPath) || {};
  const objective = platformData?.objective;
  const reasoningPlatform = platformData?.reasoning;
  const oldReasoning = getValues("reasoning");

  const pixelSelected = _.isEmpty(pixels)
    ? {}
    : pixels.find((p) => p.id === platformData?.trackingID);
  return (
    <Grid container spacing={2}>
      {!isCampaignPlatformCreated && (
        <Grid item xs={12}>
          <ReasoningContainer reasoning={reasoningPlatform || oldReasoning} />
        </Grid>
      )}
      <Grid item {...grid}>
        <Select
          label={t(OBJECTIVE_PLACEHOLDER)}
          options={optionsAdsCampaignObjectives}
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
          name={`${basicPath}.objective`}
          variant="filled"
          fullWidth
          doTranslate={true}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectBusinessAccount
          variant="filled"
          disabled={
            Boolean(platformData?.adAccountID) || isGeneralDisabledFields
          }
          label={t(AD_ACCOUNT_PLATFORMS_LABELS[platform])}
          control={control}
          platform={platform}
          name={`${basicPath}.adAccountID`}
          onChangeAction={(value) => {
            setValue(`${basicPath}.integrationID`, value?.integrationID);
            setValue(`${basicPath}.trackingID`, "");
            setValue(`${basicPath}.trackingCustomEventID`, "");
            setValue(`${basicPath}.pageID`, "");
            setValue(`${basicPath}.instagramID`, "");
            setValue(`${basicPath}.whatsappBusinessNumber`, {});
            setValue(`${basicPath}.instantForm`, {});
          }}
          errors={errors}
          fullWidth={true}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectTikTokIdentityCustom
          label={t(IDENTITY_TIK_TOK_PLACEHOLDER)}
          objective={objective}
          adAccountID={platformData?.adAccountID}
          integrationID={platformData?.integrationID}
          name={`${basicPath}.identityTikTokID`}
          fullWidth
          variant="filled"
          control={control}
          errors={errors}
          disabled={
            isCampaignPlatformCreated ||
            !platformData?.adAccountID ||
            isGeneralDisabledFields
          }
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>

      {ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK.includes(objective) && (
        <Grid item {...grid}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: 1,
            }}
          >
            <SelectTrackingIDField
              name={`${basicPath}.trackingID`}
              disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
              label={t(LABELS_TRACKING_ID[platform])}
              fullWidth
              variant="filled"
              platform={platform}
              integrationID={platformData?.integrationID}
              adAccountID={platformData?.adAccountID}
              onLoadOptions={(newPixels) => {
                setPixels(newPixels);
              }}
              onChangeAction={() => {
                setValue(`${basicPath}.trackingCustomEventID`, "");
              }}
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
            <TooltipModal
              sx={{
                mt: 0.5,
              }}
              title={t(ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION)}
              content={
                <ContentCommon
                  text={ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION}
                />
              }
            />
          </Box>
        </Grid>
      )}

      {ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK.includes(objective) && (
        <Grid item {...grid}>
          <SelectCustomEventAds
            label={t(LABELS_TRACKING_CUSTOM_EVENTS[platform])}
            name={`${basicPath}.trackingCustomEventID`}
            platform={platform}
            variant="filled"
            disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
            integrationID={platformData?.integrationID}
            adAccountID={platformData?.adAccountID}
            pixelSelected={pixelSelected}
            objective={objective}
            control={control}
            errors={errors}
            fullWidth={true}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CampaignTikTokForm;
