import { useEffect, useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { useDispatchApp } from "../../lib/redux";
import { getPathDatabaseByRole } from "../../actions/auth";
import { ROLES_PLATFORMS } from "../../utils/constants";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Loader from "../Loaders/Loader";
import { SELECT_ADVERTISER_ID_PLACEHOLDER } from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { setShowBackdrop } from "../../actions/ui";
import {
  startChangeAdvertiserIDCustomClaim,
  startGetAdvertisersByAgency,
  startGetBusinessNameByAdvertiserIDs,
} from "../../actions/user";
import _ from "lodash";

export const SelectAdvertiserID = ({
  disabled,
  fullWidth,
  placeholder,
  variant = "filled",
  sx = {},
  onChangeAction = () => {},
}) => {
  const { control, setValue } = useForm({
    defaultValues: {
      advertiserID: "",
    },
  });
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatchApp();
  const { userID, agencyID, role } = dispatch(getPathDatabaseByRole());
  const { t } = useTranslationApp();

  const {
    field: { value: fieldValue, onChange: onFieldChange },
  } = useController({
    name: "advertiserID",
    control,
  });

  const onChange = async (newAdvertiserID) => {
    dispatch(setShowBackdrop(true));
    const response = await dispatch(
      startChangeAdvertiserIDCustomClaim({ advertiserID: newAdvertiserID })
    );
    if (response) {
      onFieldChange(newAdvertiserID);
      onChangeAction(newAdvertiserID);
    }
    dispatch(setShowBackdrop(false));
  };

  const getInitialData = async () => {
    const responseAdvertisers = await dispatch(startGetAdvertisersByAgency());

    if (!responseAdvertisers.ok) {
      setIsLoading(false);
      return;
    }

    const advertisers = responseAdvertisers.data;

    if (_.isEmpty(advertisers)) {
      setIsLoading(false);
      return;
    }

    const advertisersBusiness = await dispatch(
      startGetBusinessNameByAdvertiserIDs({
        advertiserIDs: advertisers,
      })
    );

    setOptions(
      advertisers.map((advertiserID) => ({
        name: advertisersBusiness[advertiserID] || advertiserID,
        id: advertiserID,
      }))
    );

    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userID) setValue("advertiserID", userID);

    // eslint-disable-next-line
  }, [userID]);

  if (!ROLES_PLATFORMS.includes(role)) return null;

  if (isLoading) return <Loader hasMarginTop={false} size={20} />;

  if (options?.length < 2 || !agencyID) return null;

  return (
    <Controller
      control={control}
      name="advertiserID"
      render={({ field }) => (
        <Autocomplete
          {...field}
          fullWidth={fullWidth}
          disabled={disabled}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          sx={sx}
          onChange={(_, value) => {
            if (fieldValue !== value?.id) {
              onChange(value?.id);
            }
          }}
          isOptionEqualToValue={(option, value) => {
            return option?.id === value.id;
          }}
          disableClearable={true}
          getOptionLabel={(option) => {
            const optionValue = options.find((opt) => opt.id === option);
            if (optionValue) {
              return `${optionValue?.name}`;
            }
            if (typeof option === "string") {
              return option;
            }
            return `${option?.name}`;
          }}
          options={options}
          renderOption={(props, option) => {
            return (
              <Box {...props} component="li" key={option.id}>
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {option.name}
                </Typography>
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(SELECT_ADVERTISER_ID_PLACEHOLDER)}
              variant={variant}
              placeholder={field?.value?.length > 0 ? "" : placeholder}
              sx={{
                ".MuiFormHelperText-root": {
                  ml: "0 !important",
                },
              }}
            />
          )}
        />
      )}
    />
  );
};
