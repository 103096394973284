import { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SelectBusinessAccount from "../../../Meta/SelectBusinessAccount";
import {
  ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION,
  ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION,
  FIELD_REQUIRED,
  INSTAGRAM_ACCOUNT_PLACEHOLDER,
  INSTANT_FORM_PLACEHOLDER,
  META_DESTINATION_TYPE_PLACEHOLDER,
  OBJECTIVE_PLACEHOLDER,
  PAGE_FACEBOOK_PLACEHOLDER,
  WELCOME_MESSAGE_TEMPLATE_PLACEHOLDER,
  WHATSAPP_BUSINESS_NUMBER_PLACEHOLDER,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";
import { useFormContext } from "react-hook-form";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import {
  AD_ACCOUNT_PLATFORMS_LABELS,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPES_WITH_MESSAGE_TEMPLATE,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVES_META_WITH_DESTINATION_TYPE,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META,
  LABELS_TRACKING_CUSTOM_EVENTS,
  LABELS_TRACKING_ID,
  META,
} from "../../../../utils/constants";
import SelectPageFacebook from "../../../Meta/SelectPageFacebook";
import SelectInstagramAccount from "../../../Meta/SelectInstagramAccount";
import SelectDestinationTypeMeta from "../../../Meta/SelectDestinationTypeMeta";
import SelectWhatsappBusinessNumber from "../../../Form/SelectWhatsappBusinessNumber";
import MessageTemplateContainer from "../../../Meta/MessageTemplateContainer";
import InstantFormContainer from "../../../Meta/InstantFormContainer";
import SelectTrackingIDField from "../../../Form/SelectTrackingIDField";
import TooltipModal from "../../../Tooltip/TooltipModal";
import ContentCommon from "../../../Tooltip/ContentCommon";
import _ from "lodash";
import SelectCustomEventAds from "../../../Form/SelectCustomEventAds";
import Select from "../../../Form/Select";
import { optionsAdsCampaignObjectives } from "../../../../utils/options";
import ReasoningContainer from "../../../../pages/NewAdsCampaign/components/Common/ReasoningContainer";

const platform = META;
const grid = {
  xs: 12,
  lg: 6,
};

const CampaignMetaForm = () => {
  const [pixels, setPixels] = useState([]);

  const {
    control,
    formState,
    watch,
    setValue,
    isGeneralDisabledFields,
    getIsCampaignPlatformCreated,
    getValues,
  } = useFormContext();
  useFocusErrorForm(formState);
  const errors = formState.errors;

  const { t } = useTranslationApp();

  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);

  const basicPath = `platformsData.${platform}`;
  const platformData = watch(basicPath) || {};
  const objective = platformData?.objective;
  const destinationType = platformData?.destinationType;
  const adsGroups = watch("adsGroups") || [];
  const reasoningPlatform = platformData?.reasoning;
  const oldReasoning = getValues("reasoning");

  const pixelSelected = _.isEmpty(pixels)
    ? {}
    : pixels.find((p) => p.id === platformData?.trackingID);

  const onChangeDestinationType = (value) => {
    adsGroups.forEach((adGroup, index) => {
      if (adGroup.platform !== META) return;
      const ads = adGroup?.ads || [];

      ads.forEach((ad, indexAd) => {
        const currentCallToAction = ad?.callToAction;
        const callToAction =
          value === ADS_CAMPAIGN_META_DESTINATION_TYPE_INSTAGRAM_PROFILE_OPTION
            ? "VIEW_INSTAGRAM_PROFILE"
            : currentCallToAction === "VIEW_INSTAGRAM_PROFILE"
            ? ""
            : currentCallToAction;
        setValue(
          `adsGroups.[${index}].ads.[${indexAd}].callToAction`,
          callToAction
        );
      });
    });
  };

  return (
    <Grid container spacing={2}>
      {!isCampaignPlatformCreated && (
        <Grid item xs={12}>
          <ReasoningContainer reasoning={reasoningPlatform || oldReasoning} />
        </Grid>
      )}

      <Grid item {...grid}>
        <Select
          label={t(OBJECTIVE_PLACEHOLDER)}
          options={optionsAdsCampaignObjectives}
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
          name={`${basicPath}.objective`}
          actionsChange={() => {
            setValue(
              `${basicPath}.destinationType`,
              ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION
            );
          }}
          variant="filled"
          fullWidth
          doTranslate={true}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectBusinessAccount
          variant="filled"
          disabled={
            Boolean(platformData?.adAccountID) || isGeneralDisabledFields
          }
          label={t(AD_ACCOUNT_PLATFORMS_LABELS[platform])}
          control={control}
          platform={platform}
          name={`${basicPath}.adAccountID`}
          onChangeAction={(value) => {
            setValue(`${basicPath}.integrationID`, value?.integrationID);
            setValue(`${basicPath}.trackingID`, "");
            setValue(`${basicPath}.trackingCustomEventID`, "");
            setValue(`${basicPath}.pageID`, "");
            setValue(`${basicPath}.instagramID`, "");
            setValue(`${basicPath}.whatsappBusinessNumber`, {});
            setValue(`${basicPath}.instantForm`, {});
          }}
          errors={errors}
          fullWidth={true}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectPageFacebook
          variant="filled"
          disabled={
            isCampaignPlatformCreated ||
            !platformData?.adAccountID ||
            isGeneralDisabledFields
          }
          label={t(PAGE_FACEBOOK_PLACEHOLDER)}
          name={`${basicPath}.pageID`}
          integrationID={platformData?.integrationID}
          adAccountID={platformData?.adAccountID}
          control={control}
          platform={platform}
          errors={errors}
          fullWidth={true}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectInstagramAccount
          variant="filled"
          disabled={
            isCampaignPlatformCreated ||
            !platformData?.pageID ||
            isGeneralDisabledFields
          }
          label={t(INSTAGRAM_ACCOUNT_PLACEHOLDER)}
          name={`${basicPath}.instagramID`}
          integrationID={platformData?.integrationID}
          pageID={platformData?.pageID}
          adAccountID={platformData?.adAccountID}
          control={control}
          errors={errors}
          platform={platform}
          fullWidth={true}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      {ADS_CAMPAIGN_OBJECTIVES_META_WITH_DESTINATION_TYPE.includes(
        objective
      ) && (
        <Grid item {...grid}>
          <SelectDestinationTypeMeta
            label={META_DESTINATION_TYPE_PLACEHOLDER}
            name={`${basicPath}.destinationType`}
            objective={objective}
            variant="filled"
            fullWidth={true}
            disabled={isCampaignPlatformCreated}
            control={control}
            errors={errors}
            actionsChange={(value) => onChangeDestinationType(value)}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
            }}
          />
        </Grid>
      )}
      {destinationType ===
        ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION && (
        <Grid item {...grid}>
          <SelectWhatsappBusinessNumber
            label={t(WHATSAPP_BUSINESS_NUMBER_PLACEHOLDER)}
            name={`${basicPath}.whatsappBusinessNumber`}
            integrationID={platformData?.integrationID}
            disabled={isCampaignPlatformCreated}
            fullWidth
            variant="filled"
            control={control}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
            }}
          />
        </Grid>
      )}
      {ADS_CAMPAIGN_META_DESTINATION_TYPES_WITH_MESSAGE_TEMPLATE.includes(
        destinationType
      ) && (
        <Grid item {...grid}>
          <MessageTemplateContainer
            label={t(WELCOME_MESSAGE_TEMPLATE_PLACEHOLDER)}
            name={`${basicPath}.messageTemplate`}
            disabled={isCampaignPlatformCreated}
            fullWidth
            control={control}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: t(FIELD_REQUIRED),
              },
            }}
          />
        </Grid>
      )}
      {objective === ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION &&
        destinationType ===
          ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION && (
          <Grid item {...grid}>
            <InstantFormContainer
              label={t(INSTANT_FORM_PLACEHOLDER)}
              name={`${basicPath}.instantForm`}
              integrationID={platformData?.integrationID}
              pageID={platformData?.pageID}
              disabled={
                isCampaignPlatformCreated ||
                !platformData?.pageID ||
                !platformData?.integrationID
              }
              fullWidth
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
      {ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective) &&
        destinationType ===
          ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION && (
          <Grid item {...grid}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: 1,
              }}
            >
              <SelectTrackingIDField
                name={`${basicPath}.trackingID`}
                disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
                label={t(LABELS_TRACKING_ID[platform])}
                fullWidth
                variant="filled"
                platform={platform}
                integrationID={platformData?.integrationID}
                adAccountID={platformData?.adAccountID}
                onLoadOptions={(newPixels) => {
                  setPixels(newPixels);
                }}
                onChangeAction={() => {
                  setValue(`${basicPath}.trackingCustomEventID`, "");
                }}
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                }}
              />
              <TooltipModal
                sx={{
                  mt: 0.5,
                }}
                title={t(ADS_CAMPAIGNS_PIXELS_TITLE_EXPLANATION)}
                content={
                  <ContentCommon
                    text={ADS_CAMPAIGNS_PIXELS_DESCRIPTION_EXPLANATION}
                  />
                }
              />
            </Box>
          </Grid>
        )}

      {ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(objective) &&
        destinationType ===
          ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION && (
          <Grid item {...grid}>
            <SelectCustomEventAds
              label={t(LABELS_TRACKING_CUSTOM_EVENTS[platform])}
              name={`${basicPath}.trackingCustomEventID`}
              platform={platform}
              variant="filled"
              disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
              integrationID={platformData?.integrationID}
              adAccountID={platformData?.adAccountID}
              pixelSelected={pixelSelected}
              objective={objective}
              control={control}
              errors={errors}
              fullWidth={true}
              rules={{
                required: {
                  value: true,
                  message: t(FIELD_REQUIRED),
                },
              }}
            />
          </Grid>
        )}
    </Grid>
  );
};

export default CampaignMetaForm;
