import { useContext, useEffect, useState } from "react";
import { ADS_CAMPAIGN_STATUS_DRAFT } from "../../utils/constants";
import { closeChat, openChat } from "../../actions/chat";
import { useTranslationApp } from "../../lib/i18next";
import { ChatContext } from "../../contexts/ChatContext";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDispatchApp, useSelectorApp } from "../../lib/redux";
import { getPathDatabaseByRole } from "../../actions/auth";
import { useLocation, useSearchParams } from "react-router-dom";
import { getChatUnread } from "../../services/chats";
import { BUTTON_SUPPORT } from "../../i18n/keysTranslations";
import { BOX_SHADOW } from "../../utils/colors";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Button from "../Buttons/Button";
import ChatMainContainer from "./ChatMainContainer";

const OpenChatButton = () => {
  const [, setHasChatUnread] = useState(false);

  const isOpen = useSelectorApp((state) => state.chat.isOpen);
  const showOpenChatButton = useSelectorApp(
    (state) => state.ui.showOpenChatButton
  );
  const adsCampaigns = useSelectorApp(
    (state) => state.adsCampaigns?.adsCampaigns
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatchApp();
  const { userID, role, isUserPlatform } = dispatch(getPathDatabaseByRole());
  const location = useLocation();
  const [params] = useSearchParams();
  const theme = useTheme();
  const campaignID = params.get("campaignID");
  const adsCampaign = adsCampaigns?.[campaignID] || {};
  const { t } = useTranslationApp();

  const { chats } = useContext(ChatContext);

  useEffect(() => {
    const newChatsUnread = [];
    let hasOneChatUnread = false;

    Object.keys(chats || {}).forEach((chatID) => {
      const chat = chats[chatID];

      const isChatUnRead = getChatUnread({
        chat,
        userID,
      });

      if (isChatUnRead) {
        newChatsUnread.push(chatID);
        hasOneChatUnread = true;
      }
    });

    setHasChatUnread(hasOneChatUnread);

    // eslint-disable-next-line
  }, [chats]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const onOpenChat = () => {
    dispatch(openChat());
  };
  const onCloseChat = () => dispatch(closeChat());

  let SHOW_BUTTON = true;
  if (!role) {
    SHOW_BUTTON = false;
  }

  if (!showOpenChatButton || !isUserPlatform) return null;

  const isHidden =
    (location.pathname === "/ads-campaign" &&
      adsCampaign?.status === ADS_CAMPAIGN_STATUS_DRAFT) ||
    (isMobile && location.pathname === "/creators") ||
    location.pathname === "/ads-campaign-statistics" ||
    location.pathname === "/ads-campaign";

  return (
    <>
      {SHOW_BUTTON && !isOpen ? (
        <Tooltip title={"Chat"}>
          <Box
            className={""}
            sx={{
              display: isHidden ? "none" : "flex",
              position: "fixed",
              bottom: 25,
              right: 20,
              zIndex: 800,
            }}
          >
            <Button
              color="secondary"
              onClick={() => {
                onOpenChat("click");
              }}
              sx={{
                height: 30,
                maxHeight: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                fontSize: "0.975rem",
                px: { xs: 1.5, sm: 3 },
                py: { xs: 3, sm: 3 },
                boxShadow: BOX_SHADOW,
                backgroundColor: "background.paper",
                color: "secondary.main",
                border: `3px solid ${theme.palette.secondary.main}`,
                "&:hover": {
                  backgroundColor: "background.paper",
                  color: "secondary.main",
                  filter: theme.palette.hover.main,
                },
                ".MuiSvgIcon-root": {
                  fontSize: isMobile ? "1.8rem" : "1.5rem",
                },
              }}
            >
              <HelpIcon />
              {!isMobile && t(BUTTON_SUPPORT)}
            </Button>
          </Box>
        </Tooltip>
      ) : null}

      {isOpen && (
        <ChatMainContainer showChat={isOpen} onChangeShowChat={onCloseChat} />
      )}
    </>
  );
};

export default OpenChatButton;
