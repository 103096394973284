import { z } from "zod";
import { adGroupMetaBaseSchema } from "./adGroupMetaSchema";
import { adGroupGoogleBaseSchema } from "./adGroupGoogleSchema";
import { adGroupTiktokBaseSchema } from "./adGroupTikTokSchema";
import {
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  GOOGLE,
  META,
  TIKTOK,
} from "../../../../../utils/constants";
import i18next from "i18next";
import {
  FIELD_END_DATE_LESS_THAN_NOW,
  FIELD_KEYWORDS_REPEATED,
  FIELD_MIN_DAILY_BUDGET,
  FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR,
  FIELD_REQUIRED,
  FIELD_START_DATE_LESS_THAN_NOW,
  START_DATE_AND_END_DATE_VALIDATION,
} from "../../../../../i18n/keysTranslations";
import { getDuplicateItemsArray } from "../../../../../utils/array";
import { differenceInDays, endOfDay, startOfDay } from "date-fns";

const adGroupBaseSchema = z.discriminatedUnion("platform", [
  adGroupMetaBaseSchema,
  adGroupGoogleBaseSchema,
  adGroupTiktokBaseSchema,
]);

export const adGroupSchema = adGroupBaseSchema.superRefine((data, ctx) => {
  if (data.platform === GOOGLE) {
    const { keywords } = data;
    if (!keywords || keywords.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["keywords"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }

    const duplicates = getDuplicateItemsArray(keywords);
    if (duplicates.length > 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["keywords"],
        message: i18next.t(FIELD_KEYWORDS_REPEATED, {
          value: duplicates.join(", "),
        }),
      });
    }
  } else if (data.platform === TIKTOK) {
    const numericBudget = Number(data.budget);
    if (isNaN(numericBudget) || !numericBudget) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["budget"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }

    if (data.typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION) {
      if (numericBudget <= 1.5) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["budget"],
          message: i18next.t(FIELD_MIN_DAILY_BUDGET, { value: 1.5 }),
        });
      }
    }

    if (data.typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION) {
      if (!data.startDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["startDate"],
          message: i18next.t(FIELD_REQUIRED),
        });
      }
      if (!data.endDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["endDate"],
          message: i18next.t(FIELD_REQUIRED),
        });
      }

      if (data.startDate && data.endDate) {
        const startDate = startOfDay(new Date(data.startDate));
        const endDate = endOfDay(new Date(data.endDate));
        const now = new Date();

        if (startDate.getTime() < startOfDay(now).getTime()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["startDate"],
            message: i18next.t(FIELD_START_DATE_LESS_THAN_NOW),
          });
        }
        if (endDate.getTime() < now.getTime()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["endDate"],
            message: i18next.t(FIELD_END_DATE_LESS_THAN_NOW),
          });
        }
        if (startDate.getTime() > endDate.getTime()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["startDate"],
            message: i18next.t(START_DATE_AND_END_DATE_VALIDATION),
          });
        }

        const diffDays = differenceInDays(endDate, startDate);
        if (diffDays > 0) {
          const dailyBudget = numericBudget / diffDays;
          if (dailyBudget < 1.5) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["budget"],
              message: i18next.t(FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR, {
                value: Math.ceil(diffDays * 1.5),
              }),
            });
          }
        }
      }
    }

    if (data.genders) {
      const { male = false, female = false } = data.genders;
      if (!male && !female) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["genders"],
          message: i18next.t(FIELD_REQUIRED),
        });
      }
    } else {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["genders"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }

    if (!data.locations || data.locations.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["locations"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }
  } else if (data.platform === META) {
    const numericBudget = Number(data.budget);
    if (isNaN(numericBudget) || !numericBudget) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["budget"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }

    if (data.typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION) {
      if (numericBudget <= 1.5) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["budget"],
          message: i18next.t(FIELD_MIN_DAILY_BUDGET, { value: 1.5 }),
        });
      }
    }

    if (data.typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION) {
      if (!data.startDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["startDate"],
          message: i18next.t(FIELD_REQUIRED),
        });
      }
      if (!data.endDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["endDate"],
          message: i18next.t(FIELD_REQUIRED),
        });
      }

      if (data.startDate && data.endDate) {
        const startDate = startOfDay(new Date(data.startDate));
        const endDate = endOfDay(new Date(data.endDate));
        const now = new Date();

        if (startDate.getTime() < startOfDay(now).getTime()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["startDate"],
            message: i18next.t(FIELD_START_DATE_LESS_THAN_NOW),
          });
        }
        if (endDate.getTime() < now.getTime()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["endDate"],
            message: i18next.t(FIELD_END_DATE_LESS_THAN_NOW),
          });
        }
        if (startDate.getTime() > endDate.getTime()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["startDate"],
            message: i18next.t(START_DATE_AND_END_DATE_VALIDATION),
          });
        }

        const diffDays = differenceInDays(endDate, startDate);
        if (diffDays > 0) {
          const dailyBudget = numericBudget / diffDays;
          if (dailyBudget < 1.5) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["budget"],
              message: i18next.t(FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR, {
                value: Math.ceil(diffDays * 1.5),
              }),
            });
          }
        }
      }
    }

    if (data.genders) {
      const { male = false, female = false } = data.genders;
      if (!male && !female) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["genders"],
          message: i18next.t(FIELD_REQUIRED),
        });
      }
    } else {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["genders"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }

    const customAudiencesLength = data.customAudiences?.length ?? 0;
    if (customAudiencesLength === 0) {
      if (!data.locations || data.locations.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["locations"],
          message: i18next.t(FIELD_REQUIRED),
        });
      }
    }
  }
});
