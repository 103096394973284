export const setNestedValue = ({ data, path = "", setValue }) => {
  Object.keys(data).forEach((key) => {
    const isArrayIndex = !isNaN(key);
    const keyPath = isArrayIndex ? `[${key}]` : key;
    const fullPath = path ? `${path}.${keyPath}` : key;
    const value = data[key];

    if (typeof value === "object" && value !== null) {
      setNestedValue({ data: value, path: fullPath, setValue });
    } else {
      setValue(fullPath, value);
    }
  });
};

export const replaceUndefinedWithNull = (data) => {
  if (data === undefined) return null;

  if (typeof data !== "object" || data === null) return data;

  if (Array.isArray(data)) {
    return data.map((item) => replaceUndefinedWithNull(item));
  }

  const newData = {};
  Object.keys(data).forEach((key) => {
    newData[key] = replaceUndefinedWithNull(data[key]);
  });
  return newData;
};
