import ModalBasicLayout from "../../../Modal/ModalBasicLayout";
import GenerateCampaignFormMeta from "./GenerateCampaignFormMeta";

const GenerateCampaignMeta = ({
  initialData = {},
  modalOpen,
  onCloseModal,
  campaignID,
  onCallbackSave = () => {},
}) => {
  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={async () => {
        onCloseModal();
      }}
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      sxPaper={{
        maxWidth: 750,
      }}
    >
      <GenerateCampaignFormMeta
        initialData={initialData}
        campaignID={campaignID}
        onCallbackSave={onCallbackSave}
      />
    </ModalBasicLayout>
  );
};

export default GenerateCampaignMeta;
