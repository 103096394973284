import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormContext } from "react-hook-form";
import TextField from "../../../../components/Form/TextField";
import { t } from "i18next";
import {
  WRITE_INSTRUCTIONS_TO_CREATE_YOUR_CAMPAIGN,
  ADS_CAMPAIGN_CONTEXT_PLACEHOLDER,
  ADS_CAMPAIGN_LANGUAGE_PLACEHOLDER,
  ADS_CAMPAIGN_LOCATION_EXAMPLE,
  ADS_CAMPAIGN_LOCATION_PLACEHOLDER,
  SELECT_A_OPTION,
  ADS_CAMPAIGN_PLATFORMS_PLACEHOLDER,
} from "../../../../i18n/keysTranslations";
import Select from "../../../../components/Form/Select";
import { optionsLanguage } from "../../../../utils/options";
import Typography from "@mui/material/Typography";
import AddPlatformsCampaign from "./AddPlatformsCampaign";

const SetupContainer = () => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const platforms = watch("platforms");

  return (
    <Box
      sx={{
        display: "flex",
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        maxWidth: 800,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {t(ADS_CAMPAIGN_CONTEXT_PLACEHOLDER)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="context"
            placeholder={t(WRITE_INSTRUCTIONS_TO_CREATE_YOUR_CAMPAIGN)}
            variant="filled"
            fullWidth
            multiline
            minRows={3}
            maxRows={3}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={4}>
            <Select
              name="language"
              fullWidth
              variant="filled"
              label={t(ADS_CAMPAIGN_LANGUAGE_PLACEHOLDER)}
              placeholder={t(SELECT_A_OPTION)}
              displayEmpty={false}
              showEmptyOption={true}
              doTranslate={true}
              control={control}
              errors={errors}
              options={optionsLanguage}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              name="locations"
              label={t(ADS_CAMPAIGN_LOCATION_PLACEHOLDER)}
              placeholder={t(ADS_CAMPAIGN_LOCATION_EXAMPLE)}
              variant="filled"
              fullWidth
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <AddPlatformsCampaign
              label={ADS_CAMPAIGN_PLATFORMS_PLACEHOLDER}
              filterPlatforms={platforms}
              sxButtons={{
                px: 4,
                height: 56,
                maxHeight: "unset",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SetupContainer;
