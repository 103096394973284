import { z } from "zod";
import i18next from "i18next";
import { FIELD_REQUIRED } from "../../../../../i18n/keysTranslations";
import { ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK } from "../../../../../utils/constants";

const tiktokBaseSchema = z.object({
  objective: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  integrationID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  adAccountID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  identityTikTokID: z.string().min(1, i18next.t(FIELD_REQUIRED)),

  trackingID: z.any().optional(),
  trackingCustomEventID: z.any().optional(),
});

export const tiktokCampaignSchema = tiktokBaseSchema.superRefine(
  (data, ctx) => {
    const needsTracking = ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_TIKTOK.includes(
      data.objective
    );

    if (needsTracking) {
      if (!data.trackingID) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["trackingID"],
          message: i18next.t(FIELD_REQUIRED),
        });
      }
      if (!data.trackingCustomEventID) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["trackingCustomEventID"],
          message: i18next.t(FIELD_REQUIRED),
        });
      }
    }
  }
);
