import Button from "./Button";
import { BUTTON_LINKEDIN_ACTION_ID, LINKEDIN } from "../../utils/constants";
import { useTranslationApp } from "../../lib/i18next";
import { BUTTON_CONNECT_LINKEDIN } from "../../i18n/keysTranslations";
import Avatar from "@mui/material/Avatar";
import { logoPlatform } from "../../utils/string";

const ButtonLinkedinAction = ({
  id = BUTTON_LINKEDIN_ACTION_ID,
  text = BUTTON_CONNECT_LINKEDIN,
  logo = LINKEDIN,
  sx = {},
  doTranslation = true,
}) => {
  const { t } = useTranslationApp();
  return (
    <Button id={id} disabled sx={sx}>
      <Avatar
        src={logoPlatform(logo)}
        variant="square"
        sx={{
          width: "20px",
          height: "20px",
          mr: 1,
          ".MuiAvatar-img": {
            objectFit: "contain",
          },
        }}
      />
      {doTranslation ? t(text) : text}
    </Button>
  );
};

export default ButtonLinkedinAction;
