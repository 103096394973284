import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "../../../components/Form/TextField";
import {
  BRAND_SETUP_TITLE,
  BUSINESS_DESCRIPTION_PLACEHOLDER,
  BUSINESS_NAME_PLACEHOLDER,
  BUSINESS_TEXT_STYLE_PLACEHOLDER,
  BUTTON_ADD,
  BUTTON_CHANGE_LOGO,
  BUTTON_UPLOAD_LOGO,
  FIELD_REQUIRED,
  FIELD_URL_VALID,
  LOGO,
  READY,
  SOCIAL_NETWORK_EXAMPLE,
  SOCIAL_NETWORKS_PLACEHOLDER,
  WEBSITE_URL_PLACEHOLDER,
} from "../../../i18n/keysTranslations";
import { REGEX_URL } from "../../../utils/regex";
import { useTranslationApp } from "../../../lib/i18next";
import useFocusErrorForm from "../../../hooks/useFocusErrorForm";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../utils/colors";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ContainerPaperSection from "../../../components/Containers/ContainerPaperSection";
import { useDispatchApp } from "../../../lib/redux";
import {
  startExtractBrandInformationFromUrl,
  startGetBrandInformation,
  startSaveBrandInformationFromUrl,
} from "../../../actions/adsCampaigns";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import { Skeleton, Typography } from "@mui/material";
import IconButton from "../../../components/Buttons/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CardMedia from "../../../components/Media/CardMedia";
import UploadFileButton from "../../../components/Buttons/UploadFileButton";
import {
  ALERT_ICON_TYPE_SUCCESS,
  GOOGLE_IMAGE_FORMATS,
} from "../../../utils/constants";
import { getPathDatabaseByRole } from "../../../actions/auth";
import { SimpleAlert } from "../../../components/Alerts/Alerts";
import Loader from "../../../components/Loaders/Loader";

const BrandSetup = () => {
  const [isExtracted, setIsExtracted] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const { t } = useTranslationApp();
  const {
    control,
    setValue,
    formState,
    getValues,
    handleSubmit,
    trigger,
    reset,
  } = useForm({
    websiteUrl: "",
    businessName: "",
    businessDescription: "",
    businessTextStyle: "",
    logoUrl: "",
    socialNetworks: [""],
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "socialNetworks",
  });
  useFocusErrorForm(formState);

  const errors = formState.errors;
  const dispatch = useDispatchApp();
  const { userID } = dispatch(getPathDatabaseByRole());

  const getInitialData = async () => {
    reset();
    setIsLoading(true);
    const response = await dispatch(startGetBrandInformation());
    if (response.ok) {
      const { data } = response;

      Object.keys(data || {}).forEach((key) => {
        setValue(key, data[key]);
      });

      if (
        data.businessDescription ||
        data.businessTextStyle ||
        data.socialNetworks ||
        data.websiteUrl
      ) {
        setIsExtracted(true);
      }
    }
    setIsLoading(false);
  };

  const onExtractBrandInformation = async () => {
    const isValid = await trigger("websiteUrl");
    if (!isValid) {
      return;
    }
    const websiteUrl = getValues("websiteUrl");

    setIsExtracting(true);
    const response = await dispatch(
      startExtractBrandInformationFromUrl(websiteUrl)
    );
    if (response.ok) {
      const {
        businessName = "",
        businessDescription = "",
        businessTextStyle = "",
        logoUrl = "",
        socialNetworks = [],
      } = response.data;

      setValue("businessName", businessName);
      setValue("businessDescription", businessDescription);
      setValue("businessTextStyle", businessTextStyle);
      setValue("logoUrl", logoUrl);
      setValue(
        "socialNetworks",
        socialNetworks.length > 0 ? socialNetworks : [""]
      );
    } else {
      setValue("businessName", "");
      setValue("businessDescription", "");
      setValue("businessTextStyle", "");
      setValue("logoUrl", "");
      setValue("socialNetworks", [""]);
    }
    setIsExtracting(false);
    setIsExtracted(true);
  };

  const onSubmit = async (data) => {
    setIsSaving(true);
    const response = await dispatch(startSaveBrandInformationFromUrl(data));
    if (response.ok) {
      SimpleAlert({
        title: t(READY),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
    }
    setIsSaving(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [userID]);

  return (
    <ContainerPaperSection
      title={BRAND_SETUP_TITLE}
      showTopButton={false}
      isFetching={isSaving}
      showBottomButton={isExtracted}
      buttonBottomColor="white"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
      }}
      className="platforms-connect-section"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <TextField
                name="websiteUrl"
                label={t(WEBSITE_URL_PLACEHOLDER)}
                variant="filled"
                fullWidth
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: t(FIELD_REQUIRED),
                  },
                  validate: (value) => {
                    if (value && !REGEX_URL.test(encodeURI(value))) {
                      return t(FIELD_URL_VALID);
                    }
                  },
                }}
              />
              <LoadingButton
                type="button"
                onClick={onExtractBrandInformation}
                loading={isExtracting}
                sx={{
                  borderRadius: 2,
                  background: GRADIENT_PURPLE_FUCHSIA,
                  color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
                  height: 56,
                  maxHeight: 56,
                  width: 56,
                }}
              >
                <AutoAwesomeIcon sx={{}} />
              </LoadingButton>
            </Box>
          </Grid>
          {isExtracting && <BrandFieldsSkeleton />}
          {!isExtracting && isExtracted && (
            <>
              <Grid item xs={12} lg={6}>
                <TextField
                  name="businessName"
                  label={t(BUSINESS_NAME_PLACEHOLDER)}
                  variant="filled"
                  fullWidth
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: t(FIELD_REQUIRED),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  name="businessDescription"
                  label={t(BUSINESS_DESCRIPTION_PLACEHOLDER)}
                  multiline
                  minRows={4}
                  maxRows={4}
                  variant="filled"
                  fullWidth
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: t(FIELD_REQUIRED),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  name="businessTextStyle"
                  label={t(BUSINESS_TEXT_STYLE_PLACEHOLDER)}
                  multiline
                  minRows={4}
                  maxRows={4}
                  variant="filled"
                  fullWidth
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: t(FIELD_REQUIRED),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 1,
                  }}
                >
                  {t(SOCIAL_NETWORKS_PLACEHOLDER)}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.5,
                  }}
                >
                  {fields.map((item, index) => (
                    <Box key={item.id} sx={{ display: "flex", gap: 0.5 }}>
                      <TextField
                        control={control}
                        name={`socialNetworks.${index}`}
                        placeholder={t(SOCIAL_NETWORK_EXAMPLE)}
                        errors={errors}
                        multiline
                        variant="filled"
                        fullWidth
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {fields.length > 1 && (
                          <IconButton
                            onClick={() => remove(index)}
                            type="button"
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                        <IconButton onClick={() => append("")} type="button">
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                  {fields.length === 0 && (
                    <LoadingButton
                      variant="text"
                      color="white"
                      type="button"
                      onClick={() => append()}
                    >
                      <AddIcon />
                      {t(BUTTON_ADD)}
                    </LoadingButton>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" gutterBottom>
                  {t(LOGO)}
                </Typography>
                {errors["logoURL"] && (
                  <Typography variant="body2" color="error" gutterBottom>
                    {t(errors["logoURL"].message)}
                  </Typography>
                )}
                <Controller
                  name={`logoUrl`}
                  control={control}
                  render={({ field }) => (
                    <>
                      {field.value && (
                        <CardMedia
                          url={field.value}
                          sx={{
                            objectFit: "cover",
                            borderRadius: 3,
                            height: 110,
                            width: 110,
                            mb: 1,
                          }}
                        />
                      )}
                      <UploadFileButton
                        id="upload-logo-url"
                        textUpload={BUTTON_UPLOAD_LOGO}
                        colorUpload="primary"
                        textRemove={BUTTON_CHANGE_LOGO}
                        colorRemove="primary"
                        value={field.value}
                        onChange={field.onChange}
                        pathStorage={`socialMedia/${userID}/`}
                        acceptedFormats={GOOGLE_IMAGE_FORMATS}
                        modeUpload="sync"
                        loading={isUploadingLogo}
                        onCallbackLoading={(loading) =>
                          setIsUploadingLogo(loading)
                        }
                        sx={{
                          width: 110,
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </ContainerPaperSection>
  );
};

const BrandFieldsSkeleton = () => {
  return (
    <>
      <Grid item xs={12} lg={6}>
        <Skeleton variant="rounded" height={56} width={"100%"} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Skeleton variant="rounded" height={125} width={"100%"} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Skeleton variant="rounded" height={125} width={"100%"} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Skeleton variant="rounded" height={120} width={"100%"} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Skeleton variant="rounded" height={120} width={"100%"} />
      </Grid>
    </>
  );
};

export default BrandSetup;
