import { z } from "zod";
import i18next from "i18next";
import {
  FIELD_MAX_LENGTH,
  FIELD_REQUIRED,
  FIELD_TEXT_REPEATED,
} from "../../../../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH,
  ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH,
  GOOGLE,
} from "../../../../../utils/constants";

function getIndicesMap(arrayOfStrings) {
  const map = {};
  arrayOfStrings.forEach((txt, i) => {
    if (!map[txt]) {
      map[txt] = [];
    }
    map[txt].push(i);
  });
  return map;
}

const textItemSchema = z.object({
  id: z.string().optional(),
  value: z.string().min(1, i18next.t(FIELD_REQUIRED)),
});

export const adGoogleBaseSchema = z
  .object({
    platform: z.literal(GOOGLE),
    titles: z.array(
      textItemSchema.extend({
        value: z
          .string()
          .min(1, i18next.t(FIELD_REQUIRED))
          .max(
            ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[GOOGLE],
            i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_TITLE_MAX_LENGTH[GOOGLE],
            })
          ),
      })
    ),
    descriptions: z.array(
      textItemSchema.extend({
        value: z
          .string()
          .min(1, i18next.t(FIELD_REQUIRED))
          .max(
            ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[GOOGLE],
            i18next.t(FIELD_MAX_LENGTH, {
              value: ADS_CAMPAIGN_PLATFORM_DESCRIPTION_MAX_LENGTH[GOOGLE],
            })
          ),
      })
    ),
  })
  .superRefine((data, ctx) => {
    const titlesNormalized = data.titles.map((item) =>
      item.value.trim().toLowerCase()
    );
    const titlesMap = getIndicesMap(titlesNormalized);
    Object.entries(titlesMap).forEach(([, indexes]) => {
      if (indexes.length > 1) {
        indexes.forEach((i) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["titles", i, "value"],
            message: i18next.t(FIELD_TEXT_REPEATED),
          });
        });
      }
    });

    const descriptionsNormalized = data.descriptions.map((item) =>
      item.value.trim().toLowerCase()
    );
    const duplicatedDescriptions = getIndicesMap(descriptionsNormalized);
    Object.entries(duplicatedDescriptions).forEach(([, indexes]) => {
      if (indexes.length > 1) {
        indexes.forEach((i) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["descriptions", i, "value"],
            message: i18next.t(FIELD_TEXT_REPEATED),
          });
        });
      }
    });
  });
