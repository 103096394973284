import { useState } from "react";
import {
  AD_ACCOUNT_PLATFORMS_LABELS,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  GOOGLE,
  GOOGLE_IMAGE_FORMATS,
} from "../../../../utils/constants";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  optionsAdsCampaignTypeBudgets,
  optionsCampaignsTypeGoogleAds,
} from "../../../../utils/options";
import {
  FIELD_REQUIRED,
  CAMPAIGN_TYPE_PLACEHOLDER,
  NETWORK_DISPLAY_SETTING_PLACEHOLDER,
  GOOGLE_NETWORK_SETTING_GOOGLE_SEARCH,
  GOOGLE_NETWORK_SETTING_SEARCH_NETWORK,
  GOOGLE_NETWORK_SETTING_CONTENT_NETWORK,
  GOOGLE_CONVERSION_ACTIONS_PLACEHOLDER,
  LOCATIONS_PLACEHOLDER,
  ADS_CAMPAIGN_AD_GROUP_BUDGET_INFORMATION_SUB_TITLE,
  TYPE_BUDGET_PLACEHOLDER,
  LIFETIME_BUDGET_PLACEHOLDER,
  DAILY_BUDGET_PLACEHOLDER,
  VALUE_EXPRESSED_IN,
  FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR,
  ADS_CAMPAIGN_PLATFORM_BRAND_CONFIGURATION_SUB_TITLE,
  LOGO,
  BUTTON_UPLOAD_LOGO,
  BUTTON_CHANGE_LOGO,
  IMAGES_OF_CAMPAIGN_PLACEHOLDER,
  BUSINESS_NAME_PLACEHOLDER,
} from "../../../../i18n/keysTranslations";
import Select from "../../../Form/Select";
import SelectBusinessAccount from "../../../Meta/SelectBusinessAccount";
import CheckBoxes from "../../../Form/CheckBoxes";
import SelectConversionActionsGoogle from "../../../Google/SelectConversionActionsGoogle";
import NumberField from "../../../Form/NumberField";
import { addDays, differenceInDays } from "date-fns";
import { Controller, useFormContext } from "react-hook-form";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { useTranslationApp } from "../../../../lib/i18next";
import SearchSegmentationsField from "../../../Form/SearchSegmentationsField";
import TextField from "../../../Form/TextField";
import UploadFileButton from "../../../Buttons/UploadFileButton";
import MultiContentField from "../../../Form/MultiContentField";
import Accordion from "../../../Containers/Accordion";
import CardMedia from "../../../Media/CardMedia";
import { getPathDatabaseByRole } from "../../../../actions/auth";
import { useDispatchApp } from "../../../../lib/redux";
import ReasoningContainer from "../../../../pages/NewAdsCampaign/components/Common/ReasoningContainer";

const platform = GOOGLE;
const grid = {
  xs: 12,
  lg: 6,
};

const CampaignGoogleForm = () => {
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);

  const {
    control,
    formState,
    watch,
    setValue,
    getValues,
    isGeneralDisabledFields,
    getIsCampaignPlatformCreated,
    currency,
  } = useFormContext();
  useFocusErrorForm(formState);
  const errors = formState.errors;

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();

  const basicPath = `platformsData.${platform}`;
  const platformData = watch(basicPath);
  const objective = platformData?.objective;
  const typeBudget = platformData?.typeBudget;
  const startDate = platformData?.startDate;
  const endDate = platformData?.endDate;
  const mediaUrls = platformData?.mediaUrls || [];
  const mediaData = platformData?.mediaData || [];
  const logoUrl = platformData?.logoUrl;
  const language = watch("language");
  const reasoningPlatform = platformData?.reasoning;
  const oldReasoning = getValues("reasoning");

  const { userID, pathLogoUrl, isUserPlatform } = dispatch(
    getPathDatabaseByRole()
  );

  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);

  return (
    <Grid container spacing={2}>
      {!isCampaignPlatformCreated && (
        <Grid item xs={12}>
          <ReasoningContainer reasoning={reasoningPlatform || oldReasoning} />
        </Grid>
      )}

      <Grid item {...grid}>
        <Select
          label={t(CAMPAIGN_TYPE_PLACEHOLDER)}
          options={optionsCampaignsTypeGoogleAds}
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
          name={`${basicPath}.objective`}
          variant="filled"
          fullWidth
          doTranslate={true}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectBusinessAccount
          variant="filled"
          disabled={
            Boolean(platformData?.adAccountID) || isGeneralDisabledFields
          }
          label={t(AD_ACCOUNT_PLATFORMS_LABELS[platform])}
          control={control}
          platform={platform}
          name={`${basicPath}.adAccountID`}
          onChangeAction={(value) => {
            setValue(`${basicPath}.integrationID`, value?.integrationID);
            setValue(`${basicPath}.conversionActions`, []);
          }}
          errors={errors}
          fullWidth={true}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <CheckBoxes
          name={`${basicPath}.networkSettings`}
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
          label={NETWORK_DISPLAY_SETTING_PLACEHOLDER}
          options={[
            {
              key: "targetGoogleSearch",
              label: GOOGLE_NETWORK_SETTING_GOOGLE_SEARCH,
              disabled: true,
            },
            {
              key: "targetSearchNetwork",
              label: GOOGLE_NETWORK_SETTING_SEARCH_NETWORK,
            },
            {
              key: "targetContentNetwork",
              label: GOOGLE_NETWORK_SETTING_CONTENT_NETWORK,
            },
          ]}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item {...grid}>
        <SelectConversionActionsGoogle
          label={t(GOOGLE_CONVERSION_ACTIONS_PLACEHOLDER)}
          disabled={isCampaignPlatformCreated}
          name={`${basicPath}.conversionActions`}
          integrationID={platformData?.integrationID}
          adAccountID={platformData?.adAccountID}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SearchSegmentationsField
          label={t(LOCATIONS_PLACEHOLDER)}
          name={`${basicPath}.locations`}
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE}
          mode="fetching"
          platform={platform}
          adAccountID={platformData?.adAccountID}
          integrationID={platformData?.integrationID}
          objective={objective}
          language={language}
          fullWidth
          variant="filled"
          disabled={isGeneralDisabledFields}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 900,
          }}
        >
          {t(ADS_CAMPAIGN_AD_GROUP_BUDGET_INFORMATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item {...grid}>
        <Select
          label={t(TYPE_BUDGET_PLACEHOLDER)}
          disabled={true}
          name={`${basicPath}.typeBudget`}
          actionsChange={(value) => {
            if (value === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION) {
              setValue(`${basicPath}.startDate`, null);
              setValue(`${basicPath}.endDate`, null);
            } else {
              setValue(`${basicPath}.startDate`, new Date().toISOString());
              setValue(
                `${basicPath}.endDate`,
                addDays(new Date(), 7).toISOString()
              );
            }
          }}
          fullWidth
          variant="filled"
          doTranslate={true}
          options={optionsAdsCampaignTypeBudgets}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <TextField
          label={t(
            typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION
              ? DAILY_BUDGET_PLACEHOLDER
              : LIFETIME_BUDGET_PLACEHOLDER
          )}
          name={`${basicPath}.budget`}
          fullWidth
          variant="filled"
          InputProps={{
            inputComponent: NumberField,
          }}
          helperText={
            currency
              ? t(VALUE_EXPRESSED_IN, {
                  currency: currency.toUpperCase(),
                })
              : ""
          }
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION) {
                const startDateTime = startDate
                  ? new Date(startDate)
                  : new Date();
                const endDateTime = endDate ? new Date(endDate) : new Date();

                const diffDays = differenceInDays(endDateTime, startDateTime);

                const dailyBudget = value / diffDays;

                if (dailyBudget < 1) {
                  return t(FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR, {
                    value: diffDays,
                  });
                }
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 900,
          }}
        >
          {t(ADS_CAMPAIGN_PLATFORM_BRAND_CONFIGURATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item {...grid}>
        <TextField
          name={`${basicPath}.businessName`}
          label={t(BUSINESS_NAME_PLACEHOLDER)}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <Typography variant="body1" gutterBottom>
          {t(LOGO)}
        </Typography>
        {errors["logoURL"] && (
          <Typography variant="body2" color="error" gutterBottom>
            {t(errors["logoURL"].message)}
          </Typography>
        )}
        {logoUrl && (
          <CardMedia
            url={logoUrl}
            sx={{
              objectFit: "cover",
              borderRadius: 3,
              height: 110,
              width: 110,
              mb: 1,
            }}
          />
        )}
        <Controller
          name={`${basicPath}.logoUrl`}
          control={control}
          render={({ field }) => (
            <UploadFileButton
              id="upload-logo-url"
              textUpload={BUTTON_UPLOAD_LOGO}
              colorUpload="primary"
              textRemove={BUTTON_CHANGE_LOGO}
              colorRemove="primary"
              value={field.value}
              onChange={field.onChange}
              pathStorage={`socialMedia/${userID}/`}
              pathRTDB={isUserPlatform ? pathLogoUrl : null}
              acceptedFormats={GOOGLE_IMAGE_FORMATS}
              modeUpload="sync"
              loading={isUploadingLogo}
              onCallbackLoading={(loading) => setIsUploadingLogo(loading)}
              sx={{
                width: 110,
              }}
            />
          )}
        />
      </Grid>
      <Grid item {...grid}>
        <Accordion title={t(IMAGES_OF_CAMPAIGN_PLACEHOLDER)}>
          <MultiContentField
            acceptedFormats={GOOGLE_IMAGE_FORMATS}
            mediaUrls={mediaUrls}
            showGalleryAdsPlatformsOption={false}
            propsGalleryAdsPlatforms={{
              integrationID: platformData?.integrationID,
              adAccountID: platformData?.adAccountID,
            }}
            onChange={(contents) => {
              const newMediaUrls = contents.map((content) => content.url);
              const newMediaData = contents.map((content) => content.mediaData);
              setValue(`${basicPath}.mediaUrls`, [
                ...mediaUrls,
                ...newMediaUrls,
              ]);
              setValue(`${basicPath}.mediaData`, [
                ...mediaData,
                ...newMediaData,
              ]);
            }}
            onDelete={(index) => {
              setValue(
                `${basicPath}.mediaUrls`,
                mediaUrls.filter((_, i) => i !== index)
              );
              setValue(
                `${basicPath}.mediaData`,
                mediaData.filter((_, i) => i !== index)
              );
            }}
          />
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default CampaignGoogleForm;
