import Avatar from "@mui/material/Avatar";

const AnimatedImage = ({ src, sx = {} }) => {
  return (
    <Avatar
      src={src}
      sx={{
        animation: "zoom 2s infinite",
        "@keyframes zoom": {
          "0%": { transform: "scale(0.9)" },
          "25%": { transform: "scale(1.0)" },
          "50%": { transform: "scale(1.1)" },
          "75%": { transform: "scale(1.0)" },
          "100%": { transform: "scale(0.9)" },
        },
        ".MuiAvatar-img": {
          objectFit: "contain",
        },
        ...sx,
      }}
    />
  );
};

export default AnimatedImage;
