import { useEffect, useState } from "react";
import {
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_BEHAVIORS_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_INTERESTS_TYPE,
  ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE,
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
  TIKTOK,
} from "../../../../utils/constants";
import { Controller, useFormContext } from "react-hook-form";
import useFocusErrorForm from "../../../../hooks/useFocusErrorForm";
import { useTranslationApp } from "../../../../lib/i18next";
import { Slider, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  ADS_CAMPAIGN_AD_GROUP_BUDGET_INFORMATION_SUB_TITLE,
  ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE,
  AGE_RANGE_PLACEHOLDER,
  BEHAVIORS_PLACEHOLDER,
  DAILY_BUDGET_PLACEHOLDER,
  END_DATE_PLACEHOLDER,
  FEMALES,
  FIELD_END_DATE_LESS_THAN_NOW,
  FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR,
  FIELD_REQUIRED,
  FIELD_START_DATE_LESS_THAN_NOW,
  GENDERS_PLACEHOLDER,
  INTERESTS_PLACEHOLDER,
  LIFETIME_BUDGET_PLACEHOLDER,
  LOCATIONS_PLACEHOLDER,
  LOCATIONS_TIK_TOK_EMPTY,
  MALES,
  START_DATE_AND_END_DATE_VALIDATION,
  START_DATE_PLACEHOLDER,
  TYPE_BUDGET_PLACEHOLDER,
  VALUE_EXPRESSED_IN,
} from "../../../../i18n/keysTranslations";
import Select from "../../../Form/Select";
import { addDays, differenceInDays, endOfDay, startOfDay } from "date-fns";
import {
  marksAge,
  optionsAdsCampaignTypeBudgets,
} from "../../../../utils/options";
import TextField from "../../../Form/TextField";
import NumberField from "../../../Form/NumberField";
import {
  numberRoundedToGreater,
  textNumberCompactFormat,
} from "../../../../utils/numbers";
import DateField from "../../../Form/DateField";
import { GREY_SLIDER_COLOR } from "../../../../utils/colors";
import CheckBoxes from "../../../Form/CheckBoxes";
import SearchSegmentationsField from "../../../Form/SearchSegmentationsField";
import { waitDelay } from "../../../../utils/date";

const platform = TIKTOK;
const grid = {
  xs: 12,
  lg: 6,
};
const sxVariantSubTitle = {
  fontWeight: 900,
};

const AdGroupTikTokForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    control,
    formState,
    setValue,
    watch,
    sectionSelected,
    isGeneralDisabledFields,
    getIsCampaignPlatformCreated,
    currency,
    getValues,
  } = useFormContext();
  useFocusErrorForm(formState);

  const errors = formState.errors;
  const isCampaignPlatformCreated = getIsCampaignPlatformCreated(platform);

  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const variantSubTitle = isMobile ? "h6" : "h6";

  const adsGroups = watch("adsGroups");
  const adGroupID = sectionSelected.adGroupID;
  const index = adsGroups.findIndex((item) => item.id === adGroupID);
  const BASIC_PATH = `adsGroups.[${index}]`;

  const platformData = watch(`platformsData.${platform}`) || {};
  const objective = platformData?.objective;
  const locations = watch(`${BASIC_PATH}.locations`) || [];
  const genders = watch(`${BASIC_PATH}.genders`) || {};
  const typeBudget = watch(`${BASIC_PATH}.typeBudget`) || "";
  const language = watch("language") || "";
  const startDate = watch(`${BASIC_PATH}.startDate`) || null;
  const endDate = watch(`${BASIC_PATH}.endDate`) || null;

  //To reset values component when change selectedLevel
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await waitDelay(1);
      setIsLoading(false);
    })();
  }, [sectionSelected]);

  if (isLoading) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={variantSubTitle} sx={sxVariantSubTitle}>
          {t(ADS_CAMPAIGN_AD_GROUP_BUDGET_INFORMATION_SUB_TITLE)}
        </Typography>
      </Grid>
      <Grid item {...grid}>
        <Select
          label={t(TYPE_BUDGET_PLACEHOLDER)}
          disabled={isCampaignPlatformCreated || isGeneralDisabledFields}
          name={`${BASIC_PATH}.typeBudget`}
          actionsChange={(value) => {
            if (value === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION) {
              setValue(`${BASIC_PATH}.startDate`, startOfDay(new Date()));
              setValue(
                `${BASIC_PATH}.endDate`,
                endOfDay(addDays(new Date(), 1))
              );
            }
          }}
          fullWidth
          variant="filled"
          doTranslate={true}
          options={optionsAdsCampaignTypeBudgets}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <TextField
          label={t(
            typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION
              ? DAILY_BUDGET_PLACEHOLDER
              : LIFETIME_BUDGET_PLACEHOLDER
          )}
          name={`${BASIC_PATH}.budget`}
          disabled={isGeneralDisabledFields}
          fullWidth
          variant="filled"
          InputProps={{
            inputComponent: NumberField,
          }}
          helperText={
            currency
              ? t(VALUE_EXPRESSED_IN, {
                  currency: currency.toUpperCase(),
                })
              : ""
          }
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION) {
                const startDateTime = startDate
                  ? new Date(startDate)
                  : new Date();
                const endDateTime = endDate ? new Date(endDate) : new Date();

                const diffDays = differenceInDays(endDateTime, startDateTime);

                const dailyBudget = Number(value) / diffDays;

                if (dailyBudget < 1.5) {
                  return t(FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR, {
                    value: numberRoundedToGreater(diffDays * 1.5),
                  });
                }
              }
            },
          }}
        />
      </Grid>
      {typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION && (
        <>
          <Grid item {...grid}>
            <DateField
              disabled={
                (isCampaignPlatformCreated || isGeneralDisabledFields) &&
                startDate
              }
              minDate={startOfDay(new Date())}
              name={`${BASIC_PATH}.startDate`}
              label={t(START_DATE_PLACEHOLDER)}
              actionsChange={(value) => {
                setValue(
                  `${BASIC_PATH}.startDate`,
                  value ? startOfDay(value) : null
                );
              }}
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (!value) {
                    return t(FIELD_REQUIRED);
                  }
                  const startDate = new Date(value);
                  const startOfDayValue = startOfDay(new Date());

                  if (
                    startDate.getTime() < startOfDayValue.getTime() &&
                    !isCampaignPlatformCreated
                  ) {
                    return t(FIELD_START_DATE_LESS_THAN_NOW);
                  }

                  const endDateValue = getValues(`${BASIC_PATH}.endDate`);
                  if (endDateValue) {
                    const endDate = new Date(endDateValue);
                    if (startDate.getTime() > endDate.getTime()) {
                      return t(START_DATE_AND_END_DATE_VALIDATION);
                    }
                  }
                },
              }}
            />
          </Grid>
          <Grid item {...grid}>
            <DateField
              disabled={isGeneralDisabledFields}
              name={`${BASIC_PATH}.endDate`}
              minDate={addDays(new Date(), 1)}
              actionsChange={(value) => {
                setValue(
                  `${BASIC_PATH}.endDate`,
                  value ? endOfDay(value) : null
                );
              }}
              label={t(END_DATE_PLACEHOLDER)}
              control={control}
              errors={errors}
              rules={{
                validate: (value) => {
                  if (!value) {
                    return t(FIELD_REQUIRED);
                  }
                  const endDate = new Date(value);
                  const now = new Date();
                  if (endDate.getTime() < now.getTime()) {
                    return t(FIELD_END_DATE_LESS_THAN_NOW);
                  }
                  const startDateValue = getValues(`${BASIC_PATH}.startDate`);
                  if (startDateValue) {
                    const endDate = new Date(value);
                    const startDate = new Date(startDateValue);
                    if (startDate.getTime() > endDate.getTime()) {
                      return t(START_DATE_AND_END_DATE_VALIDATION);
                    }
                  }
                },
              }}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Typography variant={variantSubTitle} sx={sxVariantSubTitle}>
          {t(ADS_CAMPAIGN_SEGMENTATION_SUB_TITLE)}
        </Typography>
      </Grid>

      <Grid item {...grid}>
        <Typography variant="body1">{t(AGE_RANGE_PLACEHOLDER)}</Typography>
        <Controller
          name={`${BASIC_PATH}.ageRange`}
          control={control}
          render={({ field }) => (
            <Slider
              sx={{
                width: "calc(100% - 24px)",
                ml: 1,
                ".MuiSlider-track": {
                  height: 2,
                },
                ".MuiSlider-rail": {
                  backgroundColor: GREY_SLIDER_COLOR,
                  height: 4,
                },
                ".MuiSlider-mark": {
                  backgroundColor: "transparent",
                },
              }}
              valueLabelDisplay="auto"
              getAriaValueText={textNumberCompactFormat}
              step={1}
              marks={marksAge}
              min={18}
              max={64}
              valueLabelFormat={textNumberCompactFormat}
              {...field}
              disabled={isGeneralDisabledFields}
            />
          )}
        />
      </Grid>
      <Grid item {...grid}>
        <CheckBoxes
          name={`${BASIC_PATH}.genders`}
          disabled={isGeneralDisabledFields}
          label={GENDERS_PLACEHOLDER}
          options={[
            {
              key: "male",
              label: MALES,
            },
            {
              key: "female",
              label: FEMALES,
            },
          ]}
          control={control}
          errors={errors}
          rules={{
            validate: () => {
              if (!Object.values(genders).includes(true)) {
                return t(FIELD_REQUIRED);
              }
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SearchSegmentationsField
          label={t(LOCATIONS_PLACEHOLDER)}
          name={`${BASIC_PATH}.locations`}
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_LOCATIONS_TYPE}
          mode="fetching"
          platform={platform}
          adAccountID={platformData?.adAccountID}
          integrationID={platformData?.integrationID}
          objective={objective}
          language={language}
          fullWidth
          variant="filled"
          disabled={isGeneralDisabledFields}
          helperText={
            locations.length === 0 || !locations
              ? t(LOCATIONS_TIK_TOK_EMPTY)
              : ""
          }
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SearchSegmentationsField
          label={t(INTERESTS_PLACEHOLDER)}
          name={`${BASIC_PATH}.interests`}
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_INTERESTS_TYPE}
          mode="fetching"
          platform={platform}
          adAccountID={platformData?.adAccountID}
          integrationID={platformData?.integrationID}
          objective={objective}
          language={language}
          fullWidth
          variant="filled"
          disabled={isGeneralDisabledFields}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
      <Grid item {...grid}>
        <SearchSegmentationsField
          label={t(BEHAVIORS_PLACEHOLDER)}
          name={`${BASIC_PATH}.behaviors`}
          type={ADS_CAMPAIGN_SEARCH_SEGMENTATION_BEHAVIORS_TYPE}
          mode={"fetching"}
          groupBy={["path", 0]}
          platform={platform}
          adAccountID={platformData?.adAccountID}
          integrationID={platformData?.integrationID}
          objective={objective}
          language={language}
          fullWidth
          variant="filled"
          disabled={isGeneralDisabledFields}
          control={control}
          errors={errors}
          rules={{
            required: {
              value: false,
              message: t(FIELD_REQUIRED),
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AdGroupTikTokForm;
