import Accordion from "../../../../components/Containers/Accordion";
import { useTranslationApp } from "../../../../lib/i18next";
import { WHY_THESE_RECOMMENDED_SETTINGS } from "../../../../i18n/keysTranslations";
import { BORDER_RADIUS } from "../../../../utils/constants";
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import TypingText from "../../../../components/Texts/TypingText";

const ReasoningContainer = ({
  reasoning,
  onCallbackFinishReasoning = () => {},
}) => {
  const { t } = useTranslationApp();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const commonSxAccordion = {
    border: `1px solid ${theme.palette.background.paperSecondary}`,
    borderRadius: BORDER_RADIUS,
    "::before": {
      backgroundColor: "transparent",
    },
    m: "0 !important",
    mb: "16px !important",
  };

  if (!reasoning) {
    return null;
  }
  return (
    <Accordion
      initialValue={true}
      title={t(WHY_THESE_RECOMMENDED_SETTINGS)}
      variantTitle={isMobile ? "body1" : "h6"}
      sx={commonSxAccordion}
      sxTitle={{
        fontWeight: 900,
      }}
      sxContainerTitle={{
        ".MuiAccordionSummary-content": {
          display: "flex",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        },
        ".MuiAccordionSummary-expandIconWrapper": {},
        pt: 0,
      }}
      minHeight={64}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          height: "auto",
        }}
      >
        {reasoning ? (
          <TypingText
            text={reasoning}
            onCallbackFinish={() => {
              onCallbackFinishReasoning();
            }}
            sx={{
              height: 200,
            }}
          />
        ) : null}
      </Box>
    </Accordion>
  );
};

export default ReasoningContainer;
