import i18next from "i18next";
import { z } from "zod";
import { FIELD_REQUIRED } from "../../../../../i18n/keysTranslations";
import { TIKTOK } from "../../../../../utils/constants";
import { adTikTokBaseSchema } from "../ad/adTikTokSchema";

export const adGroupTiktokBaseSchema = z.object({
  ads: z.array(adTikTokBaseSchema).min(1, i18next.t(FIELD_REQUIRED)),
  platform: z.literal(TIKTOK),
  typeBudget: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  budget: z.any(),

  startDate: z.any().optional(),
  endDate: z.any().optional(),
  ageRange: z.tuple(
    [
      z.number().min(18, i18next.t(FIELD_REQUIRED)),
      z.number().max(64, i18next.t(FIELD_REQUIRED)),
    ],
    {
      required_error: i18next.t(FIELD_REQUIRED),
    }
  ),
  genders: z.object(
    {
      male: z.boolean().optional(),
      female: z.boolean().optional(),
    },
    {
      required_error: i18next.t(FIELD_REQUIRED),
    }
  ),
  locations: z.array(z.any()).optional(),
  interests: z.array(z.any()).optional(),
  behaviors: z.array(z.any()).optional(),
});
