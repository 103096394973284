import { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import ReactMarkdown from "react-markdown";

const TypingText = ({
  text,
  typingDelay = 5,
  onCallbackFinish = () => {},
  sx = {},
}) => {
  const [introArray, setIntroArray] = useState([]);
  const [autoScrollIsActive, setAutoScrollIsActive] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const starterArray = text?.split("\n");

    setIntroArray([]);

    const createTypingEffect = async (text, index) => {
      const typeCharacter = (char, i) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            setIntroArray((prevArray) => {
              const newArray = [...prevArray];
              newArray[index] += char;
              return newArray;
            });
            resolve();
          }, typingDelay * i);
        });
      };

      const typingPromises = text.split("").map(typeCharacter);
      return Promise.all(typingPromises);
    };

    const cycleThroughLines = async () => {
      for (let i = 0; i < starterArray.length; i++) {
        setIntroArray((prevArray) => [...prevArray, ""]);
        await createTypingEffect(starterArray[i], i);
      }

      onCallbackFinish();
    };

    cycleThroughLines();
    // eslint-disable-next-line
  }, [text, typingDelay]);

  useEffect(() => {
    if (containerRef.current && autoScrollIsActive) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [introArray, autoScrollIsActive]);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      // Disable auto-scroll if the user scrolls up
      if (scrollTop + clientHeight >= scrollHeight) {
        setAutoScrollIsActive(true); // Reactivate auto-scroll
      } else {
        setAutoScrollIsActive(false); // Disable auto-scroll when scrolling up
      }
    }
  };

  const handleUserInteraction = () => {
    setAutoScrollIsActive(false);
  };
  return (
    <Typography
      component="div"
      variant="body2"
      onScroll={handleScroll}
      onMouseDown={handleUserInteraction}
      onTouchStart={handleUserInteraction}
      ref={containerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        ...sx,
      }}
    >
      {introArray.map((introLine, index) => (
        <ReactMarkdown
          key={index}
          components={{
            h1: ({ ...props }) => (
              <h1
                {...props}
                style={{
                  margin: "8px",
                  fontSize: "revert",
                }}
              />
            ),
            h2: ({ ...props }) => (
              <h2
                {...props}
                style={{
                  margin: "8px",
                  fontSize: "revert",
                }}
              />
            ),
            h3: ({ ...props }) => (
              <h3
                {...props}
                style={{
                  margin: "8px",
                  fontSize: "revert",
                }}
              />
            ),
            h4: ({ ...props }) => (
              <h4
                {...props}
                style={{
                  margin: "8px",
                  fontSize: "revert",
                }}
              />
            ),
            h5: ({ ...props }) => (
              <h5
                {...props}
                style={{
                  margin: "8px",
                  fontSize: "revert",
                }}
              />
            ),
            h6: ({ ...props }) => (
              <h6
                {...props}
                style={{
                  margin: "8px",
                  fontSize: "revert",
                }}
              />
            ),
            p: ({ ...props }) => (
              <p
                {...props}
                style={{
                  margin: "8px",
                  fontSize: "revert",
                }}
              />
            ),
            pre: ({ ...props }) => (
              <pre
                {...props}
                style={{
                  margin: "8px",
                  fontSize: "revert",
                }}
              />
            ),
            ul: ({ ...props }) => (
              <ul
                {...props}
                style={{
                  fontSize: "revert",
                  margin: "revert",
                  padding: "revert",
                }}
              />
            ),
          }}
        >
          {introLine}
        </ReactMarkdown>
      ))}
    </Typography>
  );
};

export default TypingText;
