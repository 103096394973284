import i18next from "i18next";
import { z } from "zod";
import {
  FIELD_MIN_DAILY_BUDGET,
  FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR,
  FIELD_REQUIRED,
} from "../../../../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION,
  ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION,
} from "../../../../../utils/constants";
import { differenceInDays } from "date-fns";

const googleBaseSchema = z.object({
  objective: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  integrationID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  adAccountID: z.string().min(1, i18next.t(FIELD_REQUIRED)),

  locations: z.array(z.any()).optional(),
  typeBudget: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  budget: z.any(),
  businessName: z.string().min(1, i18next.t(FIELD_REQUIRED)),

  conversionActions: z.array(z.any()).optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  logoUrl: z.string().optional(),
  mediaUrls: z.array(z.string()).optional(),
  mediaData: z.array(z.any()).optional(),
  networkSettings: z
    .object({
      targetGoogleSearch: z.boolean().optional(),
      targetSearchNetwork: z.boolean().optional(),
      targetContentNetwork: z.boolean().optional(),
    })
    .optional(),
});

export const googleCampaignSchema = googleBaseSchema.superRefine(
  (data, ctx) => {
    const numericBudget = Number(data.budget);
    if (isNaN(numericBudget) || !numericBudget) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["budget"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }

    if (data.typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_DAILY_OPTION) {
      if (numericBudget <= 1.5) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["budget"],
          message: i18next.t(FIELD_MIN_DAILY_BUDGET, { value: 1.5 }),
        });
      }
    }

    const isLifetime =
      data.typeBudget === ADS_CAMPAIGN_TYPE_BUDGET_LIFETIME_OPTION;

    if (isLifetime) {
      const startDate = data.startDate ? new Date(data.startDate) : null;
      const endDate = data.endDate ? new Date(data.endDate) : null;

      if (startDate && endDate) {
        const diffDays = differenceInDays(endDate, startDate);

        if (diffDays > 0) {
          const dailyBudget = data.budget / diffDays;
          if (dailyBudget < 1.5) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["budget"],
              message: i18next.t(FIELD_MIN_TOTAL_BUDGET_ONE_DOLLAR, {
                value: diffDays,
              }),
            });
          }
        }
      }
    }

    if (!data.locations || data.locations.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["locations"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }
  }
);
