import { z } from "zod";
import i18next from "i18next";
import { FIELD_REQUIRED } from "../../../../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION,
  ADS_CAMPAIGN_META_DESTINATION_TYPES_WITH_MESSAGE_TEMPLATE,
  ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION,
  ADS_CAMPAIGN_OBJECTIVES_META_WITH_DESTINATION_TYPE,
  ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META,
} from "../../../../../utils/constants";

const metaBaseSchema = z.object({
  objective: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  integrationID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  adAccountID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  pageID: z.string().min(1, i18next.t(FIELD_REQUIRED)),
  instagramID: z.string().min(1, i18next.t(FIELD_REQUIRED)),

  destinationType: z.string().optional(),
  whatsappBusinessNumber: z.any().optional(),
  messageTemplate: z.any().optional(),
  instantForm: z.any().optional(),

  trackingID: z.any().optional(),
  trackingCustomEventID: z.any().optional(),
});

export const metaCampaignSchema = metaBaseSchema.superRefine((data, ctx) => {
  if (
    ADS_CAMPAIGN_OBJECTIVES_META_WITH_DESTINATION_TYPE.includes(data.objective)
  ) {
    if (!data.destinationType) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["destinationType"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }
  }

  if (
    data.destinationType === ADS_CAMPAIGN_META_DESTINATION_TYPE_WHATSAPP_OPTION
  ) {
    if (!data.whatsappBusinessNumber) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["whatsappBusinessNumber"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }
  }

  if (
    ADS_CAMPAIGN_META_DESTINATION_TYPES_WITH_MESSAGE_TEMPLATE.includes(
      data.destinationType ?? ""
    )
  ) {
    if (!data.messageTemplate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["messageTemplate"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }
  }

  const isLeads = data.objective === ADS_CAMPAIGN_OBJECTIVE_LEADS_OPTION;
  const isInstantForm =
    data.destinationType ===
    ADS_CAMPAIGN_META_DESTINATION_TYPE_LEAD_INSTANT_FORM_OPTION;
  if (isLeads && isInstantForm) {
    if (!data.instantForm) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["instantForm"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }
  }

  const needsTracking = ADS_CAMPAIGN_OBJECTIVES_WITH_TRACKING_META.includes(
    data.objective
  );
  const isWebsite =
    data.destinationType === ADS_CAMPAIGN_META_DESTINATION_TYPE_WEBSITE_OPTION;
  if (needsTracking && isWebsite) {
    if (!data.trackingID) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["trackingID"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }
    if (!data.trackingCustomEventID) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["trackingCustomEventID"],
        message: i18next.t(FIELD_REQUIRED),
      });
    }
  }
});
