import Box from "@mui/material/Box";
import BottomBar from "./BottomBar";
import MainContent from "./MainContent";
import EditableText from "../../../../components/Texts/EditableText";
import { useFormContext } from "react-hook-form";
import SwitchStatusItem from "../Common/SwitchStatusItem";
import Button from "../../../../components/Buttons/Button";
import Typography from "@mui/material/Typography";
import {
  BUTTON_TRY_AGAIN,
  ERROR_GENERATING_CAMPAIGN,
} from "../../../../i18n/keysTranslations";
import { useTranslationApp } from "../../../../lib/i18next";

const GeneralContainer = () => {
  const {
    setValue,
    watch,
    levelSectionSelected,
    sectionSelected,
    getShowActionsPlatform,
    onGenerateCampaignPlatform,
    showErrorGenerating,
    lastInformationToGenerate,
  } = useFormContext();
  const { t } = useTranslationApp();
  const showActionsPlatform = getShowActionsPlatform(sectionSelected.platform);

  const name = watch("name");
  const adsGroups = watch("adsGroups");

  if (showErrorGenerating) {
    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography variant="h5">{t(ERROR_GENERATING_CAMPAIGN)}</Typography>
        <Button
          onClick={() => onGenerateCampaignPlatform(lastInformationToGenerate)}
        >
          {t(BUTTON_TRY_AGAIN)}
        </Button>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent:
            name && levelSectionSelected === "platform"
              ? "space-between"
              : "flex-end",
          zIndex: 1100,
          mt: -1.5,
          mx: 3,
        }}
      >
        {name && levelSectionSelected === "platform" && (
          <EditableText
            value={name}
            variantText="h5"
            onCallbackSave={(newValue) => setValue("name", newValue)}
            sx={{
              maxWidth: 500,
            }}
            sxText={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          />
        )}
        {showActionsPlatform && (
          <SwitchStatusItem
            selectedSection={sectionSelected}
            hasLabel={true}
            forceRefreshForSelectedSection={true}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "unset",
              mr: 2,
            }}
          />
        )}
      </Box>
      <MainContent />

      {adsGroups?.length > 0 && <BottomBar />}
    </Box>
  );
};

export default GeneralContainer;
