import ContainerPage from "../../components/Containers/ContainerPage";
import TitlePage from "../../components/Texts/TitlePage";
import { useTranslationApp } from "../../lib/i18next";
import { CONFIGURATION_TITLE } from "../../i18n/keysTranslations";
import ConnectedAccounts from "./components/ConnectedAccounts";
import SetupBrand from "./components/BrandSetup";

const Configuration = () => {
  const { t } = useTranslationApp();
  return (
    <ContainerPage mode="padding" hasMarginBottom={true} sx={{}}>
      <TitlePage>{t(CONFIGURATION_TITLE)}</TitlePage>
      <SetupBrand />
      <ConnectedAccounts />
    </ContainerPage>
  );
};

export default Configuration;
