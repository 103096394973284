import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";

const AnswerContainer = ({ children, onNextCallback = () => {} }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <div
      style={{
        width: "100%",
        height: isMobile ? "100%" : "fit-content",
        maxWidth: 600,
      }}
      className={"animate__animated animate__fadeInUp animate__faster"}
      onKeyUp={(e) => {
        if (e.code === "Enter") {
          onNextCallback();
        }
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          width: "100%",
          height: "100%",
          maxWidth: 600,
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default AnswerContainer;
