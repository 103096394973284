import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import Accordion from "../../../../components/Containers/Accordion";
import {
  AN_ERROR_OCCURRED_GETTING_THE_REPORT,
  ANALYZING_CAMPAIGN_STATISTICS,
  APPLYING_OPTIMIZATIONS,
  BUTTON_APPLY_OPTIMIZATIONS,
  CAMPAIGN_OPTIMIZED,
  COMPARING_RESULTS_OBTAINED,
  CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS,
  CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION,
  ERROR_PROCESSING_CAMPAIGNS,
  GENERATING_OPTIMIZATIONS,
  HOW_IS_YOUR_CAMPAIGN_GOING,
} from "../../../../i18n/keysTranslations";
import {
  ADS_CAMPAIGN_OPTIMIZE_CAMPAIGN_ACTION_ID,
  ADS_CAMPAIGN_STATUS_ACTIVE,
  ADS_CAMPAIGN_STATUS_DEACTIVATED,
  ADS_OPTIMIZATION_AD_GROUPS_BUDGET_TYPE,
  ADS_OPTIMIZATION_CAMPAIGN_ACTION,
  ADS_OPTIMIZATION_KEYWORDS_TYPE,
  ADS_OPTIMIZATION_TYPES,
  ALERT_ICON_TYPE_SUCCESS,
  BORDER_RADIUS,
  TIME_LOADER_GENERATING_REPORT_CAMPAIGN,
  TIME_LOADER_OPTIMIZE_CAMPAIGN,
} from "../../../../utils/constants";
import LinearLoaderWithTime from "../../../../components/Loaders/LinearLoaderWithTime";
import TypingText from "../../../../components/Texts/TypingText";
import {
  GRADIENT_PURPLE_FUCHSIA,
  GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
} from "../../../../utils/colors";
import LoadingButton from "../../../../components/Buttons/LoadingButton";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDispatchApp } from "../../../../lib/redux";
import { useTranslationApp } from "../../../../lib/i18next";
import { useFormContext } from "react-hook-form";
import {
  startGetCampaignReportState,
  startSaveAdsCampaign,
  startSaveReportOptimizationsDone,
  startVerifyAndSetAdsUsage,
} from "../../../../actions/adsCampaigns";
import {
  getCampaignUpdatedByStatus,
  getGeneralStatistics,
  mapStatusFromOptimization,
} from "../../../../services/adsCampaigns";
import { setNestedValue } from "../../../../utils/forms";
import _ from "lodash";
import { getIsBlockedActions } from "../../../../actions/getters";
import { SimpleAlert } from "../../../../components/Alerts/Alerts";
import { differenceInDays, isSameDay, startOfDay } from "date-fns";

const ReportContainer = () => {
  const [allTextExplanation, setAllTextExplanation] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [
    isUpdatingCampaignByOptimization,
    setIsUpdatingCampaignByOptimization,
  ] = useState(false);
  const [hasError, setHasError] = useState(false);

  const {
    campaignID,
    isGeneralCampaignActivated,
    setValue,
    getValues,
    watch,
    onChangeDataModalErrorsCampaigns,
    onChangeModalErrorsCampaignsOpen,
  } = useFormContext();

  const theme = useTheme();
  const dispatch = useDispatchApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslationApp();

  const now = new Date();
  const report = watch("report") || {};
  const lastReportTime = report?.lastReportTime;
  const optimizationsDone = report?.optimizations?.optimizationsDone;
  const firstActiveTime = getValues("firstActiveTime");
  const statistics = getValues("statistics");
  const states = getValues("states");

  const commonSxAccordion = {
    border: `1px solid ${theme.palette.background.paperSecondary}`,
    borderRadius: BORDER_RADIUS,
    "::before": {
      backgroundColor: "transparent",
    },
    m: "0 !important",
    mb: "16px !important",
    width: "100%",
  };

  const onGenerateReport = async () => {
    setIsGenerating(true);
    setHasError(false);

    const response = await dispatch(
      startGetCampaignReportState({
        campaignID,
      })
    );
    if (response) {
      setValue("report", response);
      const explanation = getAllExplanation(response);
      setAllTextExplanation(explanation);
    } else {
      setHasError(true);
    }
    setIsGenerating(false);
  };

  const onApplyAdsGroupsBudgetsOptimization = async (platform) => {
    const campaign = getValues();
    const adsGroups = campaign.adsGroups;
    const actions = report?.optimizations?.byPlatform?.[platform]?.actions;

    let newAdsGroupsActive = 0;
    let adsGroupsDeactivatedBudget = 0;

    let indexPlatform = -1;
    adsGroups.forEach((adGroup, index) => {
      if (adGroup.platform !== platform) return;

      indexPlatform += 1;

      const adGroupOptimization = actions.find(
        (action) => action.adGroupIndex === indexPlatform
      );

      if (!adGroupOptimization) {
        if (adGroup.status === ADS_CAMPAIGN_STATUS_ACTIVE)
          newAdsGroupsActive += 1;
        return;
      }

      const newStatus = mapStatusFromOptimization(adGroupOptimization.status);
      const isDeactivatedStatus = newStatus === ADS_CAMPAIGN_STATUS_DEACTIVATED;

      if (!isDeactivatedStatus) {
        newAdsGroupsActive += 1;
        return;
      }

      const objectUpdate = getCampaignUpdatedByStatus({
        campaign,
        status: newStatus,
        target: "adGroup",
        platform: adGroup.platform,
        adGroupID: index,
      });

      adsGroupsDeactivatedBudget += Number(adGroup.budget);

      const newAdGroup = { ...objectUpdate.adsGroups[index] };
      setNestedValue({
        data: newAdGroup,
        setValue: setValue,
        path: `adsGroups.[${index}]`,
      });

      adsGroups[index] = newAdGroup;
    });

    if (adsGroupsDeactivatedBudget > 0 && newAdsGroupsActive > 0) {
      const budgetRepartition = adsGroupsDeactivatedBudget / newAdsGroupsActive;
      adsGroups.forEach((adGroup, index) => {
        if (adGroup.platform !== platform) return;

        if (adGroup.status === ADS_CAMPAIGN_STATUS_ACTIVE) {
          const newBudget = Number(adGroup.budget) + budgetRepartition;
          setValue(`adsGroups.[${index}].budget`, newBudget);
          adsGroups[index].budget = newBudget;
        }
      });
    }
  };

  const onApplyKeywordsOptimization = async (platform) => {
    const campaign = getValues();
    const adsGroups = campaign.adsGroups;
    const actions = report?.optimizations?.byPlatform?.[platform]?.actions;

    adsGroups.forEach((adGroup, index) => {
      if (adGroup.platform !== platform) return;

      const keywords = adGroup.keywords;
      if (!keywords) return;

      let newKeywords = _.cloneDeep(keywords);

      actions.forEach((action) => {
        const { keywordIndex, newKeyword, type } = action;

        if (type === "remove") {
          newKeywords[keywordIndex] = null;
        } else if (type === "replace") {
          newKeywords[keywordIndex] = newKeyword;
        }
      });

      newKeywords = newKeywords.filter((keyword) => keyword !== null);
      setValue(`adsGroups.[${index}].keywords`, newKeywords);
    });
  };

  const onApplyOptimizations = async () => {
    const isBlocked = await dispatch(
      getIsBlockedActions({
        action: ADS_OPTIMIZATION_CAMPAIGN_ACTION,
      })
    );
    if (isBlocked) {
      return;
    }

    const optimizations = report?.optimizations?.byPlatform || {};

    for (const platform in optimizations) {
      const platformOptimization = optimizations[platform];
      const optimizationType = platformOptimization?.type;
      if (!ADS_OPTIMIZATION_TYPES.includes(optimizationType)) continue;

      if (optimizationType === ADS_OPTIMIZATION_AD_GROUPS_BUDGET_TYPE) {
        await onApplyAdsGroupsBudgetsOptimization(platform);
      }
      if (optimizationType === ADS_OPTIMIZATION_KEYWORDS_TYPE) {
        await onApplyKeywordsOptimization(platform);
      }
    }

    setIsUpdatingCampaignByOptimization(true);
    const response = await dispatch(
      startSaveAdsCampaign({
        campaign: getValues(),
        campaignID,
        from: "optimization",
      })
    );
    if (response.ok) {
      await dispatch(
        startVerifyAndSetAdsUsage({
          actionID: ADS_CAMPAIGN_OPTIMIZE_CAMPAIGN_ACTION_ID,
        })
      );
      dispatch(
        startSaveReportOptimizationsDone({
          campaignID,
          optimizationsDone: true,
        })
      );
      setNestedValue({
        data: response.campaign,
        setValue: setValue,
      });
      SimpleAlert({
        title: t(CAMPAIGN_OPTIMIZED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      setValue("report.optimizations.optimizationsDone", true);
    } else if (response.code === ERROR_PROCESSING_CAMPAIGNS) {
      onChangeDataModalErrorsCampaigns({
        createdCampaigns: response?.createdCampaigns,
        failedCampaigns: response?.failedCampaigns,
        errors: response?.errors,
      });
      onChangeModalErrorsCampaignsOpen(true);
      setNestedValue({ data: response.campaign, setValue });
    }
    setIsUpdatingCampaignByOptimization(false);
  };

  const getAllExplanation = (report) => {
    let explanation = "";

    const reportStateExplanation = report?.explanation || "";
    explanation += `${reportStateExplanation}\n`;

    const optimizations = report?.optimizations?.byPlatform || {};

    if (_.isEmpty(optimizations)) return explanation;

    Object.keys(optimizations).forEach((platform) => {
      const platformOptimization = optimizations[platform];
      if (!platformOptimization?.explanation) return;

      explanation += `${platformOptimization.explanation}\n`;
    });

    return explanation;
  };

  const getShowButtonApplyOptimizations = () => {
    if (optimizationsDone || isGenerating || isUpdatingCampaignByOptimization)
      return false;

    const optimizations = report?.optimizations?.byPlatform || {};
    let hasOptimizations = false;

    Object.keys(optimizations).forEach((platform) => {
      const platformOptimization = optimizations[platform];
      const type = platformOptimization?.type;
      if (!ADS_OPTIMIZATION_TYPES.includes(type)) return;

      hasOptimizations = true;
    });

    return hasOptimizations;
  };

  useEffect(() => {
    if (!firstActiveTime) return;
    if (!statistics) return;

    const explanation = getAllExplanation(report);
    setAllTextExplanation(explanation);

    if (states.general !== ADS_CAMPAIGN_STATUS_ACTIVE || !states) {
      return;
    }

    const generalStatistics = getGeneralStatistics(statistics);

    if (!generalStatistics.impressions) {
      return;
    }

    const differenceDays = differenceInDays(now, firstActiveTime);
    //only if the campaign is active for more than 1 day
    if (differenceDays < 1) return;

    if (!lastReportTime && !report && isGeneralCampaignActivated) {
      onGenerateReport();
    }
    const lastReport = new Date(lastReportTime);

    //verify if the last report was generated yesterday (since start of the day)
    const isNewDay = !isSameDay(startOfDay(lastReport), startOfDay(now));
    if (!isNewDay) return;

    onGenerateReport();

    // eslint-disable-next-line
  }, []);

  const showButtonApplyOptimizations = getShowButtonApplyOptimizations();

  if (_.isEmpty(report) && !isGenerating) return null;

  return (
    <Accordion
      initialValue={true}
      title={t(HOW_IS_YOUR_CAMPAIGN_GOING)}
      iconTitle={<AnalyticsIcon />}
      variantTitle={isMobile ? "body1" : "h6"}
      sx={commonSxAccordion}
      sxTitle={{
        width: "100% !i",
        fontWeight: 900,
      }}
      sxContainerTitle={{
        ".MuiAccordionSummary-content": {
          display: "flex",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        },
        ".MuiAccordionSummary-expandIconWrapper": {},
        pt: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          height: "auto",
        }}
      >
        {hasError ? (
          <Typography variant="body1">
            {t(AN_ERROR_OCCURRED_GETTING_THE_REPORT)}
          </Typography>
        ) : null}
        {isGenerating ? (
          <LinearLoaderWithTime
            time={TIME_LOADER_GENERATING_REPORT_CAMPAIGN}
            labels={[
              t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
              t(ANALYZING_CAMPAIGN_STATISTICS),
              t(COMPARING_RESULTS_OBTAINED),
              t(GENERATING_OPTIMIZATIONS),
            ]}
            sx={{ mt: 1.5 }}
          />
        ) : null}
        {!_.isEmpty(report) && !isGenerating && !hasError ? (
          <TypingText
            text={allTextExplanation}
            sx={{
              height: 200,
            }}
          />
        ) : null}
        {showButtonApplyOptimizations && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton
              type="button"
              onClick={onApplyOptimizations}
              sx={{
                background: GRADIENT_PURPLE_FUCHSIA,
                color: GRADIENT_PURPLE_FUCHSIA_COLOR_TEXT,
              }}
            >
              {t(BUTTON_APPLY_OPTIMIZATIONS)}
            </LoadingButton>
          </Box>
        )}
        {isUpdatingCampaignByOptimization ? (
          <LinearLoaderWithTime
            time={TIME_LOADER_OPTIMIZE_CAMPAIGN}
            labels={[
              t(CREATE_ADS_CAMPAIGN_LOADING_GETTING_REQUIRED_INFORMATION),
              t(APPLYING_OPTIMIZATIONS),
              t(CREATE_ADS_CAMPAIGN_LOADING_FINALIZING_DETAILS),
            ]}
            sx={{ mt: 1.5 }}
          />
        ) : null}
      </Box>
    </Accordion>
  );
};

export default ReportContainer;
