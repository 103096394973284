import ModalBasicLayout from "./ModalBasicLayout";
import { useForm } from "react-hook-form";
import TextField from "../Form/TextField";
import {
  BUTTON_GO_BACK,
  BUTTON_NEXT,
  FIELD_REQUIRED,
  FIELD_URL_INSTAGRAM,
  FIELD_URL_VALID,
  INSTAGRAM_PROFILE_URL_PLACEHOLDER,
  SOCIAL_NETWORK_EXAMPLE,
} from "../../i18n/keysTranslations";
import { useTranslationApp } from "../../lib/i18next";
import { DialogActions } from "@mui/material";
import ContainerModalActions from "../Containers/ContainerModalActions";
import { REGEX_URL } from "../../utils/regex";
import { useEffect, useState } from "react";
import { startGetBrandInformation } from "../../actions/adsCampaigns";
import { useDispatchApp } from "../../lib/redux";
import Loader from "../Loaders/Loader";
import _ from "lodash";

const ModalSocialNetwork = ({
  modalOpen,
  onCloseModal = () => {},
  onCallbackSave = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslationApp();
  const {
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      socialNetworkUrl: "",
    },
  });

  const dispatch = useDispatchApp();

  const getInitialData = async () => {
    setIsLoading(true);
    const response = await dispatch(startGetBrandInformation());
    if (response.ok) {
      const { data } = response;

      const socialNetworks = data.socialNetworks;
      if (socialNetworks && _.isArray(socialNetworks)) {
        const instagram = socialNetworks.find((url) =>
          url.includes("instagram.com")
        );

        if (instagram) {
          setValue("socialNetworkUrl", instagram);
        }
      }
    }
    setIsLoading(false);
  };

  const onSubmit = async () => {
    const isValid = await trigger();

    if (!isValid) return;

    const socialNetworkUrl = getValues("socialNetworkUrl");

    onCallbackSave(socialNetworkUrl);
    onCloseModal();
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, []);

  return (
    <ModalBasicLayout
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      fullWidth={true}
      scroll="paper"
      maxWidth="xs"
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <TextField
          label={t(INSTAGRAM_PROFILE_URL_PLACEHOLDER)}
          placeholder={t(SOCIAL_NETWORK_EXAMPLE)}
          name={"socialNetworkUrl"}
          variant="filled"
          fullWidth
          control={control}
          errors={errors}
          rules={{
            required: {
              value: true,
              message: t(FIELD_REQUIRED),
            },
            validate: (value) => {
              if (value?.length > 0 && !REGEX_URL.test(encodeURI(value))) {
                return t(FIELD_URL_VALID);
              }
              if (value?.length > 0 && !value.includes("instagram.com")) {
                return t(FIELD_URL_INSTAGRAM);
              }
            },
          }}
        />
      )}
      {!isLoading && (
        <DialogActions
          sx={{
            p: 0,
          }}
        >
          <ContainerModalActions
            hasPadding={true}
            propsSecondaryButton={{
              showButton: true,
              onClick: onCloseModal,
              text: BUTTON_GO_BACK,
            }}
            propsPrimaryButton={{
              showButton: true,
              text: BUTTON_NEXT,
              type: "submit",
              onClick: onSubmit,
            }}
          />
        </DialogActions>
      )}
    </ModalBasicLayout>
  );
};

export default ModalSocialNetwork;
