import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
import { useTranslationApp } from "../../lib/i18next";
import { getReactHookFormNestedError } from "../../utils/errors";

const CheckBoxes = ({
  control,
  errors,
  rules,
  name,
  disabled,
  label,
  options = [],
}) => {
  const error = getReactHookFormNestedError(errors, name);
  const { t } = useTranslationApp();
  return (
    <FormControl
      component="fieldset"
      variant="filled"
      disabled={disabled}
      error={!!error}
    >
      <FormLabel component="legend" sx={{ fontWeight: 500 }}>
        {t(label)}
      </FormLabel>
      <Grid container spacing={1}>
        {options.map((option) => (
          <Grid
            item
            xs={6}
            key={option.key}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  control={control}
                  rules={rules}
                  render={({ field }) => (
                    <Checkbox
                      disabled={option.disabled}
                      checked={field?.value?.[option.key]}
                      onChange={(e) =>
                        field.onChange({
                          ...field.value,
                          [option.key]: e.target.checked,
                        })
                      }
                      name={option.key}
                    />
                  )}
                />
              }
              label={t(option.label)}
            />
            {option.subLabel && (
              <Typography
                variant="body2"
                sx={{
                  fontStyle: "italic",
                  color: "GrayText",
                }}
              >
                {t(option.subLabel)}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
      {!!error && (
        <Typography variant="caption" color="error">
          {error?.message}
        </Typography>
      )}
    </FormControl>
  );
};

export default CheckBoxes;
