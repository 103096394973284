import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AdsCampaignsListItem from "./AdsCampaignsListItem";
import { useTranslationApp } from "../../../lib/i18next";
import { EMPTY_ADS_CAMPAIGNS } from "../../../i18n/keysTranslations";
import Loader from "../../../components/Loaders/Loader";

const AdsCampaignsList = ({
  data = {},
  onChangeStatusCampaign = () => {},
  lastItemRef,
  loadedLastCampaign,
}) => {
  const keys = Object.keys(data || {}).sort(
    (a, b) => data[b]?.creationTime - data[a]?.creationTime
  );
  const { t } = useTranslationApp();

  if (keys.length === 0) {
    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography variant="body1" align="center">
          {t(EMPTY_ADS_CAMPAIGNS)}
        </Typography>
      </Box>
    );
  }
  return (
    <Grid container spacing={2}>
      {keys.map((campaignID) => {
        const campaign = data[campaignID];

        return (
          <Grid item xs={12} lg={6} key={campaignID}>
            <AdsCampaignsListItem
              data={campaign}
              campaignID={campaignID}
              onChangeStatusCampaign={onChangeStatusCampaign}
            />
          </Grid>
        );
      })}

      {!loadedLastCampaign && (
        <Box
          ref={lastItemRef}
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Loader size={30} />
        </Box>
      )}
    </Grid>
  );
};

export default AdsCampaignsList;
